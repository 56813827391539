import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import List from "./list";
import Data from "./data";

import getTransfers from "../../actions/getTransfers";

class Transfer extends Component {
  constructor(props) {
    super(props);

    document.title = "Transport";

    const id = props.location.pathname.split("/")[2];
    props.dispatch(getTransfers(props.client, id));
  }

  componentDidMount() {
    const { mp } = this.props;
    if (mp && mp.isInit && mp._mp) {
      const tracking_obj_param = {
        Screen: document.title,
        "Tracking Time": new Date().toDateString()
      };
      mp._mp.track("Screen View", tracking_obj_param, () =>
        console.log("tracked ", tracking_obj_param)
      );
    }
  }

  render() {
    const {
      dispatch,
      permissions,
      loginName,
      establishment,
      transfers,
      chosenTransfer,
      mp
    } = this.props;

    const write =
      Boolean(permissions.find(p => p.name === "transfers" && p.write)) ||
      loginName === "klout";

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <List
              className="col-md-6 padding-20 hidden-print"
              dispatch={dispatch}
              establishment={establishment}
              transfer={chosenTransfer}
              transfers={transfers}
              write={write}
            />
            <Data
              className="col-md-6 data-panel padding-20 border-left border-print-none col-print-12"
              establishment={establishment}
              transfer={chosenTransfer}
              write={write}
              mp={mp}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

Transfer.propTypes = {
  location: PropTypes.object,
  client: PropTypes.object,
  dispatch: PropTypes.func,
  permissions: PropTypes.array,
  loginName: PropTypes.string,
  establishment: PropTypes.object,
  transfers: PropTypes.array,
  chosenTransfer: PropTypes.object
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName"),
  establishment: state.main.get("establishment"),
  transfers: state.transfers.get("transfers"),
  chosenTransfer: state.transfers.get("chosenTransfer")
}))(Transfer);
