import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import logo from "../../images/logo_0.png";

import LoginForm from "./loginForm";
import PasswordForm from "./passwordForm";

import getSalt from "../../actions/getSalt";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoginForm: true
    };

    props.dispatch(getSalt(props.backend));

    this.showLoginForm = this.showLoginForm.bind(this);
    this.showPasswordForm = this.showPasswordForm.bind(this);
  }

  showLoginForm() {
    const { dispatch } = this.props;
    dispatch({ type: "SET_ERROR", data: null });
    this.setState({ showLoginForm: true });
  }

  showPasswordForm() {
    const { dispatch } = this.props;
    dispatch({ type: "SET_ERROR", data: null });
    this.setState({ showLoginForm: false });
  }

  render() {
    const { dispatch, backend, salt, message, error } = this.props;
    const { showLoginForm } = this.state;

    return (
      <div className="container-fluid bg-secondary" style={{ height: "100vh" }}>
        <div id="login-form" className="card border-0">
          <div className="card-header p-0 bg-secondary">
            {/* <img src={logo} alt="Kutter Logo" /> */}
            <div className="d-flex flex-column">
              <h1 className="display-4 m-3  text-white">
                <strong>dizpa</strong>{" "}
                <span style={{ fontSize: "12px" }}>powered by solongo</span>
              </h1>
            </div>
          </div>
          <div className="card-body">
            {showLoginForm ? (
              <LoginForm
                dispatch={dispatch}
                backend={backend}
                salt={salt}
                error={error}
                onShowPasswordForm={this.showPasswordForm}
              />
            ) : (
              <PasswordForm
                dispatch={dispatch}
                backend={backend}
                salt={salt}
                message={message}
                error={error}
                onShowLoginForm={this.showLoginForm}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func,
  backend: PropTypes.string,
  salt: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  backend: state.main.get("backend"),
  salt: state.main.get("salt"),
  message: state.main.get("message"),
  error: state.main.get("error")
}))(Login);
