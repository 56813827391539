import gql from 'graphql-tag';
import * as moment from 'moment';
import { TransferDataWithEmployeeHours } from './fragments';

const QUERY = gql`
  query getOpenTransfers(
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    getOpenTransfers {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default client => dispatch => {
  dispatch({ type: 'SET_TRANSFERS_LOADING' });
  client
    .query({
      query: QUERY,
      fetchPolicy: 'network-only'
    })
    .then(response => {
      dispatch({
        type: 'TRANSFERS_FOUND',
        data: response.data.getOpenTransfers
      });
    });
};
