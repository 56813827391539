import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  faUser,
  faTruckMoving,
  faCar
} from '@fortawesome/pro-solid-svg-icons';

class ResourceList extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete(e) {
    const { onDelete, disabled } = this.props;
    const { name, id } = e.currentTarget.dataset;

    if(!disabled)
      onDelete(name, id);
  }

  render() {
    const { resources, humanResources, vehicles, disabled } = this.props;

    return (
      <div className="col-12 mb-3">
        <ul className="list-group">
          <li
            key="dispoResourceList"
            className="list-group-item d-flex justify-content-between align-items-center">
            <b>Maschinen</b>
            <FontAwesomeIcon
              className="fa-w-20 text-black-50"
              icon={faTruckMoving}
            />
          </li>
          {resources.map(resource => {
            if (resource) {
              return (<li
                  key={resource.id}
                  className="list-group-item d-flex justify-content-between align-items-center">
                {`${resource.name} / ${resource.number}`}
                <FontAwesomeIcon
                    className={`fa-btn-secondary${
                        disabled ? '-disabled' : ''
                    } hidden-print`}
                    icon={faTimesCircle}
                    data-name="resources"
                    data-id={resource.id}
                    onClick={this.handleDelete}
                />
              </li>);
            }
          })}
        </ul>
        <ul className="list-group mt-3">
          <li
            key="dispoVehicleList"
            className="list-group-item d-flex justify-content-between align-items-center">
            <b>Fahrzeuge</b>
            <FontAwesomeIcon className="fa-w-20 text-black-50" icon={faCar} />
          </li>
          {vehicles.map(vehicle => (
            <li
              key={vehicle.id}
              className="list-group-item d-flex justify-content-between align-items-center">
              {`${vehicle.brand} ${vehicle.type} / ${vehicle.licensePlate}`}
              <FontAwesomeIcon
                className={`fa-btn-secondary${
                  disabled ? '-disabled' : ''
                } hidden-print`}
                icon={faTimesCircle}
                data-name="vehicles"
                data-id={vehicle.id}
                onClick={this.handleDelete}
              />
            </li>
          ))}
        </ul>
        <ul className="list-group mt-3">
          <li
            key="dispoHumanResourceList"
            className="list-group-item d-flex justify-content-between align-items-center">
            <b>Mitarbeiter</b>
            <FontAwesomeIcon className="fa-w-20 text-black-50" icon={faUser} />
          </li>
          {humanResources.map(humanResource => (
            <li
              key={humanResource.id}
              className="list-group-item d-flex justify-content-between align-items-center">
              {`${humanResource.firstname} ${humanResource.lastname}`}
              <FontAwesomeIcon
                className={`fa-btn-secondary${
                  disabled ? '-disabled' : ''
                } hidden-print`}
                icon={faTimesCircle}
                data-name="humanResources"
                data-id={humanResource.id}
                onClick={this.handleDelete}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

ResourceList.propTypes = {
  resources: PropTypes.array,
  humanResources: PropTypes.array,
  vehicles: PropTypes.array,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func
};

export default ResourceList;
