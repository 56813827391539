import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CreatableSelect from "react-select/lib/Creatable";
import sortBy from "lodash/sortBy";
import { faEye, faEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TitleBar from "../TitleBar";

import getPermissions from "../../actions/getPermissions";
import toggleReadPermission from "../../actions/toggleReadPermission";
import toggleWritePermission from "../../actions/toggleWritePermission";

class Permission extends Component {
  constructor(props) {
    super(props);

    document.title = "Berechtigungen";

    this.state = {
      filter: null
    };

    props.dispatch(getPermissions(props.client));

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }
  componentDidMount() {
    const { mp } = this.props;
    if (mp && mp.isInit && mp._mp) {
      const tracking_obj_param = {
        Screen: document.title,
        "Tracking Time": new Date().toDateString()
      };
      mp._mp.track("Screen View", tracking_obj_param, () =>
        console.log("tracked ", tracking_obj_param)
      );
    }
  }

  toggleReadPermission(humanResourceId, permission) {
    this.props.dispatch(
      toggleReadPermission(this.props.client, humanResourceId, permission)
    );
  }

  toggleWritePermission(humanResourceId, permission) {
    const setAll = !permission.read && !permission.write;

    this.props.dispatch(
      toggleWritePermission(
        this.props.client,
        humanResourceId,
        permission,
        setAll
      )
    );
  }

  handleFilterChange(values) {
    this.setState({
      filter: values.filter(value => value.value.trim() !== "")
    });
  }

  static filter(elements, filters) {
    if (filters && filters.length > 0) {
      return elements.filter(elem => {
        const testString = `${elem.firstname.toUpperCase()}_${elem.lastname.toUpperCase()}_${elem.position.toUpperCase()}`;
        return filters.reduce((acc, val) => {
          const match = testString.indexOf(val.value.toUpperCase()) > -1;
          return acc && match;
        }, true);
      });
    }

    return elements;
  }

  render() {
    let { humanResources } = this.props;
    const { modules, permissions, loginName } = this.props;
    const { filter } = this.state;

    const hasWritePermission =
      Boolean(permissions.find(p => p.name === "permissions" && p.write)) ||
      loginName === "klout";

    humanResources = humanResources.filter(
      u => u.username !== "" && u.status === "Aktiv"
    );
    humanResources = sortBy(humanResources, [
      "username",
      "firstname",
      "lastname"
    ]);

    humanResources = Permission.filter(humanResources, filter);

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-12 padding-20"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 65px)"
              }}
            >
              <TitleBar title="Berechtigungen" />

              <div className="my-3">
                <CreatableSelect
                  isMulti
                  isClearable
                  placeholder="Filter..."
                  noOptionsMessage={() => "Hinzufügen mit Eingabe"}
                  formatCreateLabel={inputValue => `+ ${inputValue}`}
                  value={filter}
                  onChange={this.handleFilterChange}
                />
              </div>

              <div className="small">
                <div className="row border col-md-12 p-0 m-0">
                  <div className="col bg-light p-2">&nbsp;</div>
                  {Object.keys(modules).map(key => {
                    let content = key;
                    if (key.startsWith("A_")) {
                      content = (
                        <Fragment>
                          Abrufe
                          <br />
                          <span className="small">{key.substr(2)}</span>
                        </Fragment>
                      );
                    }
                    if (key.startsWith("D_")) {
                      content = (
                        <Fragment>
                          Desktop
                          <br />
                          <span className="small">{key.substr(2)}</span>
                        </Fragment>
                      );
                    }
                    return (
                      <div key={key} className="col bg-light p-2 border-left">
                        {content}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="permissions-table-body small">
                <div>
                  {humanResources.map(hr => {
                    const { permissions } = hr;
                    return (
                      <Fragment key={hr.username}>
                        <div className="row col-md-12 p-0 m-0">
                          <div
                            className="col border-bottom border-right border-left p-2"
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {`${hr.firstname} ${hr.lastname} (${hr.username}) `}
                            <span className="d-block text-black-50">
                              {hr.position}
                            </span>
                          </div>
                          {Object.keys(modules).map(key => {
                            const permissionName = modules[key];
                            let permission = permissions.filter(
                              perm => perm.name === permissionName
                            );
                            if (permission.length > 0) {
                              [permission] = permission;
                            } else {
                              permission = {
                                name: permissionName,
                                read: false,
                                write: false
                              };
                            }
                            const baseClass = "btn w-100 p-0";
                            let readClass = `${baseClass} alert-danger`;
                            let writeClass = `${baseClass} alert-danger`;
                            if (permission.read) {
                              readClass = `${baseClass} alert-success`;
                              if (permission.write) {
                                writeClass = `${baseClass} alert-success`;
                              }
                            }
                            return (
                              <div
                                key={key}
                                className="col border-bottom border-right p-2"
                              >
                                <div className="btn-group w-100">
                                  <button
                                    className={readClass}
                                    onClick={
                                      hasWritePermission
                                        ? this.toggleReadPermission.bind(
                                            this,
                                            hr.id,
                                            permission
                                          )
                                        : () => {}
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </button>
                                  <button
                                    className={writeClass}
                                    onClick={
                                      hasWritePermission
                                        ? this.toggleWritePermission.bind(
                                            this,
                                            hr.id,
                                            permission
                                          )
                                        : () => {}
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Permission.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  modules: PropTypes.object,
  humanResources: PropTypes.array,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  humanResources: state.permissions.get("humanResources"),
  modules: state.permissions.get("modules"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName")
}))(Permission);
