import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import gql from "graphql-tag";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import Select from "react-select";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";

import CostCenterSelect from "../CostCenterSelect";
import Modal from "../Modal";
import ToggleButton from "../ToggleButton";
import Button from "../UnlockButton";

import createCostCenter from "../../actions/createCostCenter";
import updateCostCenter from "../../actions/updateCostCenter";
import deleteCostCenter from "../../actions/deleteCostCenter";
import createCompanyAndContacts from "../../actions/createCompanyAndContacts";

class Data extends Component {
  constructor(props) {
    super(props);

    let establishment = props.establishment
      ? { label: props.establishment, value: props.establishment }
      : null;

    if (props.establishment && props.establishment.label) {
      establishment = props.establishment;
    }

    this.initState = {
      establishment,
      value: 0,
      oldValue: 0,
      isContinuance: false,
      buildingProject: "",
      customer: "",
      company: null,
      masterCompany: null,
      locked: !props.write,
      valueLocked: true,
      errors: {},
      showModal: false,
      modalSaveMode: false,
      modalContent: null,
      ordersLoading: false,
      orders: null,
      noMatchingCostCenter: undefined
    };
    this.state = this.initState;

    this.getNextValue = this.getNextValue.bind(this);

    this.setCostCenter = this.setCostCenter.bind(this);

    this.loadOptions = this.loadOptions.bind(this);
    this.loadOptionsMaster = this.loadOptionsMaster.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleToggleButtonChange = this.handleToggleButtonChange.bind(this);
    this.handleEstablishmentChange = this.handleEstablishmentChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleMasterCompanyChange = this.handleMasterCompanyChange.bind(this);

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.unlock = this.unlock.bind(this);
    this.lock = this.lock.bind(this);

    this.hideModal = this.hideModal.bind(this);
  }

  static isValidNewCompanyOption(inputValue, selectValue, selectOptions) {
    if (inputValue !== "") {
      const testValue = inputValue.toUpperCase();
      for (let i = 0; i < selectOptions.length; i += 1) {
        if (
          selectOptions[i].label
            .toUpperCase()
            .trim()
            .localeCompare(testValue) === 0
        ) {
          return false;
        }
      }
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      costCenterFrames,
      costCenter,
      noMatchingCostCenter,
      establishment
    } = this.props;

    if (
      prevProps.costCenter !== costCenter ||
      prevProps.costCenterFrames !== costCenterFrames
    ) {
      if (costCenter) {
        this.setCostCenter(costCenter);
      } else {
        this.setState(this.initState);
        if (costCenterFrames) {
          this.setState({ value: this.getNextValue() });
        }
      }
    }

    if (prevState.noMatchingCostCenter !== noMatchingCostCenter) {
      this.setState({ noMatchingCostCenter });
    }

    if (prevProps.establishment !== establishment) {
      this.setState({
        establishment: {
          label: establishment,
          value: establishment
        }
      });
    }

    if (prevState.establishment !== this.state.establishment && !costCenter) {
      this.setState({ value: this.getNextValue() });
    }

    if (prevProps.duplicateError !== this.props.duplicateError){
      this.setState( {errors: {...prevState.errors, value: this.props.duplicateError}});
    }
  }

  getNextValue() {
    const { costCenterFrames, costCenters } = this.props;
    const { errors } = this.state;
    const establishment = this.state.establishment || this.props.establishment;

    if (!establishment) return 0;

    let value =
      costCenters.reduce((acc, val) => {
        if (!val.establishment || val.establishment !== establishment.value) {
          return acc;
        }
        return acc > val.value ? acc : val.value;
      }, 0) + 1;

    const frames = costCenterFrames.filter(
      frame => frame.establishment === establishment.value
    );

    let valid = false;
    let diff = -1;
    let backupValue = -1;
    let max = 0;
    frames.forEach(frame => {
      if (value >= frame.from && value <= frame.to) {
        valid = true;
        diff = frame.to - value;
      }
      if (frame.from < backupValue || backupValue < 0) {
        backupValue = frame.from;
      }
      if (frame.to > max) {
        max = frame.to;
      }
    });

    if (!valid) {
      if (backupValue > 0 && value < max) {
        value = backupValue;
        diff = 1000;
      } else {
        this.setState({ errors: { diff: errors.diff, frame: true } });
        return 0;
      }
    }

    this.setState({ errors: diff >= 0 && diff < 99 ? { diff: diff + 1 } : {} });
    return value;
  }

  setCostCenter(costCenter) {
    const customer = costCenter.customer || "";

    let establishment = costCenter.establishment
      ? { label: costCenter.establishment, value: costCenter.establishment }
      : null;

    if (costCenter.establishment && costCenter.establishment.label) {
      establishment = costCenter.establishment;
    }

    this.setState({
      establishment,
      value: costCenter.value || "",
      oldValue: costCenter.oldValue || "",
      isContinuance: costCenter.is_continuance || false,
      buildingProject: costCenter.building_project || "",
      customer,
      company: customer !== "" ? { label: customer, value: customer } : null,
      masterCompany: costCenter.masterCompany
        ? { label: costCenter.masterCompany, value: costCenter.masterCompany }
        : null,
      locked: true,
      valueLocked: true,
      errors: {},
      noMatchingCostCenter: undefined
    });
  }

  loadOptions(inputValue) {
    return this.props.client
      .query({
        query: gql`
          query getCompaniesByName($name: String) {
            getCompanies(name: $name) {
              id
              name
            }
          }
        `,
        variables: {
          name: inputValue
        },
        fetchPolicy: "no-cache"
      })
      .then(response => {
        const data = response.data.getCompanies;
        return data.map(d => ({
          ...d,
          value: d.id,
          label: d.name
        }));
      });
  }

  loadOptionsMaster(inputValue) {
    return this.props.client
      .query({
        query: gql`
          query getMasterCompaniesByName($name: String) {
            getMasterCompanies(name: $name) {
              id
              masterCustomer: master_customer
            }
          }
        `,
        variables: {
          name: inputValue
        },
        fetchPolicy: "no-cache"
      })
      .then(response => {
        const data = response.data.getMasterCompanies;
        const uniqueNames = [...new Set(data.map(d => d.masterCustomer))];
        return uniqueNames.map(label => ({
          value: data.find(d => d.masterCustomer === label),
          label
        }));
      });
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({ [name]: value });
  }

  handleToggleButtonChange(name, value) {
    this.setState({ [name]: value });
  }

  handleEstablishmentChange(value) {
    this.setState({ establishment: value });
  }

  handleCompanyChange(option) {
    this.setState({ company: option });
  }

  handleMasterCompanyChange(option) {
    this.setState({ masterCompany: option });
  }

  handleSave() {
    const { client, dispatch, costCenters, queryString } = this.props;
    const { state } = this;
    const { company, masterCompany, errors } = state;

    const costCenter = {
      value: state.value,
      oldValue: state.oldValue,
      establishment: state.establishment ? state.establishment.value : null,
      isContinuance: state.isContinuance,
      buildingProject: state.buildingProject,
      customer: state.customer,
      masterCompany: masterCompany ? masterCompany.label : null
    };

    let error =
      costCenter.value === "" ? "Es muss eine Nummer vergeben werden." : null;

    const intValue = parseInt(costCenter.value, 10);
    if (intValue <= 0) {
      error = "Es muss eine Nummer größer als 0 vergeben werden.";
    }

    if (costCenters) {
      if (!this.props.costCenter || this.props.costCenter.value !== intValue) {
        for (const i in costCenters) {
          if (costCenters[i].value === intValue) {
            error = "Diese nummer ist bereits vergeben.";
            break;
          }
        }
      }
    }

    if (error) {
      this.setState({ errors: { ...errors, value: error } });
      return;
    }

    if (company) {
      if (company.__isNew__) {
        const companyObj = {
          name: company.label,
          adress: "",
          zipcode: "",
          city: "",
          phone: "",
          fax: "",
          email: ""
        };

        dispatch(createCompanyAndContacts(client, companyObj, []));
      }

      costCenter.customer = company.label;
    }

    if (this.props.costCenter) {
      costCenter.id = this.props.costCenter.id;
      dispatch(updateCostCenter(client, costCenter));
    } else {
      const queryParams = queryString.substr(1).split("=");
      let orderId = null;
      if (queryParams[0] === "order") orderId = parseInt(queryParams[1], 10);

      costCenter.value = intValue;

      dispatch(createCostCenter(client, costCenter, orderId));
    }

    this.lock(false);
  }

  handleDelete() {
    const { client, dispatch, costCenter } = this.props;

    if (costCenter) dispatch(deleteCostCenter(client, costCenter.id));

    this.hideModal();
    this.lock(false);
  }

  onSave() {
    const { costCenter } = this.props;
    const { orders } = this.state;

    if (orders) {
      if (orders.length > 0) {
        const modalContent = (
          <Fragment>
            Diese Kostenstelle wird bereits an einem oder mehreren Abrufen
            verwendet. Änderungen wirken sich auf alle verbundenen Abrufe aus.
            Soll die Kostenstelle wirklich überschriben werden?
            <br />
            <br />
            Alternativ sollte eine neue Kostenstelle angelegt werden.
          </Fragment>
        );

        this.setState({ showModal: true, modalSaveMode: true, modalContent });
      } else {
        this.handleSave();
      }
    } else if (!costCenter) {
      this.handleSave();
    } else {
      this.hideModal();
      this.lock();
    }
  }

  onDelete() {
    const { orders } = this.state;

    if (orders) {
      if (orders.length > 0) {
        const modalContent =
          "Diese Kostenstelle wird bereits an einem oder mehreren Abrufen verwendet Und kann daher nicht gelöscht werden.";

        this.setState({ showModal: true, modalSaveMode: false, modalContent });
      } else {
        this.handleDelete();
      }
    } else {
      this.hideModal();
      this.lock();
    }
  }

  unlock() {
    const { client, costCenter } = this.props;

    if (costCenter) {
      this.setState({ ordersLoading: true });
      client
        .query({
          query: gql`
            query getOrdersByCostCenterId($id: ID) {
              getOrdersByCostCenterId(cost_center_id: $id) {
                id
              }
            }
          `,
          variables: { id: costCenter.id }
        })
        .then(response => {
          const data = response.data.getOrdersByCostCenterId;

          this.setState({ ordersLoading: false, orders: data || null });
        });
    } else {
      this.setState({ ordersLoading: false, orders: [] });
    }

    this.setState({ locked: false });
  }

  lock(reset = true) {
    const { costCenter } = this.props;

    if (reset && costCenter) {
      this.setCostCenter(costCenter);
    }

    this.hideModal();
    this.setState({ locked: true });
  }

  hideModal() {
    this.setState({
      showModal: false,
      modalSaveMode: false,
      modalContent: null
    });
  }


  render() {
    const {
      createMode,
      establishments,
      costCenter,
      costCenters,
      write,
      disabled,
      onChange,
      showButton
    } = this.props;
    const {
      value,
      oldValue,
      establishment,
      isContinuance,
      buildingProject,
      company,
      masterCompany,
      locked,
      valueLocked,
      errors,
      showModal,
      modalSaveMode,
      modalContent,
      ordersLoading,
      noMatchingCostCenter
    } = this.state;

    return (
      <div className="position-relative">
        <Modal
          show={showModal}
          titleText={
            modalSaveMode ? "Kostenstelle speichern" : "Kostenstelle löschen"
          }
          closeBtnText="Abbrechen"
          submitBtnText="Speichern"
          onClose={this.lock}
          onSubmit={modalSaveMode ? this.handleSave : this.handleDelete}
          showControls={modalSaveMode}>
          {modalContent}
        </Modal>

        <div className="row">
          <div className="col-12">
            <div className="row">
              {/* {errors.frame ? ( */}
              {/*  <div className="col-12"> */}
              {/*    <div className="alert alert-danger"> */}
              {/*      <FontAwesomeIcon */}
              {/*        className="mr-3" */}
              {/*        icon={faExclamationTriangle} */}
              {/*      /> */}
              {/*      Für diese Niederlassung muss ein neuer Kostenstellenrahmen */}
              {/*      definiert werden. */}
              {/*    </div> */}
              {/*  </div> */}
              {/* ) : null} */}
              {errors.diff !== undefined ? (
                <div className="col-12">
                  <div className="alert alert-warning">
                    <FontAwesomeIcon
                      className="mr-3"
                      icon={faExclamationTriangle}
                    />
                    In diesem Kostenstellenrahmen stehen nur noch {errors.diff}{" "}
                    Nummern zur Verfügung.
                  </div>
                </div>
              ) : null}
              <div className={`${showButton ? "col-4" : "col-2"} form-group`}>
                <label className="form-label small">Niederlassung</label>
                <Select
                  isDisabled={disabled || !write}
                  isSearchable={false}
                  placeholder=""
                  value={establishment}
                  options={establishments}
                  onChange={e => {
                    this.handleEstablishmentChange(e);
                    onChange("establishment", e);
                  }}
                />
              </div>
              <div className="col-3 form-group">
                <label className="form-label small">Kostenstelle</label>
                {createMode ? (
                  <div onClick={() => this.setState({ valueLocked: false })}>
                    <input
                      type="number"
                      className={`form-control ${
                        errors.value ? "is-invalid" : ""
                      }`}
                      name="value"
                      value={value}
                      onChange={this.handleInputChange}
                      disabled={locked || !write || valueLocked}
                    />
                    {errors.value ? (
                      <small className="form-text text-danger">
                        {errors.value}
                      </small>
                    ) : null}
                  </div>
                ) : (
                  <CostCenterSelect
                    clearable
                    placeholder=""
                    clearAllText="Kostenstelle zurücksetzen"
                    noResultsText="keine Übereinstimmung"
                    value={costCenter}
                    options={costCenters}
                    onChange={v => onChange("costCenter", v)}
                    disabled={disabled || !write || noMatchingCostCenter}
                  />
                )}
              </div>
              {!showButton ? (
                <div className="col-3 form-group">
                  <div className="form-check">
                    <label
                      className="form-label small"
                      htmlFor="noMatchingCostCenter">
                      keine passende Kostenstelle
                    </label>
                    <ToggleButton
                      className="form-control mt-2"
                      name="noMatchingCostCenter"
                      value={noMatchingCostCenter}
                      onChange={(name, bool) => {
                        onChange(name, bool);
                        this.setState({ [name]: bool });
                      }}
                      disabled={disabled || !write}
                    />
                  </div>
                </div>
              ) : null}

              <div className={`${showButton ? "col-3" : "col-2"} form-group`}>
                <label className="form-label small">Kostenstelle Alt</label>
                {createMode ? (
                  <div onClick={() => this.setState({ valueLocked: false })}>
                    <input
                      type="number"
                      className={`form-control ${
                        errors.value ? "is-invalid" : ""
                      }`}
                      name="oldValue"
                      value={oldValue}
                      onChange={this.handleInputChange}
                      disabled={true}
                    />
                  </div>
                ) : null}
              </div>
              <div className="col-2 form-group">
                <label className="form-label small" htmlFor="isContinuance">
                  DauerKS
                </label>
                <ToggleButton
                  className="form-control mt-2"
                  name="isContinuance"
                  value={isContinuance}
                  onChange={this.handleToggleButtonChange}
                  disabled={locked || !createMode}
                />
              </div>

              <div className="col-6 form-group">
                <label className="form-label small">Auftraggeber</label>
                <AsyncCreatableSelect
                  isClearable
                  isDisabled={locked || !createMode}
                  loadOptions={this.loadOptions}
                  placeholder=""
                  noOptionsMessage={() => "Suchbegriff eingeben"}
                  noResultsMessage={() => "Keine Übereinstimmung"}
                  formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
                  value={company}
                  isValidNewOption={Data.isValidNewCompanyOption}
                  onChange={this.handleCompanyChange}
                />
              </div>

              <div className="col-6 form-group">
                <label className="form-label small">
                  Bauher/Hauptauftraggeber
                </label>
                <AsyncCreatableSelect
                  isClearable
                  isDisabled={locked || !createMode}
                  loadOptions={this.loadOptions}
                  placeholder=""
                  noOptionsMessage={() => "Suchbegriff eingeben"}
                  noResultsMessage={() => "Keine Übereinstimmung"}
                  formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
                  value={masterCompany}
                  isValidNewOption={Data.isValidNewCompanyOption}
                  onChange={this.handleMasterCompanyChange}
                />
              </div>

              <div className="col-12 form-group">
                <label className="form-label small">Bauvorhaben</label>
                <input
                  type="text"
                  className="form-control"
                  name="buildingProject"
                  value={buildingProject}
                  onChange={this.handleInputChange}
                  disabled={locked || !createMode}
                />
              </div>
            </div>
          </div>
        </div>
        {write && showButton ? (
          <div className="order-fixed-bottom border-top">
            <Button
              className="row pt-3 pl-3 hidden-print mr-2"
              onSave={this.onSave}
              onDelete={this.onDelete}
              unlock={this.unlock}
              lock={this.lock}
              chosen={Boolean(costCenter)}
              locked={locked}
              saveEnabled={!ordersLoading}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
Data.defaultProps = {
  showButton: true
};
Data.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  queryString: PropTypes.string,
  className: PropTypes.string,
  establishment: PropTypes.object,
  establishments: PropTypes.array,
  costCenterFrames: PropTypes.array,
  costCenter: PropTypes.object,
  costCenters: PropTypes.array,
  write: PropTypes.bool,
  createMode: PropTypes.bool,
  disabled: PropTypes.bool,
  showMapOverlay: PropTypes.bool,
  onChange: PropTypes.func,
  showButton: PropTypes.bool,
  hideSpacer: PropTypes.bool,
  noMatchingCostCenter: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishments: state.main.get("establishments"),
  costCenterFrames: state.costCenters.get("costCenterFrames"),
  duplicateError: state.costCenters.get("duplicateError"),
  costCenters: state.costCenters.get("costCenters")
}))(Data);
