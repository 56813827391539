import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { uniqBy } from "lodash";

import Select from "react-select";

import moment from "moment";
import TitleBar from "../TitleBar";
import ResourceSelect from "./resourceSelect";
import ResourceList from "./resourceList";
import Button from "../UnlockButton";

import getResources from "../../actions/getResources";
import getUsers from "../../actions/getUsers";
import getVehicles from "../../actions/getVehicles";

import createCombination from "../../actions/createCombination";
import updateCombination from "../../actions/updateCombination";
import deleteCombination from "../../actions/deleteCombination";

class Data extends Component {
  constructor(props) {
    super(props);

    const { dispatch, client, establishment, write } = props;
    this.initState = {
      name: "",
      establishment,
      description: "",
      resources: [],
      humanResources: [],
      vehicles: [],
      locked: !write,
      errors: {},
      status: "Aktiv",
      leavingDate: null
    };
    this.state = this.initState;

    this.setCombination = this.setCombination.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEstablishmentChange = this.handleEstablishmentChange.bind(this);
    this.validateForm = this.validateForm.bind(this);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.lock = this.lock.bind(this);
    this.unlock = this.unlock.bind(this);

    dispatch(getResources(client));
    dispatch(getUsers(client));
    dispatch(getVehicles(client));
  }

  componentDidUpdate(prevProps) {
    const { combination } = this.props;
    if (prevProps.combination !== combination) {
      if (combination) {
        this.setCombination(combination);
      } else {
        this.setState(this.initState);
      }
    }
  }

  setCombination(combination) {
    this.setState({
      name: combination.name || "",
      establishment: combination.establishment
        ? { label: combination.establishment, value: combination.establishment }
        : null,
      description: combination.description || "",
      resources: combination.resources || [],
      humanResources: combination.humanResources || [],
      vehicles: combination.vehicles || [],
      locked: true,
      errors: {},
      status: combination.status,
      leavingDate: combination.leavingDate
        ? moment(combination.leavingDate).format("YYYY-MM-DD")
        : null
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleEstablishmentChange(value) {
    this.setState({
      establishment: value
    });
  }

  validateForm() {
    const { errors, name } = this.state;

    errors.name = name === "";

    let valid = true;
    for (const key in errors) valid = valid && !errors[key];

    this.setState({ errors });
    return valid;
  }

  handleAdd(data) {
    const { resources, humanResources, vehicles } = this.state;

    this.setState({
      resources: uniqBy(resources.concat(data.resources), elem => elem.id),
      humanResources: uniqBy(
        humanResources.concat(data.humanResources),
        elem => elem.id
      ),
      vehicles: uniqBy(vehicles.concat(data.vehicles), elem => elem.id)
    });
  }

  handleDelete(name, id) {
    const elements = this.state[name];
    this.setState({ [name]: elements.filter(elem => elem.id !== id) });
  }

  lock(reset = true) {
    const { combination } = this.props;
    if (reset && combination) {
      this.setCombination(combination);
    }
    this.setState({
      locked: true
    });
  }

  unlock() {
    const { combination, mp } = this.props;
    this.setState({
      locked: false
    });
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Edit",
        ...(combination && combination.id && { "Object Id": combination.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
  }

  save() {
    const { state } = this;
    const { client, dispatch, combination, mp } = this.props;

    const data = {
      name: state.name,
      establishment: state.establishment ? state.establishment.value : null,
      description: state.description,
      status: state.status,
      leavingDate: state.leavingDate,
      resources: JSON.stringify(
        state.resources.map(elem => parseInt(elem.id, 10))
      ),
      humanResources: JSON.stringify(
        state.humanResources.map(elem => parseInt(elem.id, 10))
      ),
      vehicles: JSON.stringify(
        state.vehicles.map(elem => parseInt(elem.id, 10))
      )
    };

    if (!this.validateForm()) return;

    if (combination) {
      dispatch(updateCombination(client, combination.id, data));
    } else {
      dispatch(createCombination(client, data));
    }
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Save",
        ...(combination && combination.id && { "Object Id": combination.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
    this.lock(false);
  }

  delete() {
    const { client, dispatch, combination, mp } = this.props;
    if (combination) dispatch(deleteCombination(client, combination.id));
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Delete",
        ...(combination && combination.id && { "Object Id": combination.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
    this.lock(false);
  }

  render() {
    const {
      className,
      establishments,
      combination,
      write,
      resourceOptions,
      humanResourceOptions,
      vehicleOptions
    } = this.props;
    const {
      name,
      establishment,
      description,
      resources,
      humanResources,
      vehicles,
      locked,
      errors,
      status,
      leavingDate
    } = this.state;

    return (
      <div className={className}>
        <TitleBar title="Stammdaten" />

        <div className="row">
          <div className="form-group col-8">
            <label className="form-label small">Name*</label>
            <input
              type="text"
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
              name="name"
              value={name}
              onChange={this.handleInputChange}
              disabled={locked}
            />
          </div>

          <div className="form-group col-4">
            <label className="form-label small">Niederlassung</label>
            <Select
              isDisabled={locked}
              isSearchable={false}
              placeholder=""
              value={establishment}
              options={establishments}
              onChange={this.handleEstablishmentChange}
            />
          </div>

          <div className="form-group col-12">
            <label className="form-label small">Beschreibung</label>
            <input
              type="text"
              className="form-control"
              name="description"
              value={description}
              onChange={this.handleInputChange}
              disabled={locked}
            />
          </div>

          <div className="form-group col-4">
            <label className="form-label small">Status</label>
            <select
              className="form-control"
              name="status"
              value={status}
              disabled={locked}
              onChange={this.handleInputChange}
            >
              {["Aktiv", "Inaktiv"].map(stateOption => (
                <option key={stateOption}>{stateOption}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-4">
            {status === "Inaktiv" ? (
              <Fragment>
                <label className="form-label small">Austrittsdatum</label>
                <input
                  type="date"
                  className="form-control"
                  name="leavingDate"
                  value={leavingDate}
                  disabled={locked}
                  onChange={this.handleInputChange}
                />
              </Fragment>
            ) : null}
          </div>

          <ResourceList
            resources={resources}
            humanResources={humanResources}
            vehicles={vehicles}
            onDelete={this.handleDelete}
            disabled={locked}
          />

          <ResourceSelect
            resources={resourceOptions}
            humanResources={humanResourceOptions}
            vehicles={vehicleOptions}
            disabled={locked}
            onAdd={this.handleAdd}
          />
        </div>

        {write ? (
          <div className="order-fixed-bottom border-top">
            <Button
              className="row pt-3 pl-3 hidden-print mr-2"
              onSave={this.save}
              onDelete={this.delete}
              unlock={this.unlock}
              lock={this.lock}
              chosen={Boolean(combination)}
              locked={locked}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Data.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  className: PropTypes.string,
  establishment: PropTypes.object,
  establishments: PropTypes.array,
  combination: PropTypes.object,
  write: PropTypes.bool,
  resourceOptions: PropTypes.array,
  humanResourceOptions: PropTypes.array,
  vehicleOptions: PropTypes.array
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishments: state.main.get("establishments"),
  resourceOptions: state.resources.get("resources"),
  humanResourceOptions: state.humanResources.get("users"),
  vehicleOptions: state.vehicles.get("vehicles")
}))(Data);
