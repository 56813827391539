import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "react-select";

import moment from "moment";
import Button from "../UnlockButton";

import createUser from "../../actions/createUser";
import updateUser from "../../actions/updateUser";
import deleteUser from "../../actions/deleteUser";

class Data extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      firstname: "",
      lastname: "",
      staff_id: "",
      phone: "",
      position: "Abrechnung",
      street: "",
      city: "",
      zipcode: "",
      establishment: props.establishment,
      username: "",
      locked: false,
      status: "Aktiv",
      reason: null,
      leavingDate: null,
      errors: {}
    };

    this.state = this.initState;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEstablishmentChange = this.handleEstablishmentChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (prevProps.user !== user) {
      if (user) {
        this.setUser(user);
      } else {
        this.setState(this.initState);
      }
    }
  }

  setUser(user) {
    this.setState({
      firstname: user.firstname || "",
      lastname: user.lastname || "",
      phone: user.phone || "",
      staff_id: user.staff_id || "",
      position: user.position || "Abrechnung",
      street: user.street || "",
      city: user.city || "",
      zipcode: user.zipcode || "",
      establishment: user.establishment
        ? { label: user.establishment, value: user.establishment }
        : null,
      username: user.username,
      locked: true,
      status: user.status || "Aktiv",
      reason: user.reason || null,
      leavingDate: user.leavingDate
        ? moment(user.leavingDate).format("YYYY-MM-DD")
        : null,
      errors: {}
    });
  }

  // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
  //   const { user } = nextProps;
  //   if (user) {
  //     this.setUser(user);
  //   } else {
  //     this.setState(Object.assign(this.initState, { locked: true }));
  //   }
  // }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleEstablishmentChange(value) {
    this.setState({
      establishment: value
    });
  }

  unlock() {
    const { user, mp } = this.props;
    this.setState({ locked: false });
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Edit",
        ...(user && user.id && { "Object Id": user.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
  }

  lock(reset = true) {
    const { user } = this.props;
    if (reset && user) {
      this.setUser(user);
    }
    this.setState({
      locked: true
    });
  }

  validateForm() {
    const { errors } = this.state;

    errors.phone = !this.state.phone.match(/^\d+$/i);

    let valid = true;
    Object.keys(errors).forEach(field => {
      valid = valid && !errors[field];
    });

    this.setState({ errors });
    return valid;
  }

  onSave() {
    if (!this.validateForm()) {
      return;
    }
    const { client, dispatch, user, mp } = this.props;
    const {
      lastname,
      firstname,
      staff_id,
      position,
      street,
      city,
      zipcode,
      phone,
      username,
      status,
      reason,
      leavingDate
    } = this.state;

    const establishment = this.state.establishment
      ? this.state.establishment.value
      : null;

    if (user) {
      dispatch(
        updateUser(
          client,
          user.id,
          firstname,
          lastname,
          staff_id,
          establishment,
          phone,
          position,
          street,
          city,
          zipcode,
          status,
          reason,
          leavingDate
        )
      );
    } else {
      dispatch(
        createUser(
          client,
          firstname,
          lastname,
          staff_id,
          establishment,
          phone,
          position,
          street,
          city,
          zipcode,
          username,
          status,
          reason,
          leavingDate
        )
      );
    }
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Save",
        ...(user && user.id && { "Object Id": user.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
    this.lock(false);
  }

  onDelete() {
    const { client, dispatch, user, mp } = this.props;
    if (user) dispatch(deleteUser(client, user.id));
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Delete",
        ...(user && user.id && { "Object Id": user.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
    this.lock();
  }

  render() {
    const { establishments, positions, user, write } = this.props;
    const {
      lastname,
      firstname,
      staff_id,
      establishment,
      phone,
      position,
      street,
      city,
      zipcode,
      locked,
      status,
      reason,
      leavingDate,
      errors
    } = this.state;

    const positionsToShow = positions;
    // positionsToShow.push(positions[6]);
    // positionsToShow.push(positions[8]);
    // positionsToShow.push(positions[9]);
    // positionsToShow.push(position);

    return (
      <Fragment>
        <div className="row">
          <div className="form-group col-4">
            <label className="form-label small">Vorname</label>
            <input
              type="text"
              className="form-control"
              name="firstname"
              value={firstname}
              disabled={locked}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-4">
            <label className="form-label small">Name</label>
            <input
              type="text"
              className="form-control"
              name="lastname"
              value={lastname}
              disabled={locked}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-4">
            <label className="form-label small">Personalnummer</label>
            <input
              type="text"
              className={`form-control`}
              name="staff_id"
              value={staff_id}
              disabled={locked}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-4">
            <label className="form-label small">Niederlassung</label>
            <Select
              isDisabled={locked}
              isSearchable={false}
              placeholder=""
              value={establishment}
              options={establishments}
              onChange={this.handleEstablishmentChange}
            />
          </div>

          <div className="form-group col-4">
            <label className="form-label small">Telefonnummer</label>
            <input
              type="text"
              className={`form-control ${errors.phone ? "is-invalid" : ""}`}
              name="phone"
              value={phone}
              disabled={locked}
              onChange={this.handleInputChange}
            />
            {errors.phone ? (
              <small className="form-text text-danger">
                Darf nur Zahlen enthalten.
              </small>
            ) : null}
          </div>
          <div className="form-group col-4">
            <label className="form-label small">Position</label>
            <select
              className="form-control"
              name="position"
              value={position}
              disabled={locked}
              onChange={this.handleInputChange}
            >
              {positionsToShow.map(positionVal => (
                <option key={positionVal}>{positionVal}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-6">
            <label className="form-label small">Straße</label>
            <input
              type="text"
              className="form-control"
              name="street"
              value={street}
              disabled={locked}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-2">
            <label className="form-label small">Postleitzahl</label>
            <input
              type="text"
              className="form-control"
              name="zipcode"
              value={zipcode}
              disabled={locked}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-4">
            <label className="form-label small">Stadt</label>
            <input
              type="text"
              className="form-control"
              name="city"
              value={city}
              disabled={locked}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-4">
            <label className="form-label small">Status</label>
            <select
              className="form-control"
              name="status"
              value={status}
              disabled={locked}
              onChange={this.handleInputChange}
            >
              {["Aktiv", "Inaktiv"].map(stateOption => (
                <option key={stateOption}>{stateOption}</option>
              ))}
            </select>
          </div>
          {status === "Inaktiv" ? (
            <Fragment>
              <div className="form-group col-4">
                <label className="form-label small">Grund</label>
                <select
                  className="form-control"
                  name="reason"
                  value={reason || ""}
                  disabled={locked}
                  onChange={this.handleInputChange}
                >
                  <option />
                  {["Kündigung AG", "Kündigung AN", "Rente"].map(
                    reasonOption => (
                      <option key={reasonOption} value={reasonOption}>
                        {reasonOption}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="form-group col-4">
                <label className="form-label small">Austrittsdatum</label>
                <input
                  type="date"
                  className="form-control"
                  name="leavingDate"
                  value={leavingDate || ""}
                  disabled={locked}
                  onChange={this.handleInputChange}
                />
              </div>
            </Fragment>
          ) : null}
        </div>

        {write ? (
          <div className="order-fixed-bottom border-top">
            <Button
              className="row pt-3 pl-3 hidden-print mr-2"
              onSave={this.onSave.bind(this)}
              onDelete={this.onDelete.bind(this)}
              unlock={this.unlock.bind(this)}
              lock={this.lock.bind(this)}
              chosen={Boolean(user)}
              locked={locked}
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

Data.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  establishment: PropTypes.object,
  establishments: PropTypes.array,
  user: PropTypes.object,
  positions: PropTypes.array,
  salt: PropTypes.string,
  write: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishments: state.main.get("establishments"),
  positions: state.humanResources.get("positions")
}))(Data);
