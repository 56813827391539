import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { faTimes, faSync } from "@fortawesome/pro-regular-svg-icons";
import { faCog } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { groupBy } from "lodash";

import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";

import ToggleButton from "../../ToggleButton";

import TimePeriodHead from "../../Gantt/timePeriodHead";
import TimeControl from "../../Gantt/timeControl";
import List from "./list";
import Reservations from "./reservations";
import LoadingOverlay from "./lodingOverlay";

import getResourcesWithReservations from "../../../actions/getResourcesWithReservations";
import getVehiclesWithReservations from "../../../actions/getVehiclesWithReservations";
import getCombinationsWithReservations from "../../../actions/getCombinationsWithReservations";
import getTransfersForPeriod from "../../../actions/getTransfersForPeriod";
import setGanttDate from "../../../actions/setGanttDate";
import getOrdersSubcontractor from "../../../actions/getOrdersSubcontractor";
import createSubcontractor from "../../../actions/createSubcontractor";

class Gantt extends Component {
  static groupResources(resources) {
    const colors = {
      "35": "#d82919",
      "50": "#ff9a91",
      "60": "#1a63d8",
      "100": "#a1bae2",
      "120": "#ef9306",
      "130": "#ffef8b",
      "150": "#35a815",
      "200": "#bdf971",
      "220": "#c551ff",
      misc: "none"
    };

    const groups = {
      "35": [],
      "50": [],
      "60": [],
      "100": [],
      "120": [],
      "130": [],
      "150": [],
      "200": [],
      "220": [],
      misc: []
    };

    resources.forEach(resource => {
      const { subcategory } = resource;
      if (subcategory && subcategory !== "") {
        groups[subcategory].push(
          Object.assign(resource, { color: colors[subcategory] })
        );
      } else {
        groups.misc.push(Object.assign(resource, { color: colors.misc }));
      }
    });

    let groupedResources = [];

    Object.keys(groups).forEach(key => {
      groupedResources = groupedResources.concat(
        groups[key].sort((a, b) => a.name.localeCompare(b.name))
      );
    });

    return groupedResources;
  }

  constructor(props) {
    super(props);

    this.state = {
      filteredResources: [],
      combinationsLength: -1,
      showHours: false,
      filterValuesCustom: [],
      filterValuesCategory: [],
      filterValueLocation: props.establishment
        ? props.establishment
        : { value: "", label: "Alle" },
      filterOrder: null,
      backgroundPosition: "0px 0px",
      selectedDaySpan: [-1, -1],
      hoursStartDate: null,
      hoursEndDate: null,

      showSettings: false,
      showAbsences: false,
      showGroups: false
    };

    this.filterSelectStyles = {
      control: (provided, state) => ({
        ...provided,
        minHeight: 43,
        borderRadius: 0,
        border: "none"
      })
    };

    this.categorySelectStyles = {
      control: (provided, state) => ({
        ...provided,
        marginLeft: 1,
        minHeight: 39,
        borderRadius: 0,
        border: "none"
      }),
      menu: styles => ({
        ...styles,
        top: "auto",
        bottom: "100%",
        zIndex: 100
      })
    };

    this.filterOptions = [
      { value: "großfräsen", label: "Großfräsen" },
      { value: "kleinfräsen", label: "Kleinfräsen" },
      { value: "kompaktlader", label: "Kompaktlader" },
      { value: "zertrümmerer", label: "Zertrümmerer" },
      { value: "zusatzgeräte", label: "Zusatzgeräte" },
      { value: "tieflader", label: "Tieflader" },
      { value: "werkstattauto", label: "Werkstattauto" },
      { value: "fremdmaschinen", label: "Fremdmaschinen" },

      { value: "pkw", label: "PKW" },
      { value: "lkw", label: "LKW" },
      { value: "anhänger", label: "Anhänger" },
      { value: "zugmaschine", label: "Zugmaschine" },
      { value: "wasserwagen", label: "Wasserwagen" },

      { value: "gespanne", label: "Gespanne" }
    ];

    // this.locationOptions = [
    //   { value: '', label: 'Alle' },
    //   { value: 'leipzig', label: 'Leipzig' },
    //   { value: 'duben', label: 'Duben' }
    // ];

    // this.state.filterValueLocation = this.locationOptions[0];

    this.filterSelect = React.createRef();

    this.filterResources = this.filterResources.bind(this);

    this.toggleView = this.toggleView.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.setSelectedDaySpan = this.setSelectedDaySpan.bind(this);

    this.applyResourceFilters = this.applyResourceFilters.bind(this);
    this.applyVehicleFilters = this.applyVehicleFilters.bind(this);
    this.applyCombinationFilters = this.applyCombinationFilters.bind(this);

    this.handleCustomFilterChange = this.handleCustomFilterChange.bind(this);
    this.handleCategoryFilterChange = this.handleCategoryFilterChange.bind(
      this
    );
    this.handleLocationFilterChange = this.handleLocationFilterChange.bind(
      this
    );
    this.handleOrderFilterChange = this.handleOrderFilterChange.bind(this);
    this.handleSubcontractorCreateChange = this.handleSubcontractorCreateChange.bind(
      this
    );
    this.handleResourceSelect = this.handleResourceSelect.bind(this);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.handleSettingsChange = this.handleSettingsChange.bind(this);
  }

  componentDidMount() {
    const { startDate, establishment } = this.props;

    let endOfWeek = moment(startDate);
    endOfWeek = endOfWeek.endOf("isoWeek");
    const dayOffset = endOfWeek.diff(startDate, "days") - 6;

    this.setState({
      backgroundPosition: `${dayOffset * 120}px 0px`
    });

    const filterValues = JSON.parse(
      window.localStorage.getItem("machineDispoFilterValues")
    );
    if (filterValues) {
      this.setState({ filterValuesCategory: filterValues });
    }

    // const locationFilterValue = JSON.parse(
    //   window.localStorage.getItem('machineDispoLocationFilterValue')
    // );
    // if (locationFilterValue) {
    //   this.setState({ filterValueLocation: locationFilterValue });
    // }

    this.setState({
      filterValueLocation: establishment || { value: "", label: "Alle" }
    });

    const settings = JSON.parse(
      window.localStorage.getItem("machineDispoSettings")
    );
    if (settings) {
      this.setState(settings);
    }

    document.addEventListener("keydown", this.handleKeyDown);

    this.filterResources();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      startDate,
      resources,
      vehicles,
      combinations,
      orders,
      subcontractors
    } = this.props;

    const {
      showHours,
      selectedDaySpan,
      filterValuesCustom,
      filterValuesCategory,
      filterValueLocation,
      filterOrder
    } = this.state;

    if (
      prevState.showHours !== showHours ||
      prevProps.startDate !== startDate
    ) {
      let offset = showHours ? Math.max(selectedDaySpan[0], 0) : 0;
      offset %= 7;

      let endOfWeek = moment(startDate);
      endOfWeek = endOfWeek.endOf("isoWeek");
      const dayOffset = endOfWeek.diff(startDate, "days") - 6;
      const factor = showHours ? 480 : 120;
      const x = dayOffset - offset;

      this.setState({
        backgroundPosition: `${x * factor}px 0px`
      });
    }

    if (
      prevProps.resources !== resources ||
      prevProps.vehicles !== vehicles ||
      prevProps.combinations !== combinations ||
      prevProps.orders !== orders ||
      prevProps.subcontractors !== subcontractors ||
      prevState.filterValuesCustom !== filterValuesCustom ||
      prevState.filterValuesCategory !== filterValuesCategory ||
      prevState.filterValueLocation !== filterValueLocation ||
      prevState.filterOrder !== filterOrder
    ) {
      this.filterResources();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (e.ctrlKey && e.code === "KeyF") {
      if (this.filterSelect.current) {
        e.preventDefault();
        this.filterSelect.current.focus();
      }
    } else if (e.code === "Escape") {
      e.preventDefault();
      this.filterSelect.current.blur();
    }
  }

  filterResources() {
    const {
      resources,
      vehicles,
      combinations,
      transfers,
      orders,
      subcontractors
    } = this.props;
    const { filterValuesCustom, filterOrder } = this.state;

    const groupedOrders = groupBy(orders, "subcontractor");
    const filteredOrders = subcontractors.flatMap(subcontractor => {
      const subContractorOrders = groupedOrders[subcontractor];
      let heightFactor = 1;
      if (subContractorOrders) {
        heightFactor = Math.max(
          ...subContractorOrders.map(order => {
            const crossOrders = subContractorOrders.filter(subOrder => {
              const orderFinish = new Date(order.operational_period_finish);
              const subOrderStart = new Date(subOrder.operational_period_start);

              return orderFinish > subOrderStart;
            });
            return crossOrders.length || 1;
          })
        );
      }

      return {
        heightFactor,
        name: subcontractor,
        description: "Sub-Unternehmen",
        humanResources: [],
        reservations: subContractorOrders
          ? subContractorOrders.map(order => ({
              id: order.id,
              order,
              status: order.status,
              from: order.operational_period_start,
              to: order.operational_period_finish
            }))
          : [],
        type: "RESOURCE"
      };
    });

    const vehicleTransfers = transfers.filter(transfer => transfer.vehicle);
    const combiTransfers = transfers.filter(transfer => transfer.combination);

    let groupedResources = [];
    let filteredVehicles = [];
    let filteredCombinations = [];

    if (filterOrder) {
      const { id } = filterOrder;

      const filteredResources = resources
        .filter(resource =>
          resource.reservations.find(elem => elem.status === id)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      filteredVehicles = vehicles
        .filter(vehicle =>
          vehicle.reservations.find(elem => elem.status === id)
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      vehicleTransfers.forEach(transfer => {
        const filteredVehicle = filteredVehicles.find(
          v => v.id === transfer.vehicle.id
        );
        if (filteredVehicle) {
          filteredVehicle.reservations.push(transfer);
        }
      });

      filteredCombinations = combinations
        .filter(combination =>
          combination.reservations.find(elem => elem.status === id)
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      combiTransfers.forEach(transfer => {
        const filteredCombination = filteredCombinations.find(
          v => v.id === transfer.combination.id
        );
        if (filteredCombination) {
          filteredCombination.reservations.push(transfer);
        }
      });

      groupedResources = Gantt.groupResources(filteredResources);
    } else {
      const specialFilters = filterValuesCustom
        .filter(elem => elem.value.startsWith("!"))
        .map(elem => elem.value.substr(1));

      if (specialFilters.length > 0) {
        filteredCombinations = combinations
          .filter(elem => specialFilters.find(filter => filter === elem.name))
          .sort((a, b) => a.name.localeCompare(b.name));

        combiTransfers.forEach(transfer => {
          const filteredCombination = filteredCombinations.find(
            v => v.id === transfer.combination.id
          );
          if (filteredCombination) {
            filteredCombination.reservations.push(transfer);
          }
        });

        const resourceIds = filteredCombinations
          .map(elem => elem.resources.map(resource => resource.id))
          .flat();
        const vehicleIds = filteredCombinations
          .map(elem => elem.vehicles.map(vehicle => vehicle.id))
          .flat();

        const filteredResources = resources
          .filter(elem => resourceIds.find(id => elem.id === id))
          .sort((a, b) => a.name.localeCompare(b.name));
        filteredVehicles = vehicles
          .filter(elem => vehicleIds.find(id => elem.id === id))
          .sort((a, b) => a.name.localeCompare(b.name));

        vehicleTransfers.forEach(transfer => {
          const filteredVehicle = filteredVehicles.find(
            v => v.id === transfer.vehicle.id
          );
          if (filteredVehicle) {
            filteredVehicle.reservations.push(transfer);
          }
        });

        groupedResources = Gantt.groupResources(filteredResources);
      } else {
        const filteredResources = this.applyResourceFilters(resources).sort(
          (a, b) => a.name.localeCompare(b.name)
        );
        filteredVehicles = this.applyVehicleFilters(vehicles).sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        vehicleTransfers.forEach(transfer => {
          const filteredVehicle = filteredVehicles.find(
            v => v.id === transfer.vehicle.id
          );
          if (filteredVehicle) {
            filteredVehicle.reservations.push(transfer);
          }
        });

        filteredCombinations = this.applyCombinationFilters(combinations).sort(
          (a, b) => a.name.localeCompare(b.name)
        );

        combiTransfers.forEach(transfer => {
          const filteredCombination = filteredCombinations.find(
            v => v.id === transfer.combination.id
          );
          if (filteredCombination) {
            filteredCombination.reservations.push(transfer);
          }
        });

        groupedResources = Gantt.groupResources(filteredResources);
      }
    }

    this.setState({
      filteredResources: groupedResources
        .concat(filteredVehicles)
        .concat(filteredCombinations)
        .concat(filteredOrders),
      combinationsLength: filteredCombinations.length
    });
  }

  toggleView() {
    const { showHours, selectedDaySpan } = this.state;
    const { startDate, endDate } = this.props;
    if (showHours) {
      this.setState({
        showHours: false,
        hoursStartDate: null,
        hoursEndDate: null
      });
    } else if (selectedDaySpan[0] > -1) {
      const duration = selectedDaySpan[1] - selectedDaySpan[0];
      const start = moment(startDate);
      start.add(selectedDaySpan[0], "days");
      const end = moment(start);
      end.add(duration, "days");
      this.setState({
        showHours: true,
        hoursStartDate: start,
        hoursEndDate: end
      });
    } else {
      this.setState({
        showHours: true,
        hoursStartDate: startDate,
        hoursEndDate: endDate
      });
    }
  }

  handleDateChange(start, end) {
    const { dispatch, client } = this.props;
    dispatch(setGanttDate(start, end));
    dispatch(getResourcesWithReservations(client, start, end));
    dispatch(getVehiclesWithReservations(client, start, end));
    dispatch(getCombinationsWithReservations(client, start, end));
    dispatch(getTransfersForPeriod(client, start, end));
    dispatch(getOrdersSubcontractor(client, start, end));
  }

  onFilterChange(e) {
    this.setState({ filter: e.target.value });
  }

  setSelectedDaySpan(start, end) {
    this.setState({ selectedDaySpan: [start, end] });
  }

  handleCustomFilterChange(values) {
    this.setState({
      filterValuesCustom: values.filter(value => value.value.trim() !== "")
    });
  }

  handleCategoryFilterChange(values) {
    window.localStorage.setItem(
      "machineDispoFilterValues",
      JSON.stringify(values)
    );
    this.setState({ filterValuesCategory: values });
  }

  handleLocationFilterChange(value) {
    window.localStorage.setItem(
      "machineDispoLocationFilterValue",
      JSON.stringify(value)
    );
    this.setState({ filterValueLocation: value });
  }

  handleOrderFilterChange(order) {
    this.setState({ filterOrder: order });
  }

  handleSubcontractorCreateChange(e) {
    if (e.key === "Enter") {
      const { value } = e.target;
      const { dispatch, client } = this.props;
      dispatch(createSubcontractor(client, value));
      e.target.value = "";
    }
  }

  applyResourceFilters(resources) {
    const {
      filterValuesCustom,
      filterValuesCategory,
      filterValueLocation
    } = this.state;
    let filteredResources = resources;

    if (filterValueLocation.value !== "") {
      const val = filterValueLocation.value.toUpperCase();
      filteredResources = filteredResources.filter(
        elem =>
          (elem.establishment && elem.establishment.toUpperCase() === val) ||
          elem.reservations.find(
            reservation =>
              reservation.order &&
              reservation.order.cost_center &&
              reservation.order.cost_center.establishment.toUpperCase() === val
          )
      );
    }

    if (filterValuesCategory.length > 0) {
      filteredResources = filteredResources.filter(elem => {
        if (elem.category) {
          const a = elem.category.toLowerCase();
          return filterValuesCategory.reduce((acc, val) => {
            const match = a.indexOf(val.value.toLowerCase()) > -1;
            return acc || match;
          }, false);
        }
        return false;
      });
    }

    if (filterValuesCustom.length > 0) {
      filteredResources = filteredResources.filter(elem => {
        let result = false;
        if (elem.name) {
          const a = elem.name.toLowerCase();
          result = filterValuesCustom.reduce((acc, val) => {
            const match = a.indexOf(val.value.toLowerCase()) > -1;
            return acc || match;
          }, false);
        }
        if (elem.number) {
          const a = elem.number.toLowerCase();
          result =
            result ||
            filterValuesCustom.reduce((acc, val) => {
              const match = a.indexOf(val.value.toLowerCase()) > -1;
              return acc || match;
            }, false);
        }
        if (elem.humanResources) {
          elem.humanResources.forEach((human, i) => {
            const a = `${human.firstname} ${human.lastname}`.toLowerCase();
            result =
              result ||
              filterValuesCustom.reduce((acc, val) => {
                const match = a.indexOf(val.value.toLowerCase()) > -1;
                return acc || match;
              }, false);
          });
        }

        return result;
      });
    }

    return filteredResources;
  }

  applyVehicleFilters(vehicles) {
    const {
      filterValuesCustom,
      filterValuesCategory,
      filterValueLocation
    } = this.state;
    let filteredVehicles = vehicles;

    if (filterValueLocation.value !== "") {
      const val = filterValueLocation.value.toUpperCase();
      filteredVehicles = filteredVehicles.filter(
        elem =>
          (elem.establishment && elem.establishment.toUpperCase() === val) ||
          elem.reservations.find(
            reservation =>
              reservation.order &&
              reservation.order.cost_center &&
              reservation.order.cost_center.establishment.toUpperCase() === val
          )
      );
    }

    if (filterValuesCategory.length > 0) {
      filteredVehicles = filteredVehicles.filter(elem => {
        if (elem.category) {
          const a = elem.category.toLowerCase();
          return filterValuesCategory.reduce((acc, val) => {
            const match = a.indexOf(val.value.toLowerCase()) > -1;
            return acc || match;
          }, false);
        }
        return false;
      });
    }

    if (filterValuesCustom.length > 0) {
      filteredVehicles = filteredVehicles.filter(elem => {
        let result = false;
        if (elem.name) {
          const a = elem.name.toLowerCase();
          result = filterValuesCustom.reduce((acc, val) => {
            const match = a.indexOf(val.value.toLowerCase()) > -1;
            return acc || match;
          }, false);
        }
        if (elem.licensePlate) {
          const a = elem.licensePlate.toLowerCase();
          result =
            result ||
            filterValuesCustom.reduce((acc, val) => {
              const match = a.indexOf(val.value.toLowerCase()) > -1;
              return acc || match;
            }, false);
        }
        if (elem.humanResources) {
          elem.humanResources.forEach((human, i) => {
            const a = `${human.firstname} ${human.lastname}`.toLowerCase();
            result =
              result ||
              filterValuesCustom.reduce((acc, val) => {
                const match = a.indexOf(val.value.toLowerCase()) > -1;
                return acc || match;
              }, false);
          });
        }

        return result;
      });
    }

    return filteredVehicles;
  }

  applyCombinationFilters(combinations) {
    const {
      filterValuesCustom,
      filterValuesCategory,
      filterValueLocation
    } = this.state;
    let filteredCombinations = combinations;

    if (filterValueLocation.value !== "") {
      const val = filterValueLocation.value.toUpperCase();
      filteredCombinations = filteredCombinations.filter(
        elem =>
          (elem.establishment && elem.establishment.toUpperCase() === val) ||
          elem.reservations.find(
            reservation =>
              reservation.order &&
              reservation.order.cost_center &&
              reservation.order.cost_center.establishment.toUpperCase() === val
          )
      );
    }

    if (
      filterValuesCategory.length > 0 &&
      !filterValuesCategory.find(category => category.value === "gespanne")
    )
      return [];

    if (filterValuesCustom.length > 0) {
      filteredCombinations = filteredCombinations.filter(elem => {
        let result = false;
        if (elem.name) {
          const a = elem.name.toLowerCase();
          result = filterValuesCustom.reduce((acc, val) => {
            const match = a.indexOf(val.value.toLowerCase()) > -1;
            return acc || match;
          }, false);
        }

        return result;
      });
    }

    return filteredCombinations;
  }

  handleResourceSelect(e) {
    const { name } = e.currentTarget.dataset;
    this.setState({
      filterValuesCustom: [{ label: `!${name}`, value: `!${name}` }]
    });
  }

  toggleSettings() {
    const { showSettings } = this.state;
    this.setState({ showSettings: !showSettings });
  }

  handleSettingsChange(name, value) {
    const { showAbsences, showGroups } = this.state;
    const settings = { showAbsences, showGroups };
    settings[name] = value;

    window.localStorage.setItem(
      "machineDispoSettings",
      JSON.stringify(settings)
    );

    this.setState({ [name]: value });
  }

  render() {
    const {
      history,
      startDate,
      endDate,
      loading,
      write,
      hideTimeControl,
      conflictingResources
    } = this.props;
    const {
      combinationsLength,
      showHours,
      filterValuesCustom,
      filterValuesCategory,
      filterOrder,
      backgroundPosition,
      selectedDaySpan,
      hoursStartDate,
      hoursEndDate,
      showSettings,
      showAbsences,
      showGroups
    } = this.state;
    let { filteredResources } = this.state;

    const from = hoursStartDate || startDate;
    const to = hoursEndDate || endDate;

    const numberOfDays = to.diff(from, "days") + 1;
    const width = numberOfDays * (showHours ? 480 : 120);

    const timeControlDisabled =
      selectedDaySpan[0] >= 0 && selectedDaySpan[1] >= 0 && showHours;

    let filterOrderText = "";
    if (filterOrder) {
      filterOrderText = filterOrder.description;
      const costCenter = filterOrder.cost_center;
      if (costCenter) {
        filterOrderText =
          costCenter.building_project !== ""
            ? costCenter.building_project
            : "kein Bauvorhaben definiert";
        filterOrderText += ` (${costCenter.value})`;
      }
    }

    filteredResources = filteredResources.filter(
      r =>
        r.status !== "Inaktiv" ||
        (r.status === "Inaktiv" && moment(r.leavingDate) > startDate)
    );
    filteredResources = filteredResources.filter(r => {
      if (r.type === "RESOURCE") {
        const isSubcontractor = r.description === "Sub-Unternehmen";
        const existingWithToType = r.reservations.find(
          reservation => reservation.order && reservation.order.type === 2
        );
        return !isSubcontractor || (isSubcontractor && existingWithToType);
      }
      return true;
    });

    return (
      <Fragment>
        <div className="gantt-viewer-container">
          <div
            className="gantt-container"
            style={{
              width: width + 320,
              maxWidth: width + 320,
              minWidth: width + 320
            }}
          >
            <div className="gantt-machine-filter border-right">
              <div
                className="gantt-machine-filter-control"
                style={{ display: "flex" }}
              >
                <div
                  className="p-2 cursor-pointer"
                  style={{ width: 32 }}
                  onClick={() =>
                    this.props.dispatch({
                      type: "SET_CONFLICTING_RESOURCES",
                      data: []
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </div>
                {filterOrder ? (
                  <div style={{ width: "calc(100% - 32px)" }}>
                    <span className="font-weight-bold small">
                      &nbsp;Zeige Abruf:
                    </span>
                    <span
                      className="d-block small"
                      style={{ marginTop: -2, height: 15 }}
                    >
                      &nbsp;{filterOrderText}
                    </span>
                    <button
                      className="position-absolute d-block btn-no-style fa-btn fa-btn-secondary"
                      style={{ top: 4, right: 0 }}
                      onClick={() => this.handleOrderFilterChange(null)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                ) : (
                  <div style={{ width: "calc(100% - 32px)" }}>
                    <CreatableSelect
                      styles={this.filterSelectStyles}
                      isMulti
                      isClearable
                      placeholder="Filter eingeben..."
                      noOptionsMessage={() => "Hinzufügen mit Eingabe"}
                      formatCreateLabel={inputValue => `+ ${inputValue}`}
                      value={filterValuesCustom}
                      ref={this.filterSelect}
                      onChange={this.handleCustomFilterChange}
                    />
                  </div>
                )}
              </div>
            </div>

            <TimePeriodHead
              width={width}
              startDate={from}
              endDate={to}
              showHours={showHours}
              dayWidth={120}
              hourWidth={40}
              onSelection={this.setSelectedDaySpan}
            />

            <List
              resources={filteredResources}
              conflictingResources={conflictingResources}
              onSelect={this.handleResourceSelect}
            />

            <Reservations
              history={history}
              width={width}
              resources={filteredResources}
              combinationsLength={combinationsLength}
              start={from}
              end={to}
              showHours={showHours}
              showAbsences={showAbsences}
              showGroups={showGroups}
              backgroundPosition={backgroundPosition}
              highlight={selectedDaySpan}
              loading={loading}
              write={write}
              onSelectOrder={this.handleOrderFilterChange}
            />
          </div>

          <LoadingOverlay show={loading} />
        </div>
        <div className="row border-top m-0">
          <div className={`border-right p-0 px-1`} style={{ width: 320 }}>
            <input
              className="form-control"
              placeholder="Sub-Unternehmen hinzufügen..."
              onKeyDown={this.handleSubcontractorCreateChange}
            />
          </div>
          <div className={`col${hideTimeControl ? "" : "-4"} border-right p-0`}>
            <Select
              isMulti
              styles={this.categorySelectStyles}
              placeholder="Katagorie filtern..."
              noOptionsMessage={() => "Keine Übereinstimmung"}
              options={this.filterOptions}
              value={filterValuesCategory}
              onChange={this.handleCategoryFilterChange}
            />
          </div>
          <div
            className={`col${hideTimeControl ? "-auto p-0" : ""} border-right`}
          >
            <TimeControl
              showHours={showHours}
              startDate={from}
              endDate={to}
              hideRangeControls={hideTimeControl}
              onToggleView={this.toggleView}
              onDateChange={this.handleDateChange}
              disabled={timeControlDisabled}
            />
          </div>
          <div className="col-auto py-1">
            <button
              className="btn btn-outline-secondary btn-sm px-3"
              style={{ height: 33 }}
              onClick={this.toggleSettings}
            >
              <FontAwesomeIcon icon={faCog} />
            </button>
          </div>
        </div>

        {showSettings ? (
          <div className="gantt-viewer-settings shadow">
            <ul className="list-group">
              <li className="list-group-item">
                Abwesenheiten
                <ToggleButton
                  className="float-right ml-3"
                  name="showAbsences"
                  value={showAbsences}
                  onChange={this.handleSettingsChange}
                />
              </li>
              <li className="list-group-item">
                Gruppierung einfärben
                <ToggleButton
                  className="float-right ml-3"
                  name="showGroups"
                  value={showGroups}
                  onChange={this.handleSettingsChange}
                />
              </li>
            </ul>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

Gantt.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  history: PropTypes.object,
  establishment: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resources: PropTypes.array,
  vehicles: PropTypes.array,
  transfers: PropTypes.array,
  orders: PropTypes.array,
  subcontractors: PropTypes.array,
  combinations: PropTypes.array,
  conflictingResources: PropTypes.array,
  loading: PropTypes.bool,
  write: PropTypes.bool,
  hideTimeControl: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment"),
  resources: state.disposition.get("resources"),
  vehicles: state.disposition.get("vehicles"),
  transfers: state.disposition.get("transfers"),
  orders: state.disposition.get("orders"),
  conflictingResources: state.disposition.get("conflictingResources"),
  subcontractors: state.orders.get("subcontractors"),
  combinations: state.disposition.get("combinations")
}))(Gantt);
