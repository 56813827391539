import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPlus,
  faTimes
} from '@fortawesome/pro-regular-svg-icons/index';

class Input extends Component {
  static defaultProps = {
    editMode: false
  };

  constructor() {
    super();

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    const { data, onDataChange } = this.props;
    const { name, value } = e.target;

    onDataChange({
      ...data,
      [name]: value
    });
  }

  render() {
    const {
      data,
      absenceTypes,
      editMode,
      locked,
      onCancel,
      onSave
    } = this.props;

    const { type, startDate, endDate } = data;

    const saveEnabled = !locked && startDate !== '' && endDate !== '';

    return (
      <div className="row employee-absence-entry">
        <div className="col-md-3 border-bottom border-right border-left p-2">
          <select
            className="form-control"
            name="type"
            value={type}
            onChange={this.handleInputChange}
            disabled={locked}>
            {absenceTypes.map(absenceTyp => (
              <option key={absenceTyp}>{absenceTyp}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3 border-bottom border-right p-2">
          <input
            type="date"
            className="form-control"
            id="startDate"
            name="startDate"
            value={startDate}
            onChange={this.handleInputChange}
            disabled={locked}
          />
        </div>
        <div className="col-md-3 border-bottom border-right p-2">
          <input
            type="date"
            className="form-control"
            id="endDate"
            name="endDate"
            value={endDate}
            onChange={this.handleInputChange}
            disabled={locked}
          />
        </div>
        <div className="col-md-3 border-bottom border-right p-2">
          {editMode ? (
            <div className="float-right">
              <button
                style={{ width: 32 }}
                className="btn btn-danger btn-sm mr-2"
                onClick={onCancel}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <button
                style={{ width: 32 }}
                className="btn btn-success btn-sm"
                onClick={onSave}>
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>
          ) : (
            <button
              style={{ width: 72 }}
              className="btn btn-primary btn-sm float-right"
              onClick={onSave}
              disabled={!saveEnabled}>
              <FontAwesomeIcon icon={faPlus} />
              {/* <div */}
              {/* className="btn btn-primary create-absence w-100" */}
              {/* onClick={onSave}> */}
              {/* Abwesenheit hinzufügen */}
              {/* </div> */}
            </button>
          )}
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  data: PropTypes.object,
  absenceTypes: PropTypes.array,
  onDataChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  editMode: PropTypes.bool,
  locked: PropTypes.bool
};

export default Input;
