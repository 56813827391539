import mixpanel from "mixpanel-browser";
import { createContext, useContext } from "react";

export const MixPanelContext = createContext({ mp: {} });
MixPanelContext.displayName = "MixPanelContext";

export const useMixPanel = () => useContext(MixPanelContext);

export class MixPanel {
  static instance;
  isInit = false;
  _mp = mixpanel;
  constructor(token, user) {
    this._mp.init(token, {
      api_host: "https://api-eu.mixpanel.com"
    });
    this._mp.identify(user.userId);
    this._mp.people.set({
      $user: user.name
    });
    this.isInit = true;
  }
  static getInstance(token, user) {
    if (!MixPanel.instance) {
      if (!token || !user)
        throw new Error("token field is missing for initializing mixpanel");
      MixPanel.instance = new MixPanel(token, user);
    }
    return MixPanel.instance;
  }
  /**
   * @description custom track function with custom typechecks for event collection
   * @param event_name
   * @param properties
   * @param callback
   */
  track(event_name, properties, callback) {
    if (!this.isInit) {
      console.warn("Calling Track Without Initializing MixPanel!");
      return;
    }
    this._mp.track(event_name, properties, callback);
  }
  /**
   * @description this method calls the normal track method defined for mixpanel
   *
   * @param params
   */
  _track(...params) {
    if (!this.isInit) {
      console.warn("Calling Track Without Initializing MixPanel!");
      return;
    }
    this._mp.track.apply(null, params);
  }
}
