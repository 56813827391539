import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import XLSX from "xlsx";

import "./print.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import Calendar from "react-calendar";
import {
  addDays,
  dateToMonthRange,
  endOfDay,
  getWeekNumber,
  monthStringToMonth,
  monthToString,
  toDateString,
} from "../../util/dateHelper";

import List from "./list";

import getOrder from "../../actions/getOrder";
import getClosedOrdersOverviewInRange from "../../actions/getClosedOrdersOverviewInRange";
import getUsers from "../../actions/getUsers";
import getResources from "../../actions/getResources";
import getCostCenters from "../../actions/getCostCenters";
import getSubcontractors from "../../actions/getSubcontractors";
import TitleBar from "../TitleBar";
import getOpenOrders from "../../actions/getOpenOrders";
import getOpenTransfers from "../../actions/getOpenTransfers";

import getClosedTransfersOverviewInRange from "../../actions/getClosedTransfersOverviewInRange";
import getOrdersOverviewInRange from "../../actions/getOrdersOverviewInRange";
import getTransfersOverviewInRange from "../../actions/getTransfersOverviewInRange";
import getOpenOrdersOverviewInRange from "../../actions/getOpenOrdersOverviewInRange";
import getOpenTransfersOverviewInRange from "../../actions/getOpenTransfersOverviewInRange";

const checkDate = (dateType, dateString, cb) => {
  switch (dateType) {
    case "DAY": {
      const d = new Date(dateString);
      // An invalid date object returns NaN for getTime() and NaN is the only object not strictly equal to itself.
      // eslint-disable-next-line no-self-compare
      if (d.getTime() === d.getTime()) {
        cb();
      }
      break;
    }

    case "WEEK": {
      const dateStringSplit = dateString.split(" ");
      if (dateStringSplit.length > 1) {
        if (
          dateStringSplit[0] === "KW" &&
          !isNaN(parseInt(dateStringSplit[1], 10))
        ) {
          cb();
        }
      }
      break;
    }

    case "MONTH": {
      const dateStringSplit = dateString.split(" ");
      if (dateStringSplit.length > 1) {
        const month = monthStringToMonth(dateStringSplit[0]);
        if (month > 0 && !isNaN(parseInt(dateStringSplit[1], 10))) {
          cb();
        }
      }
      break;
    }

    default:
      break;
  }
};

const downloadFile = (file) => {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

const options = {
  weekday: "short",
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
};
const dateToString = (date) => date.toLocaleDateString("de-DE", options);

class App extends Component {
  constructor(props) {
    super(props);

    document.title = "Abrechnung";

    const { client, dispatch, location, chosenOrder } = props;

    const dateFilterValues = JSON.parse(
      window.localStorage.getItem("orderDateFilterValues")
    );

    const dateRange = dateToMonthRange(new Date());

    this.state = {
      showCalendar: false,
      view: "LIST",
      date: dateRange,
      dateType: "MONTH",
      dateString: `${monthToString(
        dateRange[0].getMonth()
      )} ${dateRange[1].getFullYear()}`,
      openView: "Open",
    };

    if (dateFilterValues) {
      const { date, dateString, dateType } = dateFilterValues;

      if (date) {
        if (date.length) {
          this.state.date = [new Date(date[0]), new Date(date[1])];
        } else {
          this.state.date = new Date(date);
        }
      }

      const setDateData = () => {
        this.state.dateType = dateType;
        this.state.dateString = dateString;
      };
      checkDate(dateType, dateString, setDateData);
    }

    const path = location.pathname.split("/");
    const id = path[2];

    this.print = path.length > 3 && path[3] === "print";

    if (id !== undefined && id !== "") {
      dispatch(getOrder(client, id));
    } else if (!chosenOrder) {
      this.state.view = "LIST";
    }

    this.loadData = this.loadData.bind(this);
    this.loadData();

    dispatch(getUsers(client));
    dispatch(getResources(client));
    dispatch(getCostCenters(client));
    dispatch(getSubcontractors(client));

    this.addDate = this.addDate.bind(this);
    this.subDate = this.subDate.bind(this);
    this.handleDaySelect = this.handleDaySelect.bind(this);
    this.handleWeekSelect = this.handleWeekSelect.bind(this);
    this.handleMonthSelect = this.handleMonthSelect.bind(this);

    this.handleViewChange = this.handleViewChange.bind(this);

    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.setOpenView = this.setOpenView.bind(this);

    this.exportCSV = this.exportCSV.bind(this);
  }

  componentDidMount() {
    const { mp } = this.props;
    if (mp && mp.isInit && mp._mp) {
      const tracking_obj_param = {
        Screen: document.title,
        "Tracking Time": new Date().toDateString()
      };
      mp._mp.track("Screen View", tracking_obj_param);
    }
  }

  componentWillUnmount() {
    const { date, dateType, dateString } = this.state;

    window.localStorage.setItem(
      "orderDateFilterValues",
      JSON.stringify({ date, dateType, dateString })
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.chosenOrder && this.props.chosenOrder) {
      const date = new Date(this.props.chosenOrder.start_meeting_time);
      const dateRange = dateToMonthRange(date);
      this.setState(
        {
          date: dateRange,
          dateString: `${monthToString(
            dateRange[0].getMonth()
          )} ${dateRange[1].getFullYear()}`,
        },
        () => this.loadData()
      );
    }
  }

  loadData() {
    const { client, dispatch } = this.props;
    const { view, date, openView } = this.state;

    if (openView === "Open") {
      let from = date;
      let to = date;
      if (date.length) {
        // eslint-disable-next-line prefer-destructuring
        from = date[0];
        // eslint-disable-next-line prefer-destructuring
        to = date[1];
      }

      if (!from || !to) {
        return;
      }

      to = endOfDay(to);

      if (view === "LIST") {
        dispatch(getOpenOrdersOverviewInRange(client, from, to));
        dispatch(getOpenTransfersOverviewInRange(client, from, to));
      }
    } else if (openView === "Ongoing") {
      const newdate = new Date();
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      today.setMonth(newdate.getMonth());
      newdate.setMonth(newdate.getMonth() + 12);
      let from = today;
      let to = newdate;

      if (newdate.length) {
        // eslint-disable-next-line prefer-destructuring
        from = today;
        // eslint-disable-next-line prefer-destructuring
        to = newdate;
      }

      if (!from || !to) {
        return;
      }

      to = endOfDay(to);

      if (view === "LIST") {
        dispatch(getOrdersOverviewInRange(client, from, to, true));
        dispatch(getTransfersOverviewInRange(client, from, to));
      }
    } else {
      let from = date;
      let to = date;
      if (date.length) {
        // eslint-disable-next-line prefer-destructuring
        from = date[0];
        // eslint-disable-next-line prefer-destructuring
        to = date[1];
      }

      if (!from || !to) {
        return;
      }

      to = endOfDay(to);

      if (view === "LIST") {
        dispatch(getClosedOrdersOverviewInRange(client, from, to));
        dispatch(getClosedTransfersOverviewInRange(client, from, to));
      }
    }
  }

  handleViewChange(e) {
    const { view } = this.state;
    let value = "";
    if (typeof e === "string") value = e;
    else value = e.currentTarget.dataset.view;

    if (value !== "" && view !== value) {
      this.loadData();
      this.setState({ view: value });
    }
  }

  addDate() {
    this.alterDate(false);
  }

  subDate() {
    this.alterDate(true);
  }

  alterDate(back) {
    const { date, dateType, dateDisplayValue } = this.state;

    const factor = back ? -1 : 1;

    let nextDateValue = date;
    let nextDisplayValue = dateDisplayValue;

    switch (dateType) {
      case "DAY":
        nextDateValue = addDays(date, factor);
        nextDisplayValue = toDateString(nextDateValue);
        break;
      case "WEEK":
        nextDateValue = [
          addDays(date[0], 7 * factor),
          addDays(date[1], 7 * factor),
        ];
        nextDisplayValue = `KW ${getWeekNumber(nextDateValue[0])}`;
        break;
      case "MONTH": {
        const d = date[0];
        const startDate = new Date(d.getFullYear(), d.getMonth() + factor, 1);
        const endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        );
        nextDateValue = [startDate, endDate];
        nextDisplayValue = `${monthToString(
          startDate.getMonth()
        )} ${startDate.getFullYear()}`;
        break;
      }
      default:
        break;
    }

    this.handleDateChange(nextDateValue, dateType, nextDisplayValue);
  }

  handleDateChange(date, dateType, dateString) {
    this.setState({ date, dateType, dateString }, this.loadData);
  }

  handleDaySelect(date) {
    this.handleDateChange(date, "DAY", toDateString(date));
  }

  handleWeekSelect(weekNumber, date) {
    this.handleDateChange([date, addDays(date, 6)], "WEEK", `KW ${weekNumber}`);
  }

  handleMonthSelect(date) {
    this.handleDateChange(
      dateToMonthRange(date),
      "MONTH",
      `${monthToString(date.getMonth())} ${date.getFullYear()}`
    );
  }

  toggleCalendar() {
    this.setState((state) => ({ showCalendar: !state.showCalendar }));
  }

  setOpenView(openView) {
    this.setState({ openView }, this.loadData);
  }

  exportCSV(orders) {
    const { date } = this.state;
    let from = date;
    let to = date;
    if (date.length) {
      // eslint-disable-next-line prefer-destructuring
      from = date[0];
      // eslint-disable-next-line prefer-destructuring
      to = date[1];
    }

    const workBook = XLSX.utils.book_new();
    workBook.Props = {
      Title: "Aufträge",
      Subject: "Aufträge",
      Author: "",
      CreatedDate: new Date(),
    };
    const sheetName = "Auftragssheet";
    workBook.SheetNames.push(sheetName);

    const rows = orders.map((order) => {
      const costCenter = order.cost_center;
      const start = dateToString(new Date(order.operational_period_start));
      const kutterContactName = order.kutterContact
        ? `${order.kutterContact.firstname} ${order.kutterContact.lastname}`
        : "";
      const invoiceNumber = order.invoiceNumber || "";
      const notes = order.note || "";

      let buildingAddress = order.building_address.trim();
      if (order.lat !== "") {
        buildingAddress = `In der Nähe von: ${buildingAddress}`;
      }

      let KS = order.description;
      let projectNumber = "";
      let customer = "";
      let buildingProject = "";

      if (costCenter) {
        KS = costCenter.value;
        if (costCenter.oldValue) {
          KS += ` (${costCenter.oldValue})`;
        }

        projectNumber = costCenter.project_number;
        // eslint-disable-next-line prefer-destructuring
        customer = costCenter.customer;
        buildingProject = costCenter.building_project;
      }

      let humanResources = "";
      if (order.humanResources) {
        humanResources = order.humanResources
          .map((hr) => {
            const { humanResource } = hr;
            return `${humanResource.firstname} ${humanResource.lastname}`;
          })
          .join(", ");
      }

      const machines = [
        ...order.resources,
        ...order.vehicles.map((v) => ({
          resource: {
            ...v.resource,
            name: `${v.resource.number ? `${v.resource.number} ` : ""}${
              v.resource.licensePlate ? `${v.resource.licensePlate} ` : ""
            }${v.resource.category ? `${v.resource.category} ` : ""}`,
          },
        })),
      ]
        .map((e) => {
          const rn = e.resource.number ? ` (${e.resource.number})` : "";
          return e.resource.name + rn;
        })
        .join(", ");

      return [
        KS,
        projectNumber,
        customer,
        buildingProject,
        buildingAddress,
        machines,
        humanResources,
        start,
        kutterContactName,
        invoiceNumber,
        notes,
      ];
    });
    const workSheetData = [
      [
        "KS",
        "Projektnummer",
        "Auftraggeber",
        "Bauvorhaben",
        "Adresse",
        "Maschinen",
        "Mitarbeiter",
        "geplantes Startdatum",
        "Bauleiter Kutter",
        "Rechnung",
        "Notizen",
      ],
      ...rows,
    ];

    workBook.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(workSheetData);

    const workBookOut = XLSX.write(workBook, {
      bookType: "xlsx",
      type: "binary",
    });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
      const view = new Uint8Array(buf); // create uint8array as viewer
      for (let i = 0; i < s.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        view[i] = s.charCodeAt(i) & 0xff; // convert to octet
      }
      return buf;
    };

    const start = moment(from).format("YYYY-MM-DD");
    const end = moment(to).format("YYYY-MM-DD");
    const file = new File(
      [s2ab(workBookOut)],
      `abrechnung_${start}_${end}.xlsx`,
      {
        type: "application/octet-stream",
      }
    );
    downloadFile(file);
  }

  render() {
    const {
      permissions,
      loginName,
      chosenOrder,
      orders: requestOrders,
      transfers: requestTransfers,
      ordersLoading,
    } = this.props;
    const {
      showCalendar,
      view,
      date,
      dateType,
      dateString,
      openView,
    } = this.state;

    const hasWritePermission =
      Boolean(permissions.find((p) => p.name === "accounting" && p.write)) ||
      loginName === "klout";

    const chosenOrderId = chosenOrder ? chosenOrder.id : "-1";

    const start = chosenOrder
      ? moment(chosenOrder.operational_period_start).startOf("day")
      : null;
    const end = chosenOrder
      ? moment(chosenOrder.operational_period_finish).endOf("day")
      : null;

    if (start) {
      start.subtract(1, "days");
    }

    if (end) {
      end.add(1, "days");
    }

    const now = moment.utc();
    let orders = [];
    let transfers = [];

    if (openView === "Ongoing") {
      orders = requestOrders.filter((data) => !data.accountingState);
    } else {
      orders = requestOrders.filter((o) =>
        moment.utc(o.operational_period_finish).isBefore(now)
      );
    }

    if (openView === "Ongoing") {
      transfers = requestTransfers.filter((data) => !data.accountingState);
    } else {
      transfers = requestTransfers.filter((transfer) =>
        moment.utc(transfer.destinationDate).isBefore(now)
      );
    }

    const listData = [...orders, ...transfers];

    return (
      <Fragment>
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-12 padding-20">
              <TitleBar
                title=""
                titleComponent={
                  <Fragment>
                    <span className="text-black h4 mr-3">Abrechnung:</span>
                    <span
                      className={`${
                        openView === "Open" ? "text-black" : "text-black-50"
                      } h4 mr-3 cursor-pointer`}
                      onClick={() => {
                        this.setOpenView("Open");
                      }}
                    >
                      offen
                    </span>
                    <span style={{ fontSize: 24 }}>|</span>
                    <span
                      className={`${
                        openView === "Ongoing" ? "text-black" : "text-black-50"
                      } h4 mr-3 ml-3 cursor-pointer`}
                      onClick={() => {
                        this.setOpenView("Ongoing");
                      }}
                    >
                      laufende Abrufe
                    </span>
                    <span style={{ fontSize: 24 }}>|</span>
                    <span
                      className={`${
                        openView === "Closed" ? "text-black" : "text-black-50"
                      } h4 ml-3 cursor-pointer`}
                      onClick={() => {
                        this.setOpenView("Closed");
                      }}
                    >
                      geschlossen
                    </span>
                  </Fragment>
                }
              >
                {openView === "Closed" || openView === "Open" ? (
                  <Fragment>
                    <div className="col d-flex justify-content-center">
                      <div>
                        <button
                          type="button"
                          onClick={this.subDate}
                          className="btn btn-outline-primary btn-sm px-3"
                        >
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <button
                          className="btn btn-outline-primary btn-sm px-3 mx-3"
                          style={{ width: 140 }}
                          onClick={this.toggleCalendar}
                        >
                          {dateString}
                        </button>
                        <button
                          type="button"
                          onClick={this.addDate}
                          className="btn btn-outline-primary btn-sm px-3"
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                      </div>
                    </div>
                    {openView === "Closed" && (
                      <div className="col-auto" style={{ width: 300 }}>
                        <button
                          className="btn btn-outline-primary btn-sm float-right"
                          onClick={() => this.exportCSV(orders)}
                        >
                          Export CSV
                        </button>
                      </div>
                    )}
                  </Fragment>
                ) : null}

                {showCalendar ? (
                  <Calendar
                    className="order-calendar shadow"
                    locale="de-DE"
                    view={dateType === "MONTH" ? "year" : "month"}
                    minDetail="year"
                    prev2Label={null}
                    next2Label={null}
                    showNavigation={true}
                    showWeekNumbers={true}
                    value={date}
                    onChange={this.handleDaySelect}
                    onClickWeekNumber={this.handleWeekSelect}
                    onClickMonth={this.handleMonthSelect}
                  />
                ) : null}
              </TitleBar>

              <List
                chosenOrderId={chosenOrderId}
                listData={listData}
                loading={ordersLoading}
                write={hasWritePermission}
                expanded={false}
                view={view}
                onViewChange={this.handleViewChange}
                loadData={this.loadData}
                openView={openView}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  orders: PropTypes.array,
  transfers: PropTypes.array,
  chosenOrder: PropTypes.object,
  newOrder: PropTypes.bool,
  orderLoading: PropTypes.bool,
  ordersLoading: PropTypes.bool,
  costCenters: PropTypes.array,
  client: PropTypes.object,
  location: PropTypes.object,
  permissions: PropTypes.array,
  loginName: PropTypes.string,
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  orders: state.orders.get("orders"),
  transfers: state.transfers.get("transfers"),
  chosenOrder: state.orders.get("chosenOrder"),
  newOrder: state.orders.get("newOrder"),
  orderLoading: state.orders.get("orderLoading"),
  ordersLoading: state.orders.get("ordersLoading"),
  costCenters: state.costCenters.get("costCenters"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName"),
}))(App);
