import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Data from "./data";
import List from "./list";

import getVehicles from "../../actions/getVehicles";
import getMachinists from "../../actions/getMachinists";
import getVehicleBrands from "../../actions/getVehicleBrands";
import getVehicleTypes from "../../actions/getVehicleTypes";

class App extends Component {
  constructor(props) {
    super(props);

    document.title = "Fahrzeuge";

    const id = props.location.pathname.split("/")[2];
    props.dispatch(getVehicles(props.client, id));
    props.dispatch(getMachinists(props.client));
    props.dispatch(getVehicleBrands(props.client));
    props.dispatch(getVehicleTypes(props.client));
  }

  componentDidMount() {
    const { mp } = this.props;
    if (mp && mp.isInit && mp._mp) {
      const tracking_obj_param = {
        Screen: document.title,
        "Tracking Time": new Date().toDateString()
      };
      mp._mp.track("Screen View", tracking_obj_param, () =>
        console.log("tracked ", tracking_obj_param)
      );
    }
  }

  render() {
    const {
      permissions,
      loginName,
      establishment,
      vehicles,
      chosenVehicle,
      mp
    } = this.props;

    const hasWritePermission =
      Boolean(permissions.find(p => p.name === "vehicles" && p.write)) ||
      loginName === "klout";

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <List
              className="col-md-6 padding-20"
              establishment={establishment}
              vehicles={vehicles}
              chosenVehicle={chosenVehicle}
              write={hasWritePermission}
            />
            <Data
              className="col-md-6 data-panel padding-20 border-left"
              establishment={establishment}
              vehicle={chosenVehicle}
              vehicles={vehicles}
              write={hasWritePermission}
              mp={mp}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  establishment: PropTypes.object,
  vehicles: PropTypes.array,
  chosenVehicle: PropTypes.object,
  location: PropTypes.object,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment"),
  vehicles: state.vehicles.get("vehicles"),
  chosenVehicle: state.vehicles.get("chosenVehicle"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName")
}))(App);
