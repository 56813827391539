import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import './list.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faCircle,
  faEuroSign,
  faLink,
  faLocationArrow,
  faTimes,
  faTruckContainer
} from "@fortawesome/pro-solid-svg-icons";

import * as moment from "moment";
import { debounce } from "lodash";
import { holidays2 } from "../../util/dateHelper";

import SimpleTable from "../SimpleTable";
import LoadingIndicator from "../LoadingIndicator";

import DropZone from "../HumanResources/DropZone";
import parseJSON from "../../util/parseJSON";
import getOrder from "../../actions/getOrder";

import setOrderAllowances from "../../actions/setOrderAllowances";
import deleteOrderAllowance from "../../actions/deleteOrderAllowance";
import deleteOrderInvoice from "../../actions/deleteOrderInvoice";
import setOrderInvoice from "../../actions/setOrderInvoice";
import setOrderIsEmployeeHoursValid from "../../actions/setOrderIsEmployeeHoursValid";
import setOrderAllowanceValid from "../../actions/setOrderAllowanceValid";
import setOrderInvoiceNumber from "../../actions/setOrderInvoiceNumber";
import setOrderNote from "../../actions/setOrderNote";
import setOrderAccountingState from "../../actions/setOrderAccountingState";

import setTransferAllowances from "../../actions/setTransferAllowances";
import deleteTransferAllowance from "../../actions/deleteTransferAllowance";
import setTransferInvoice from "../../actions/setTransferInvoice";
import deleteTransferInvoice from "../../actions/deleteTransferInvoice";
import setTransferIsEmployeeHoursValid from "../../actions/setTransferIsEmployeeHoursValid";
import setTransferAllowanceValid from "../../actions/setTransferAllowanceValid";
import setTransferInvoiceNumber from "../../actions/setTransferInvoiceNumber";
import setTransferNote from "../../actions/setTransferNote";
import setTransferAccountingState from "../../actions/setTransferAccountingState";
import UploadedFileElement from "./UploadedFileElement";

export const generateProjectNumberInterval = projectNumberString => {
  if (projectNumberString && projectNumberString !== "") {
    const projectNumber = parseInt(projectNumberString, 10);
    const times = Math.floor(projectNumber / 50);
    let start = times * 50 + 1;
    const end = times * 50 + 50;
    if (start.toString().length === 1) {
      start = `0${start}`;
    }
    return `${start}-${end}`;
  }
  return "";
};

const options = {
  // weekday: "short",
  year: "2-digit",
  month: "2-digit",
  day: "2-digit"
};
const dateToString = date => date.toLocaleDateString("de-DE", options);

const EXTERNAL_NOTES_CSS = 'external-note';
const MAIN_NOTE_CSS = 'main-note';
const HIDE_NOTE_CSS = 'd-none';
const NOTE_ACTIVE_CSS = 'note-active';

const MAIN_NOTES_HDR = 'main-notes';
const EXT_NOTES_HDR = 'ext-notes';

const widths = [2, 3, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3];
const sum = widths.reduce((acc, val) => acc + val, 0);
const styles = widths.map(withFactor => {
  const percent = (100 / sum) * withFactor;
  return {
    width: `${percent}%`,
    maxWidth: `${percent}%`
  };
});
const getStyle = index => styles[index];

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      continuanceFilter: false,
      sortKey: null,
      reverseSort: true,
      showModal: false,
      message: "",
      accountingStates: {},
      isEmployeeHoursValid: {},
      allowanceValid: {},
      invoiceNumber: {},
      notes: {},
      transferAccountingStates: {},
      transferIsEmployeeHoursValid: {},
      transferAllowanceValid: {},
      transferInvoiceNumber: {},
      transferNotes: {}
    };

    this.tableHeadRenderer = className => (
      <Fragment>
        <div className={className} style={getStyle(0)}>
          ID
        </div>
        <div className={className} style={getStyle(1)}>
          KS
        </div>
        <div className={className} style={getStyle(2)}>
          Projektnummer
        </div>
        <div className={className} style={getStyle(3)}>
          Datum
        </div>
        <div className={className} style={getStyle(4)}>
          Auftraggeber
        </div>
        <div className={className} style={getStyle(5)}>
          Bauvorhaben
        </div>
        <div className={className} style={getStyle(6)}>
          Adresse
        </div>
        <div className={className} style={getStyle(7)}>
          Maschinen
        </div>
        <div className={className} style={getStyle(8)}>
          Mitarbeiter
        </div>
        <div className={className} style={getStyle(9)}>
          Bauleiter Kutter
        </div>
        <div className={className} style={getStyle(10)}>
          Aufmaß
        </div>
        <div className={className} style={getStyle(11)}>
          Rechnung
        </div>
        <div className={className} style={getStyle(12)}>
          <span className='note-switch main-notes-header note-active' onClick={() => toggleToNotesView(MAIN_NOTES_HDR)}>Notizen</span>
          <span className='ext-notes-header note-switch' style={{float: 'right'}} onClick={() => toggleToNotesView(EXT_NOTES_HDR)}>Notizen Abruf</span>
        </div>
        {/*<div className={className} style={getStyle(13)}>
          Notizen Abrufe
        </div>*/}
      </Fragment>
    );

    const toggleToNotesView = (note_type) => {
      const notes = document.getElementsByClassName(MAIN_NOTE_CSS);
      const extNotes =document.getElementsByClassName(EXTERNAL_NOTES_CSS);
      switch(note_type) {
        case MAIN_NOTES_HDR:
          Array.from(notes).forEach(note => {
            note.classList.remove(HIDE_NOTE_CSS);
          });
          Array.from(extNotes).forEach(extN => {
            extN.classList.add(HIDE_NOTE_CSS);
          });
          document.getElementsByClassName('main-notes-header')[0].classList.add(NOTE_ACTIVE_CSS);
          document.getElementsByClassName('ext-notes-header')[0].classList.remove(NOTE_ACTIVE_CSS);
          break;
        case EXT_NOTES_HDR:
          Array.from(notes).forEach(note => {
            note.classList.add(HIDE_NOTE_CSS);
          });
          Array.from(extNotes).forEach(extN => {
            extN.classList.remove(HIDE_NOTE_CSS);
          });
          document.getElementsByClassName('ext-notes-header')[0].classList.add(NOTE_ACTIVE_CSS);
          document.getElementsByClassName('main-notes-header')[0].classList.remove(NOTE_ACTIVE_CSS);
          break;
        default:
          return;
      }
    }

    const setOrderAccountingStates = (orderId, value) => {
      this.setState(state => ({
        accountingStates: {
          ...state.accountingStates,
          [orderId]: value
        }
      }));
      setOrderAccountingState(this.props.client, orderId, value).then(
        this.props.loadData
      );
    };

    const setIsEmployeeHoursValid = (orderId, humanResourceId, value) => {
      this.setState(state => ({
        isEmployeeHoursValid: {
          ...state.isEmployeeHoursValid,
          [orderId]: {
            ...state.isEmployeeHoursValid[orderId],
            [humanResourceId]: value
          }
        }
      }));
      setOrderIsEmployeeHoursValid(
        this.props.client,
        orderId,
        humanResourceId,
        value
      ).then(this.props.loadData);
    };

    const setAllowanceValid = (orderId, value) => {
      this.setState(state => ({
        allowanceValid: {
          ...state.allowanceValid,
          [orderId]: value
        }
      }));
      setOrderAllowanceValid(this.props.client, orderId, value).then(
        this.props.loadData
      );
    };

    const setOrderInvoiceNumberFunc = debounce((orderId, value) => {
      this.setState(state => ({
        invoiceNumber: {
          ...state.invoiceNumber,
          [orderId]: value
        }
      }));
      setOrderInvoiceNumber(this.props.client, orderId, value).then(
        this.props.loadData
      );
    }, 300);

    const setOrderNotesFunc = debounce((orderId, value) => {
      this.setState(state => ({
        notes: {
          ...state.notes,
          [orderId]: value
        }
      }));
      setOrderNote(this.props.client, orderId, value).then(this.props.loadData);
    }, 300);

    const setTransferAccountingStates = (orderId, value) => {
      this.setState(state => ({
        transferAccountingStates: {
          ...state.transferAccountingStates,
          [orderId]: value
        }
      }));
      setTransferAccountingState(this.props.client, orderId, value).then(
        this.props.loadData
      );
    };

    const setTIsEmployeeHoursValid = (transferId, humanResourceId, value) => {
      let data = this.state.transferIsEmployeeHoursValid[transferId];
      if (!data) {
        data = [];
      }
      if (value) {
        data = [...new Set([...data, humanResourceId])];
      } else {
        data = data.filter(hid => hid !== humanResourceId);
      }

      this.setState(state => ({
        transferIsEmployeeHoursValid: {
          ...state.transferIsEmployeeHoursValid,
          [transferId]: data
        }
      }));
      setTransferIsEmployeeHoursValid(
        this.props.client,
        transferId,
        JSON.stringify(data)
      ).then(this.props.loadData);
    };

    const setTAllowanceValid = (orderId, value) => {
      this.setState(state => ({
        transferAllowanceValid: {
          ...state.transferAllowanceValid,
          [orderId]: value
        }
      }));
      setTransferAllowanceValid(this.props.client, orderId, value).then(
        this.props.loadData
      );
    };

    const setTransferInvoiceNumberFunc = debounce((orderId, value) => {
      this.setState(state => ({
        transferInvoiceNumber: {
          ...state.transferInvoiceNumber,
          [orderId]: value
        }
      }));
      setTransferInvoiceNumber(this.props.client, orderId, value).then(
        this.props.loadData
      );
    }, 300);

    const setTransferNotesFunc = debounce((orderId, value) => {
      this.setState(state => ({
        transferNotes: {
          ...state.transferNotes,
          [orderId]: value
        }
      }));
      setTransferNote(this.props.client, orderId, value).then(
        this.props.loadData
      );
    }, 300);

    this.tableColRenderer = (elem, className) => {
      // eslint-disable-next-line no-underscore-dangle
      switch (elem.__typename) {
        case "Order": {
          let customer = "";
          let buildingProject = "";
          let buildingAddress = elem.building_address;
          const orderStart = moment.utc(
            elem.operational_period_start.slice(0, 10)
          );
          const orderFinish = moment.utc(
            elem.operational_period_finish.slice(0, 10)
          );

          const orderWorkDays = [orderStart.toISOString()];
          const temp = moment.utc(orderStart);
          while (orderFinish.isAfter(temp)) {
            orderWorkDays.push(temp.add(1, "days").toISOString());
          }
          const years = [...new Set([orderStart.year(), orderFinish.year()])];
          const possibleHolidays = [
            ...new Set(
              years.map(year => Object.keys(holidays2[year] || {})).flat()
            )
          ];
          const filteredOrderWorkDays = orderWorkDays
            .map(o => moment.utc(o))
            .filter(
              day =>
                possibleHolidays.indexOf(day.toISOString().slice(0, 10)) ===
                  -1 && day.weekday() <= 4
            );

          const kutterContactName = elem.kutterContact
            ? `${elem.kutterContact.firstname} ${elem.kutterContact.lastname}`
            : "";

          let useNewApiString = elem.allowances.split("/")[1] || "";
          let allowances = parseJSON(elem.allowances.split("/")[0]) || [];


          if (allowances.error) {
            allowances = undefined;
          }

          if(allowances != null || allowances !== undefined) {
            allowances.reverse();
          }

          const invoice = elem.invoice.split("/")[0];
          const useNewApiInvoice = elem.invoice.split("/")[1] === "x";

          let hasGPS = false;

          const costCenter = elem.cost_center;
          if (costCenter) {
            // eslint-disable-next-line prefer-destructuring
            customer = costCenter.customer;
            buildingProject = costCenter.building_project;
          }

          if (elem.lat !== "") {
            buildingAddress = `In der Nähe von: ${buildingAddress}`;
            hasGPS = true;
          }

          // eslint-disable-next-line no-param-reassign
          className += " clip-text";

          let statusClassName = "order-status-blob-stroke";
          let statusTitle = "";
          let icon = null;

          switch (elem.status) {
            case 2:
              statusClassName += " bg-box-green";
              statusTitle = "Kaufmännisch geprüft";
              icon = faEuroSign;
              break;
            case 1:
              statusClassName += " bg-box-green";
              statusTitle = "Veröffentlicht";
              icon = faCheck;
              break;
            default:
              // status is 0
              if (elem.cost_center) statusClassName += " bg-box-grey";
              else {
                statusClassName += " bg-box-lightgrey";
                statusTitle = "keine Kostenstelle";
              }
              break;
          }

          const machines = [
            ...elem.resources,
            ...elem.vehicles.map(v => ({
              resource: {
                ...v.resource,
                name: `${v.resource.number ? `${v.resource.number} ` : ""}${
                  v.resource.licensePlate ? `${v.resource.licensePlate} ` : ""
                } ${v.resource.category ? `${v.resource.category} ` : ""}`
              }
            }))
          ]
            .map(e => e.resource)
            .filter(e => e);

          const isAllowanceValid =
            this.state.allowanceValid[elem.id] || elem.allowanceValid;

          const orderStateIsClosed =
            this.state.accountingStates[elem.id] || elem.accountingState;
          const orderStatusClassName = `${
            orderStateIsClosed ? "bg-box-green " : "bg-box-red "
          } order-status-blob-stroke`;
          const orderStatusTitle = orderStateIsClosed ? "ready" : "open";
          const orderStatusIcon = orderStateIsClosed ? faCheck : null;

          return (
            <Fragment>
              <div className={`${className} text-right`} style={getStyle(0)}>
                <div className={statusClassName} title={statusTitle}>
                  <div className="order-status-blob-fill">
                    {icon ? (
                      <FontAwesomeIcon
                        className="order-status-blob-icon"
                        icon={icon}
                      />
                    ) : null}
                  </div>
                </div>

                <div
                  className={orderStatusClassName}
                  title={orderStatusTitle}
                  style={{ left: 37 }}>
                  <div
                    className="order-status-blob-fill cursor-pointer"
                    onClick={e => {
                      if (this.props.openView !== "Ongoing") {
                        e.stopPropagation();
                        setOrderAccountingStates(elem.id, !orderStateIsClosed);
                      }
                    }}>
                    {orderStatusIcon ? (
                      <FontAwesomeIcon
                        className="order-status-blob-icon"
                        icon={orderStatusIcon}
                      />
                    ) : null}
                  </div>
                </div>

                <a href={`/order/${elem.id}`}>{elem.id}</a>
              </div>
              <div className={className} style={getStyle(1)}>
                {costCenter ? (
                  <a href={`/cost_center/${costCenter.id}`}>
                    {costCenter.value}
                    {costCenter.oldValue ? ` (${costCenter.oldValue})` : ""}
                  </a>
                ) : (
                  <a href={`/cost_center?order=${elem.id}`}>
                    {elem.description}
                  </a>
                )}
              </div>
              <div className={className} style={getStyle(2)}>
                {costCenter ? (
                  <Fragment>
                    {costCenter.project_number}
                    {costCenter.project_number &&
                    costCenter.project_number !== "" ? (
                      <a
                        className="float-right"
                        href={`fileopener:K:\\Kutter Leipzig\\Angebote - Projekte\\${generateProjectNumberInterval(
                          costCenter.project_number
                        )}\\${costCenter.project_number}\\1. Angebotsphase`}>
                        <FontAwesomeIcon icon={faLink} />
                      </a>
                    ) : null}
                  </Fragment>
                ) : null}
              </div>
              <div className={className} style={getStyle(3)}>
                {`${dateToString(
                  new Date(elem.operational_period_start)
                )} - ${dateToString(new Date(elem.operational_period_finish))}`}
              </div>
              <div className={className} style={getStyle(4)}>
                {customer}
              </div>
              <div className={className} style={getStyle(5)}>
                {buildingProject}
              </div>
              <div className={className} style={getStyle(6)}>
                {buildingAddress}
                {hasGPS ? (
                  <FontAwesomeIcon
                    className="order-gps-icon"
                    icon={faLocationArrow}
                  />
                ) : null}
              </div>
              <div className={className} style={getStyle(7)}>
                {machines.map(machine => (
                  <Fragment key={machine.id}>
                    <span>{machine.name}</span>
                    <span className="float-right">{machine.number}</span>
                    <br />
                  </Fragment>
                ))}
              </div>
              <div className={className} style={getStyle(8)}>
                {elem.humanResources
                  ? elem.humanResources.map(hr => {
                      const { humanResource } = hr;
                      const { employeeHours } = humanResource;
                      const employeeHoursForOrder = employeeHours.filter(eh => {
                        const date = moment.utc(eh.date.slice(0, 10));
                        return (
                          date.isSameOrAfter(orderStart) &&
                          date.isSameOrBefore(orderFinish)
                        );
                      });
                      const possibleEmployeeHours = employeeHoursForOrder.filter(
                        eh => eh.value || eh.file
                      );
                      const dates = [
                        ...new Set(possibleEmployeeHours.map(eh => eh.date))
                      ];

                      const validEmployeeHours = dates.map(date =>
                        possibleEmployeeHours.find(eh => eh.date === date)
                      );

                      const badgeType =
                        (this.state.isEmployeeHoursValid[elem.id] &&
                          this.state.isEmployeeHoursValid[elem.id][hr.id]) ||
                        hr.isEmployeeHoursValid
                          ? "badge-success"
                          : "badge-danger";

                      return (
                        <Fragment key={hr.id}>
                          <span>
                            {humanResource.firstname} {humanResource.lastname}
                          </span>
                          <span
                            className={`float-right badge badge-pill cursor-pointer ${badgeType}`}
                            onClick={e => {
                              e.stopPropagation();
                              setIsEmployeeHoursValid(
                                elem.id,
                                hr.id,
                                !hr.isEmployeeHoursValid
                              );
                            }}>
                            {validEmployeeHours.length}/
                            {filteredOrderWorkDays.length}
                          </span>
                          <br />
                        </Fragment>
                      );
                    })
                  : null}
              </div>
              <div className={className} style={getStyle(9)}>
                {kutterContactName}
              </div>
              <div className={className} style={getStyle(10)}>
                <span className="pr-2 d-inline-block" style={{ width: "10%" }}>
                  <FontAwesomeIcon
                    className={`cursor-pointer ${
                      isAllowanceValid ? "text-success" : ""
                    }`}
                    icon={isAllowanceValid ? faCheckCircle : faCircle}
                    onClick={e => {
                      e.stopPropagation();
                      setAllowanceValid(elem.id, !elem.allowanceValid);
                    }}
                  />
                </span>
                {allowances
                  ? allowances.map((allowance, index) => (
                      <UploadedFileElement
                          useNewApi={useNewApiString.includes(index + "x")}
                          key={allowance}
                        handle={allowance}
                        handleDelete={() =>
                          deleteOrderAllowance(
                            this.props.client,
                            elem.id,
                            allowance
                          ).then(this.props.loadData)
                        }
                      />
                    ))
                  : null}
                <DropZone
                  className="d-inline-block"
                  style={{ width: allowances ? "100%" : "85%" }}
                  multi={true}
                  isFileStack={true}
                  accept={"application/pdf,image/*"}
                  onDrop={handles => {
                    const data = [...handles];
                    if (allowances) {
                      data.push(...allowances);
                    }
                    setOrderAllowances(this.props.client, elem.id, data, useNewApiString).then(
                      this.props.loadData
                    );
                  }}>
                  {percentage => (
                    <div className="alert-danger rounded small p-2 text-center cursor-pointer">
                      {percentage ? (
                        `${percentage}%`
                      ) : (
                        <FontAwesomeIcon icon={faTimes} />
                      )}
                    </div>
                  )}
                </DropZone>
              </div>

              <div className={className} style={getStyle(11)}>
                <div className="d-inline-block w-75 pr-2">
                  <input
                    className="form-control"
                    defaultValue={
                      this.state.invoiceNumber[elem.id] ||
                      elem.invoiceNumber ||
                      ""
                    }
                    onChange={e =>
                      setOrderInvoiceNumberFunc(elem.id, e.target.value)
                    }
                  />
                </div>
                {invoice ? (
                  <UploadedFileElement
                      useNewApi={useNewApiInvoice}
                    handle={invoice}
                    handleDelete={() =>
                      deleteOrderInvoice(this.props.client, elem.id).then(
                        this.props.loadData
                      )
                    }
                  />
                ) : (
                  <DropZone
                    className="d-inline-block w-25"
                    accept={"application/pdf"}
                    isFileStack={true}
                    onDrop={handle => {
                      setOrderInvoice(this.props.client, elem.id, handle).then(
                        () => this.props.loadData()
                      );
                    }}>
                    {percentage => (
                      <div className="alert-danger rounded small p-2 text-center cursor-pointer">
                        {percentage ? (
                          `${percentage}%`
                        ) : (
                          <FontAwesomeIcon icon={faTimes} />
                        )}
                      </div>
                    )}
                  </DropZone>
                )}
              </div>
              <div className={`${className} ${MAIN_NOTE_CSS}`} style={getStyle(12)}>
                <textarea
                  className="h-100 w-100 rounded"
                  style={{
                    border: "1px solid #ced4da"
                  }}
                  defaultValue={this.state.notes[elem.id] || elem.note || ""}
                  onChange={e => setOrderNotesFunc(elem.id, e.target.value)}
                />
              </div>
              <div className={`${className} ${EXTERNAL_NOTES_CSS} ${HIDE_NOTE_CSS}`} style={getStyle(12)}>
                {elem.notes}
              </div>
            </Fragment>
          );
        }
        case "Transfer": {
          let customer = "";
          let buildingProject = "";
          const orderStart = moment.utc(elem.sourceDate.slice(0, 10));
          const orderFinish = moment.utc(elem.destinationDate.slice(0, 10));

          const orderWorkDays = [orderStart.toISOString()];
          const temp = moment.utc(orderStart);
          while (orderFinish.isAfter(temp)) {
            orderWorkDays.push(temp.add(1, "days").toISOString());
          }
          const years = [...new Set([orderStart.year(), orderFinish.year()])];
          const possibleHolidays = [
            ...new Set(
              years.map(year => Object.keys(holidays2[year] || {})).flat()
            )
          ];
          const filteredOrderWorkDays = orderWorkDays
            .map(o => moment.utc(o))
            .filter(
              day =>
                possibleHolidays.indexOf(day.toISOString().slice(0, 10)) ===
                  -1 && day.weekday() <= 4
            );

          let destinationContactKutterName = "";
          if (elem.destinationContactKutter) {
            if (elem.destinationContactKutter.id === "__new__") {
              const hr = this.props.humanResources.find(
                h => h.id === elem.destinationContactKutter.firstname
              );
              if (hr) {
                destinationContactKutterName = `${hr.firstname} ${hr.lastname}`;
              }
            } else {
              destinationContactKutterName = `${elem.destinationContactKutter.firstname} ${elem.destinationContactKutter.lastname}`;
            }
          }

          let sourceContactKutterName = "";
          if (elem.sourceContactKutter) {
            if (elem.sourceContactKutter.id === "__new__") {
              const hr = this.props.humanResources.find(
                h => h.id === elem.sourceContactKutter.firstname
              );
              if (hr) {
                sourceContactKutterName = `${hr.firstname} ${hr.lastname}`;
              }
            } else {
              sourceContactKutterName = `${elem.sourceContactKutter.firstname} ${elem.sourceContactKutter.lastname}`;
            }
          }

          let allowances = parseJSON(elem.allowances);
          if (allowances.error) {
            allowances = undefined;
          }

          const { invoice } = elem;

          let order = elem.destinationOrder;
          let isSourceOrder = false;
          let {
            type,
            cost_center: costCenter,
            building_address: buildingAddress
          } = order;
          const setForSourceOrder = () => {
            order = elem.sourceOrder;
            isSourceOrder = true;
            // eslint-disable-next-line prefer-destructuring
            type = order.type;
            const sourceOrderCostCenter = order.cost_center;
            if (sourceOrderCostCenter) {
              // eslint-disable-next-line prefer-destructuring
              customer = sourceOrderCostCenter.customer;
              buildingProject = sourceOrderCostCenter.building_project;
              costCenter = sourceOrderCostCenter;
              buildingAddress = sourceOrderCostCenter.building_address;
            } else if (order.label) {
              customer = order.label;
            } else if (order.customer) {
              costCenter = order;
              customer = order.customer;
              buildingProject = order.building_project;
              buildingAddress = order.building_address;
            }
          };

          if (costCenter) {
            customer = costCenter.customer;
            buildingProject = costCenter.building_project;
          } else if (order.label) {
            if (order.label === "Bauhof Leipzig") {
              setForSourceOrder();
            } else {
              customer = order.label;
            }
          } else if (order.customer) {
            costCenter = order;
            customer = order.customer;
            buildingProject = order.building_project;
            buildingAddress = order.building_address;
          } else {
            setForSourceOrder();
          }

          let hasGPS = false;
          if (order.lat && order.lat !== "") {
            buildingAddress = buildingAddress
              ? `In der Nähe von: ${buildingAddress}`
              : "";
            hasGPS = true;
          }

          // eslint-disable-next-line no-param-reassign
          className += " clip-text";

          let statusClassName = "order-status-blob-stroke";
          let statusTitle = "";
          let icon = null;

          switch (elem.status) {
            case 2:
              statusClassName += " bg-box-green";
              statusTitle = "Kaufmännisch geprüft";
              icon = faEuroSign;
              break;
            case 1:
              statusClassName += " bg-box-green";
              statusTitle = "Veröffentlicht";
              icon = faCheck;
              break;
            default:
              // status is 0
              if (elem.cost_center) statusClassName += " bg-box-grey";
              else {
                statusClassName += " bg-box-lightgrey";
                statusTitle = "keine Kostenstelle";
              }
              break;
          }

          const machines = [
            ...elem.resources,
            elem.combination,
            elem.vehicle
              ? {
                  ...elem.vehicle,
                  name: `${
                    elem.vehicle.number ? `${elem.vehicle.number} ` : ""
                  }${
                    elem.vehicle.licensePlate
                      ? `${elem.vehicle.licensePlate} `
                      : ""
                  } ${elem.vehicle.category ? `${elem.vehicle.category} ` : ""}`
                }
              : null
          ].filter(e => e);

          const isAllowanceValid =
            this.state.transferAllowanceValid[elem.id] || elem.allowanceValid;

          const orderStateIsClosed =
            this.state.transferAccountingStates[elem.id] ||
            elem.accountingState;
          const orderStatusClassName = `${
            orderStateIsClosed ? "bg-box-green " : "bg-box-red "
          } order-status-blob-stroke`;
          const orderStatusTitle = orderStateIsClosed ? "ready" : "open";
          const orderStatusIcon = orderStateIsClosed ? faCheck : null;

          const { employeeHours } = elem.humanResource;
          const employeeHoursForOrder = employeeHours.filter(eh => {
            const date = moment.utc(eh.date.slice(0, 10));
            return (
              date.isSameOrAfter(orderStart) && date.isSameOrBefore(orderFinish)
            );
          });
          const possibleEmployeeHours = employeeHoursForOrder.filter(
            eh => eh.value || eh.file
          );
          const dates = [...new Set(possibleEmployeeHours.map(eh => eh.date))];

          const validEmployeeHours = dates.map(date =>
            possibleEmployeeHours.find(eh => eh.date === date)
          );

          const badgeType =
            (this.state.transferIsEmployeeHoursValid[elem.id] &&
              this.state.transferIsEmployeeHoursValid[elem.id].includes(
                elem.humanResource.id
              )) ||
            elem.isEmployeeHoursValid.includes(elem.humanResource.id)
              ? "badge-success"
              : "badge-danger";

          return (
            <Fragment>
              <div className={`${className} text-right`} style={getStyle(0)}>
                <div
                  className="order-status-blob-stroke"
                  title={statusTitle}
                  style={{ top: 5, left: 6, background: "none", width: 30 }}>
                  <FontAwesomeIcon
                    className="text-dark"
                    icon={faTruckContainer}
                  />
                  <span className="pl-1">{isSourceOrder ? "S" : "D"}</span>
                </div>
                <div className={statusClassName} title={statusTitle}>
                  <div className="order-status-blob-fill">
                    {icon ? (
                      <FontAwesomeIcon
                        className="order-status-blob-icon"
                        icon={icon}
                      />
                    ) : null}
                  </div>
                </div>
                <div
                  className={orderStatusClassName}
                  title={orderStatusTitle}
                  style={{ left: 37 }}>
                  <div
                    className="order-status-blob-fill cursor-pointer"
                    onClick={e => {
                      if (this.props.openView !== "Ongoing") {
                        e.stopPropagation();
                        setTransferAccountingStates(
                          elem.id,
                          !orderStateIsClosed
                        );
                      }
                    }}>
                    {orderStatusIcon ? (
                      <FontAwesomeIcon
                        className="order-status-blob-icon"
                        icon={orderStatusIcon}
                      />
                    ) : null}
                  </div>
                </div>
                <a href={`/order/${elem.id}`}>{elem.id}</a>
              </div>
              <div className={className} style={getStyle(1)}>
                {costCenter ? (
                  <a href={`/cost_center/${costCenter.id}`}>
                    {costCenter.value}
                    {costCenter.oldValue ? ` (${costCenter.oldValue})` : ""}
                  </a>
                ) : (
                  <a href={`/cost_center?order=${elem.id}`}>
                    {elem.description}
                  </a>
                )}
              </div>
              <div className={className} style={getStyle(2)}>
                {// eslint-disable-next-line no-nested-ternary
                costCenter ? (
                  // eslint-disable-next-line no-nested-ternary
                  type === 0 ? (
                    <span>
                      {order.project_number}
                      <a
                        className="float-right"
                        href={`fileopener:K:\\Kutter Leipzig\\Angebote - Projekte\\${generateProjectNumberInterval(
                          order.project_number
                        )}\\${order.project_number}\\1. Angebotsphase`}
                        onClick={e => e.stopPropagation()}>
                        <FontAwesomeIcon icon={faLink} />
                      </a>
                      <br />
                      <p className="badge badge-secondary">{order.ebNumber}</p>
                    </span>
                  ) : // eslint-disable-next-line no-nested-ternary
                  type === 1 ? (
                    <span>von {order.subcontractor}</span>
                  ) : type === 2 ? (
                    <span>an {order.subcontractor}</span>
                  ) : null
                ) : null}
              </div>
              <div className={className} style={getStyle(3)}>
                {`${dateToString(new Date(elem.sourceDate))} - ${dateToString(
                  new Date(elem.destinationDate)
                )}`}
              </div>
              <div className={className} style={getStyle(4)}>
                {customer}
              </div>
              <div className={className} style={getStyle(5)}>
                {buildingProject}
              </div>
              <div className={className} style={getStyle(6)}>
                {buildingAddress}
                {hasGPS ? (
                  <FontAwesomeIcon
                    className="order-gps-icon"
                    icon={faLocationArrow}
                  />
                ) : null}
              </div>
              <div className={className} style={getStyle(7)}>
                {machines.map(machine => {
                  return (
                    <Fragment key={machine.id}>
                      <span>{machine.name}</span>
                      <span className="float-right">{machine.number}</span>
                      <br />
                    </Fragment>
                  );
                })}
              </div>
              <div className={className} style={getStyle(8)}>
                <span>
                  {elem.humanResource.firstname} {elem.humanResource.lastname}
                </span>
                <span
                  className={`float-right badge badge-pill cursor-pointer ${badgeType}`}
                  onClick={e => {
                    e.stopPropagation();
                    setTIsEmployeeHoursValid(
                      elem.id,
                      elem.humanResource.id,
                      !elem.humanResource.isEmployeeHoursValid
                    );
                  }}>
                  {validEmployeeHours.length}/{filteredOrderWorkDays.length}
                </span>
              </div>
              <div className={className} style={getStyle(9)}>
                <div>{destinationContactKutterName}</div>
                <div>{sourceContactKutterName}</div>
              </div>
              <div className={className} style={getStyle(10)}>
                <span className="pr-2 d-inline-block" style={{ width: "10%" }}>
                  <FontAwesomeIcon
                    className={`cursor-pointer ${
                      isAllowanceValid ? "text-success" : ""
                    }`}
                    icon={isAllowanceValid ? faCheckCircle : faCircle}
                    onClick={e => {
                      e.stopPropagation();
                      setTAllowanceValid(elem.id, !elem.allowanceValid);
                    }}
                  />
                </span>
                {allowances
                  ? allowances.map(allowance => (
                      <UploadedFileElement
                        key={allowance}
                        handle={allowance}
                        handleDelete={() =>
                          deleteTransferAllowance(
                            this.props.client,
                            elem.id,
                            allowance
                          ).then(this.props.loadData)
                        }
                      />
                    ))
                  : null}
                <DropZone
                  className="d-inline-block"
                  style={{ width: allowances ? "100%" : "85%" }}
                  multi={true}
                  isFileStack={true}
                  accept={"application/pdf,image/*"}
                  onDrop={handles => {
                    const data = [...handles];
                    if (allowances) {
                      data.push(...allowances);
                    }
                    setTransferAllowances(
                      this.props.client,
                      elem.id,
                      data
                    ).then(this.props.loadData);
                  }}>
                  {percentage => (
                    <div className="alert-danger rounded small p-2 text-center cursor-pointer">
                      {percentage ? (
                        `${percentage}%`
                      ) : (
                        <FontAwesomeIcon icon={faTimes} />
                      )}
                    </div>
                  )}
                </DropZone>
              </div>

              <div className={className} style={getStyle(11)}>
                <div className="d-inline-block w-75 pr-2">
                  <input
                    className="form-control"
                    defaultValue={
                      this.state.transferInvoiceNumber[elem.id] ||
                      elem.invoiceNumber ||
                      ""
                    }
                    onChange={e =>
                      setTransferInvoiceNumberFunc(elem.id, e.target.value)
                    }
                  />
                </div>
                {invoice ? (
                  <UploadedFileElement
                    handle={invoice}
                    handleDelete={() =>
                      deleteTransferInvoice(this.props.client, elem.id).then(
                        this.props.loadData
                      )
                    }
                  />
                ) : (
                  <DropZone
                    className="d-inline-block w-25"
                    isFileStack={true}
                    accept={"application/pdf"}
                    onDrop={handle => {
                      setTransferInvoice(
                        this.props.client,
                        elem.id,
                        handle
                      ).then(() => this.props.loadData());
                    }}>
                    {percentage => (
                      <div className="alert-danger rounded small p-2 text-center cursor-pointer">
                        {percentage ? (
                          `${percentage}%`
                        ) : (
                          <FontAwesomeIcon icon={faTimes} />
                        )}
                      </div>
                    )}
                  </DropZone>
                )}
              </div>
              <div className={`${className} ${MAIN_NOTE_CSS}`} style={getStyle(12)}>
                <textarea
                  className="h-100 w-100 rounded"
                  style={{
                    border: "1px solid #ced4da"
                  }}
                  defaultValue={
                    this.state.transferNotes[elem.id] || elem.note || ""
                  }
                  onChange={e => setTransferNotesFunc(elem.id, e.target.value)}
                />
              </div>
              <div className={`${className} ${EXTERNAL_NOTES_CSS} ${HIDE_NOTE_CSS}`} style={getStyle(12)}>
                {elem.notes}
              </div>
            </Fragment>
          );
        }
        default:
          break;
      }
      return null;
    };

    this.setChosenOrder = this.setChosenOrder.bind(this);

    this.setFilter = debounce(filter => this.setState({ filter }), 300);
    this.setContinuanceFilter = this.setContinuanceFilter.bind(this);
    this.setSortKey = this.setSortKey.bind(this);
  }

  setChosenOrder(order) {
    const { client, dispatch } = this.props;

    dispatch(getOrder(client, order.id));
    window.history.pushState({}, null, `/accounting/${order.id}`);
  }

  setContinuanceFilter(e) {
    this.setState({ continuanceFilter: e.target.checked });
  }

  setSortKey(key) {
    const { sortKey, reverseSort } = this.state;
    if (key === sortKey) {
      this.setState({ reverseSort: !reverseSort });
    } else {
      this.setState({ sortKey: key, reverseSort: false });
    }
  }

  static filter(data, filterString, continuanceFilter) {
    const filteredDataOrder = data.filter(order => {
      if (!filterString) {
        return true;
      }
        return(
            (order.building_address &&
          order.building_address.includes(filterString)) ||
        (order.construction_manager_name &&
          order.construction_manager_name.includes(filterString)) ||
        (order.certified_foreman_name &&
          order.certified_foreman_name.includes(filterString)) ||
        (order.establishment && order.establishment.includes(filterString)) ||
        (order.cost_center &&
          ((order.cost_center.establishment &&
            order.cost_center.establishment.includes(filterString)) ||
            (order.cost_center.value &&
              order.cost_center.value.toString().includes(filterString)) ||
            (order.cost_center.oldValue &&
              order.cost_center.oldValue.toString().includes(filterString)) ||
            (order.cost_center.customer &&
              order.cost_center.customer.includes(filterString)) ||
            (order.cost_center.building_project &&
              order.cost_center.building_project.includes(filterString))))
      );
    });

    const filteredDataTransfer = data.filter(transfer => {
      if (!filterString) {
        return true;
      }
      return (
        transfer.destinationOrder &&
        ((transfer.destinationOrder.building_address &&
          transfer.destinationOrder.building_address.includes(filterString)) ||
          (transfer.destinationOrder.construction_manager_name &&
            transfer.destinationOrder.construction_manager_name.includes(
              filterString
            )) ||
          (transfer.destinationOrder.certified_foreman_name &&
            transfer.destinationOrder.certified_foreman_name.includes(
              filterString
            )) ||
          (transfer.destinationOrder.establishment &&
            transfer.destinationOrder.establishment.includes(filterString)) ||
          (transfer.destinationOrder.cost_center &&
            ((transfer.destinationOrder.cost_center.establishment &&
              transfer.destinationOrder.cost_center.establishment.includes(
                filterString
              )) ||
              (transfer.destinationOrder.cost_center.value &&
                transfer.destinationOrder.cost_center.value
                  .toString()
                  .includes(filterString)) ||
              (transfer.destinationOrder.cost_center.oldValue &&
                transfer.destinationOrder.cost_center.oldValue
                  .toString()
                  .includes(filterString)) ||
              (transfer.destinationOrder.cost_center.customer &&
                transfer.destinationOrder.cost_center.customer.includes(
                  filterString
                )) ||
              (transfer.destinationOrder.cost_center.building_project &&
                transfer.destinationOrder.cost_center.building_project.includes(
                  filterString
                )))))
      );
    });


    const filteredDataCommon = data.filter(data => {
      if (!filterString) {
        return true;
      }
      let filterMachine = false;
      // resources can be reservation, inside a resources array, a resource object defining the machine number(ID)
      // and name can be found.
      if (data.resources) {
        data.resources.forEach( r => {
          if (r && r.resource) {
            if (r.resource.number) filterMachine = filterMachine || r.resource.number.includes(filterString);
            if (r.resource.name) filterMachine = filterMachine || r.resource.name.includes(filterString);
          }
        });
      }

      let filterVehicle = false;

      if (data.vehicles) {
        data.vehicles.forEach( v => {
          if (v && v.resource ) {
            if (v.resource.number) filterVehicle = filterVehicle || v.resource.number.includes(filterString);
            if (v.resource.licensePlate) filterVehicle = filterVehicle || v.resource.licensePlate.includes(filterString);
          }
        })
      }


      let filterOperator = false;
      function uncapitalize(str) {
          return str.charAt(0).toLowerCase() + str.slice(1);
      }
      // Note: certified_foreman_name and construction manager name are also filtered arguments.
      if (data.humanResources){
        data.humanResources.forEach( hr => {
          if (hr && hr.humanResource) {
            if (hr.humanResource.firstname) filterOperator = filterOperator || hr.humanResource.firstname.includes(filterString)
                                                                            || uncapitalize(hr.humanResource.firstname).includes(filterString);
            if (hr.humanResource.lastname) filterOperator = filterOperator || hr.humanResource.lastname.includes(filterString)
                                                                           || uncapitalize(hr.humanResource.lastname).includes(filterString);
          }
        })
      }
      return filterMachine || filterOperator || filterVehicle;
    })

    const filteredData = data.filter(
      d => filteredDataOrder.includes(d) || filteredDataTransfer.includes(d) ||  filteredDataCommon.includes(d)
    );

    return (continuanceFilter
      ? filteredData.filter(elem => {
          // eslint-disable-next-line no-underscore-dangle
          switch (elem.__typename) {
            case "Order": {
              return elem.cost_center ? elem.cost_center.is_continuance : false;
            }
            case "Transfer": {
              return elem.destinationOrder && elem.destinationOrder.cost_center
                ? elem.destinationOrder.cost_center.is_continuance
                : false;
            }
            default: {
              return true;
            }
          }
        })
      : filteredData
    ).filter(e => e);
  }

  render() {
    let { listData } = this.props;

    const { chosenOrderId, loading } = this.props;
    const { filter, continuanceFilter } = this.state;

    listData = List.filter(listData, filter, continuanceFilter);

    listData.sort((a, b) => {
      const endDateA = new Date(
        a.__typename === "Order"
          ? a.operational_period_finish
          : a.destinationDate
      );
      const endDateB = new Date(
        b.__typename === "Order"
          ? b.operational_period_finish
          : b.destinationDate
      );

      return endDateB - endDateA;
    });

    return (
      <Fragment>
        <div className="row mt-3">
          <div className="col">
            <input
              className="form-control"
              placeholder="Filter..."
              defaultValue={filter}
              onChange={e => this.setFilter(e.target.value)}
            />
          </div>
          <div className="col-auto pt-2">
            <div className="form-check">
              <input
                id="continuance-filter-input"
                className="form-check-input"
                type="checkbox"
                checked={continuanceFilter}
                onChange={this.setContinuanceFilter}
              />
              <label
                className="form-check-label"
                htmlFor="continuance-filter-input">
                Dauerabrufe
              </label>
            </div>
          </div>
        </div>
        <SimpleTable
          className="mt-3"
          headRenderer={this.tableHeadRenderer}
          colRenderer={this.tableColRenderer}
          selected={["id", chosenOrderId]}
          data={listData}
          onColSelected={this.setSortKey}
          onRowSelected={this.setChosenOrder}
        />

        <LoadingIndicator
          className="mt-5"
          show={loading}
          loadingMessage="Lade Abrufe..."
        />
      </Fragment>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  chosenOrderId: PropTypes.string,
  listData: PropTypes.array,
  humanResources: PropTypes.array,
  write: PropTypes.bool,
  loading: PropTypes.bool,
  onViewChange: PropTypes.func,
  loadData: PropTypes.func,
  openView: PropTypes.string,
  establishment: PropTypes.object
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  humanResources: state.humanResources.get("users"),
  establishment: state.main.get("establishment")
}))(List);
