import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import List from "./list";
import Data from "./data";

import getContacts from "../../actions/getContacts";
import getCompanies from "../../actions/getCompanies";

class App extends Component {
  constructor(props) {
    super(props);

    const { client, dispatch, location } = props;

    document.title = "Kontakte";

    const id = location.pathname.split("/")[2];
    dispatch(getContacts(client, id));
    dispatch(getCompanies(client));
  }

  componentDidMount() {
    const { mp } = this.props;
    if (mp && mp.isInit && mp._mp) {
      const tracking_obj_param = {
        Screen: document.title,
        "Tracking Time": new Date().toDateString()
      };
      mp._mp.track("Screen View", tracking_obj_param);
    }
  }
  render() {
    const {
      companies,
      chosenCompany,
      contacts,
      chosenContact,
      permissions,
      loginName,
      mp
    } = this.props;

    const write =
      Boolean(permissions.find(p => p.name === "contacts" && p.write)) ||
      loginName === "klout";

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <List
              className="col-md-6 padding-20"
              companies={companies}
              contacts={contacts}
              contact={chosenContact}
              write={write}
            />
            <Data
              className="col-md-6 data-panel padding-20 border-left"
              contact={chosenContact}
              companies={companies}
              company={chosenCompany}
              write={write}
              mp={mp}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  contacts: PropTypes.array,
  companies: PropTypes.array,
  permissions: PropTypes.array,
  chosenContact: PropTypes.object,
  chosenCompany: PropTypes.object,
  client: PropTypes.object,
  location: PropTypes.object,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  contacts: state.contacts.get("contacts"),
  chosenContact: state.contacts.get("chosenContact"),
  companies: state.contacts.get("companies"),
  chosenCompany: state.contacts.get("chosenCompany"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName")
}))(App);
