import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";

import "./style.css";

import moment from "moment";
import Column from "./column";
import Floorplan from "./floorplan";

import getWorkshopTasks from "../../actions/getWorkshopTasks";
import getResources from "../../actions/getResources";
import getVehicles from "../../actions/getVehicles";
import getHumanResources from "../../actions/getUsers";

import createWorkshopTask from "../../actions/createWorkshopTask";
import updateWorkshopTask from "../../actions/updateWorkshopTask";
import updateWorkshopTaskColumn from "../../actions/updateWorkshopTaskColumn";
import updateWorkshopTaskArchiveStatus from "../../actions/updateWorkshopTaskArchiveStatus";
import deleteWorkshopTask from "../../actions/deleteWorkshopTask";

class App extends Component {
  constructor(props) {
    super(props);
    document.title = "Werkstatt";

    const { client, dispatch, currentDate } = this.props;

    const columnOrder = [
      "column0",
      "column1",
      "column2",
      "column3",
      "column100"
    ];

    this.state = {
      columnOrder,
      dragStartId: ""
    };

    dispatch(getWorkshopTasks(client, currentDate));
    dispatch(getResources(client));
    dispatch(getVehicles(client));
    dispatch(getHumanResources(client));

    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);

    this.newTask = this.newTask.bind(this);
    this.addTask = this.addTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.editTask = this.editTask.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.handleArchive = this.handleArchive.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
  }

  componentDidMount() {
    const { mp } = this.props;
    if (mp && mp.isInit && mp._mp) {
      const tracking_obj_param = {
        Screen: document.title,
        "Tracking Time": new Date().toDateString()
      };
      mp._mp.track("Screen View", tracking_obj_param, () =>
        console.log("tracked ", tracking_obj_param)
      );
    }
  }
  handleDragStart(start) {
    this.setState({ dragStartId: start.source.droppableId });
  }

  handleDragEnd(result) {
    const { client, dispatch, tasks, columns, currentDate } = this.props;
    const { draggableId, source, destination } = result;

    this.setState({ dragStartId: "" });

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (
      source.droppableId === "column0" &&
      !(
        destination.droppableId === "column0" ||
        destination.droppableId === "column1"
      )
    ) {
      return;
    }

    const sourceColumn = columns[source.droppableId];
    const destinationColumn = columns[destination.droppableId];
    let sourceTaskIds = sourceColumn.taskIds;
    let destinationTaskIds = destinationColumn.taskIds;

    sourceTaskIds = sourceTaskIds.filter(e => e !== draggableId);
    destinationTaskIds = [draggableId, ...destinationTaskIds];

    const newColumns = {
      ...columns,
      [sourceColumn.id]: { ...sourceColumn, taskIds: sourceTaskIds },
      [destinationColumn.id]: {
        ...destinationColumn,
        taskIds: destinationTaskIds
      }
    };

    const sourceColumnId = parseInt(source.droppableId.substr(6), 10);
    const destinationColumnId = parseInt(destination.droppableId.substr(6), 10);

    const task = tasks.find(t => t.id === draggableId);
    const plannedDate = moment(task.dueDate);
    const deliveryDate = plannedDate.isBefore(currentDate)
      ? plannedDate
      : currentDate;

    if (task) {
      dispatch(
        updateWorkshopTaskColumn(
          client,
          task,
          sourceColumnId,
          destinationColumnId,
          deliveryDate
        )
      );
    }

    dispatch({ type: "SET_COLUMNS", data: newColumns });
  }

  newTask() {
    const { dispatch, tasks, columns } = this.props;

    if (!tasks.find(task => task.id === "new")) {
      const newTask = {
        id: "new"
      };

      const newColumns = {
        ...columns,
        column0: {
          ...columns.column0,
          taskIds: columns.column0.taskIds.concat(newTask.id)
        }
      };

      dispatch({
        type: "SET_TASKS_AND_COLUMNS",
        data: { tasks: tasks.concat(newTask), columns: newColumns }
      });
    }
  }

  addTask(task) {
    const { client, dispatch, currentDate } = this.props;

    dispatch(createWorkshopTask(client, task, currentDate));
  }

  updateTask(task, fileUpdate) {
    const { client, dispatch, tasks, currentDate } = this.props;

    const oldTask = tasks.find(t => t.id === task.id);

    if (oldTask)
      dispatch(
        updateWorkshopTask(
          client,
          task,
          oldTask,
          currentDate,
          false,
          fileUpdate
        )
      );
  }

  editTask(e) {
    const { dispatch, tasks } = this.props;

    const { id } = e.currentTarget.dataset;

    if (!tasks.find(t => t.id === "new")) {
      const newTasks = tasks.map(t => {
        if (t.id === id) return { ...t, edit: true };
        return t;
      });

      dispatch({
        type: "SET_TASKS",
        data: newTasks
      });
    }
  }

  cancelEdit(e) {
    const { dispatch, tasks, columns } = this.props;

    const { id } = e.currentTarget.dataset;

    let newColumns = columns;
    let newTasks = tasks;

    if (id === "new") {
      newTasks = tasks.filter(task => task.id !== id);

      newColumns = {};
      Object.keys(columns).forEach(column => {
        const c = columns[column];
        newColumns[column] = {
          ...c,
          taskIds: c.taskIds.filter(taskId => taskId !== id)
        };
      });
    } else {
      newTasks = tasks.map(task => {
        if (task.id === id) return { ...task, edit: false };
        return task;
      });
    }

    dispatch({
      type: "SET_TASKS_AND_COLUMNS",
      data: { tasks: newTasks, columns: newColumns }
    });
  }

  handleArchive(e) {
    const { client, dispatch } = this.props;
    const { id } = e.currentTarget.dataset;

    dispatch(updateWorkshopTaskArchiveStatus(client, id, true));
  }

  deleteTask(e) {
    const { client, dispatch, tasks } = this.props;
    const { id } = e.currentTarget.dataset;

    const task = tasks.find(t => t.id === id);

    if (task) dispatch(deleteWorkshopTask(client, task));
  }

  render() {
    const {
      permissions,
      loginName,
      workspaces,
      columns,
      establishment
    } = this.props;
    const { columnOrder, dragStartId } = this.state;

    const tasks = this.props.tasks.filter(
      t =>
        t.id === "new" ||
        !establishment ||
        t.establishment === establishment.value
    );
    //console.log(tasks);

    const write =
      Boolean(permissions.find(p => p.name === "workshop" && p.write)) ||
      loginName === "klout";

    const workspacesWithTasks = workspaces.map(workspace => ({
      ...workspace,
      tasks: []
    }));

    const importantTasks = [];
    columns.column2.taskIds.forEach(id => {
      const task = tasks.find(t => t.id === id);
      if (task) importantTasks.push(task);
    });

    importantTasks.forEach(task => {
      const { resourceType, resource, externalWorkshop } = task;
      let text = "";
      switch (resourceType) {
        case "RESOURCE": {
          if (resource) {
            text = `${resource.name} ${resource.number}`;
          }
          break;
        }
        case "VEHICLE": {
          if (resource) {
            text = resource.licensePlate;
          }
          break;
        }
        default:
          text = "Sonstiges / Werkstattarbeiten";
          break;
      }

      if (task.workspaces.find(ws => ws.id === "14") && externalWorkshop !== "")
        text += ` (${externalWorkshop})`;

      const displayObj = {
        id: task.id,
        text
      };

      task.workspaces.forEach(w => {
        const workspace = workspacesWithTasks.find(elem => elem.id === w.id);
        if (workspace) workspace.tasks.push(displayObj);
      });
    });

    return (
      <div className="container-fluid cb-container">
        <DragDropContext
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}
        >
          <div className="row cb-height">
            {columnOrder.map(columnId => {
              const column = columns[columnId];
              const columnTasks = column.taskIds
                .map(taskId => tasks.find(task => task.id === taskId))
                .filter(e => e);

              return (
                <Column
                  key={column.id}
                  column={column}
                  tasks={columnTasks}
                  dragStartId={dragStartId}
                  write={write}
                  onNewTask={this.newTask}
                  onAddTask={this.addTask}
                  onUpdateTask={this.updateTask}
                  onEdit={this.editTask}
                  onCancelEdit={this.cancelEdit}
                  onArchive={this.handleArchive}
                  onDelete={this.deleteTask}
                />
              );
            })}

            <Floorplan
              workspaces={workspacesWithTasks}
              establishment={establishment ? establishment.value : ""}
            />
          </div>
        </DragDropContext>
      </div>
    );
  }
}

App.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  permissions: PropTypes.array,
  loginName: PropTypes.string,
  workspaces: PropTypes.array,
  tasks: PropTypes.array,
  columns: PropTypes.object,
  blocked: PropTypes.bool,
  currentDate: PropTypes.object,
  establishment: PropTypes.object
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName"),
  establishment: state.main.get("establishment"),
  workspaces: state.workshop.get("workspaces"),
  tasks: state.workshop.get("tasks"),
  columns: state.workshop.get("columns"),
  blocked: state.workshop.get("blocked"),
  currentDate: state.workshop.get("currentDate")
}))(App);
