import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Data from "./data";
import List from "./list";

import getResources from "../../actions/getResources";
import getMachinists from "../../actions/getMachinists";

class App extends Component {
  constructor(props) {
    super(props);

    document.title = "Maschinen";

    const id = props.location.pathname.split("/")[2];
    props.dispatch(getResources(props.client, id));
    props.dispatch(getMachinists(props.client));
  }

  componentDidMount() {
    const { mp } = this.props;
    if (mp && mp.isInit && mp._mp) {
      const tracking_obj_param = {
        Screen: document.title,
        "Tracking Time": new Date().toDateString()
      };
      mp._mp.track("Screen View", tracking_obj_param, () =>
        console.log("tracked ", tracking_obj_param)
      );
    }
  }

  render() {
    const {
      permissions,
      loginName,
      establishment,
      resources,
      chosenResource,
      mp
    } = this.props;

    const hasWritePermission =
      Boolean(permissions.find(p => p.name === "resources" && p.write)) ||
      loginName === "klout";

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <List
              className="col-md-6 padding-20"
              establishment={establishment}
              resources={resources}
              chosenResource={chosenResource}
              write={hasWritePermission}
            />
            <Data
              className="col-md-6 data-panel padding-20 border-left"
              establishment={establishment}
              resource={chosenResource}
              resources={resources}
              write={hasWritePermission}
              mp={mp}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  establishment: PropTypes.object,
  resources: PropTypes.array,
  chosenResource: PropTypes.object,
  users: PropTypes.array,
  location: PropTypes.object,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment"),
  resources: state.resources.get("resources"),
  chosenResource: state.resources.get("chosenResource"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName")
}))(App);
