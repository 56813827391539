/* eslint-disable camelcase */
import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import queryString from "query-string";

import {
  faAngleRight,
  faArrowsAlt,
  faCheck,
  faClipboardList,
  faExternalLinkAlt,
  faEye,
  faPen,
  faPlusCircle,
  faTimes,
  faTrashAlt
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AbsenceBox from "./absenceBox";
import ConflictBox from "./conflictBox";
import SelectionBox from "./selectionBox";
import OrderBox from "./orderBox";
import ReservationBox from "./reservationBox";
import ContextMenu from "../../ContextMenu";
import ReservationData from "./reservationData";
import Modal from "../../Modal";

import createReservation from "../../../actions/createReservation";
import createOrderFromMachineDispo from "../../../actions/createOrderFromMachineDispo";
import updateReservation from "../../../actions/updateReservation";
import deleteReservation from "../../../actions/deleteReservation";
import extendOrderFromMachineDispo from "../../../actions/extendOrderFromMachineDispo";
import updateOrder from "../../../actions/updateOrder";
import InactiveBox from "./InactiveBox";
import TimeModal from "../../../util/timeModal";
import updateDispoMachine from "../../../actions/updateDispoMachine";
import updateDispoVehicle from "../../../actions/updateDispoVehicle";
import updateDispoHumanResourceTime from "../../../actions/updateDispoHumanResourceTime";
import updateOrderPeriod from "../../../actions/updateOrderPeriod";
import createContactAndReservation from "../../../actions/createContactAndReservation";

class Reservations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: [],
      sx: -1,
      y: 0,
      selection: null,
      rsx: -1,
      resizeElement: null,
      contextMenu: {
        show: false,
        x: 0,
        y: 0,
        bounds: [0, 0],
        context: null,
        content: []
      },
      selectMode: false,
      selectedElements: [],
      showReservationModal: false,
      showNoteModal: false,
      reservationData: null,
      showConfirmationModal: false,
      resources: [],
      vehicles: [],
      humanResources: [],
      machinesToAdjust: [],
      vehiclesToAdjust: [],
      humanResourcesToAdjust: [],
      opStartDate: null,
      opFinishDate: null,
      orderStartDate: null,
      orderFinishDate: null,
      order: null,
      orderCheck: false,
      selectedResourceId: null
    };

    const start = moment(props.start);
    this.state.elements = Reservations.getElements(
      props.resources,
      start,
      props.showHours
    );

    this.node = null;

    this.startSelectionDrag = this.startSelectionDrag.bind(this);
    this.doSelectionDrag = this.doSelectionDrag.bind(this);
    this.endSelectionDrag = this.endSelectionDrag.bind(this);

    this.setResizeElement = this.setResizeElement.bind(this);
    this.startResize = this.startResize.bind(this);
    this.doResize = this.doResize.bind(this);
    this.endResize = this.endResize.bind(this);
    this.unsetResizeElement = this.unsetResizeElement.bind(this);

    this.cancelSelection = this.cancelSelection.bind(this);

    this.createReservation = this.createReservation.bind(this);
    this.updateReservation = this.updateReservation.bind(this);
    this.deleteReservation = this.deleteReservation.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.createNote = this.createNote.bind(this);
    this.updateNote = this.updateNote.bind(this);

    this.contextMenuActionHandler = this.contextMenuActionHandler.bind(this);
    this.openContextMenu = this.openContextMenu.bind(this);
    this.closeContextMenu = this.closeContextMenu.bind(this);

    this.openOrder = this.openOrder.bind(this);
    this.openAbsence = this.openAbsence.bind(this);
    this.openResource = this.openResource.bind(this);

    this.setSelectMode = this.setSelectMode.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.handleReservationDataChange = this.handleReservationDataChange.bind(
      this
    );
    this.handleNoteDataChange = this.handleNoteDataChange.bind(this);
    this.openReservationModal = this.openReservationModal.bind(this);
    this.closeReservationModal = this.closeReservationModal.bind(this);
    this.handleModalChange = this.handleModalChange.bind(this);
    this.handleModalOrderChange = this.handleModalOrderChange.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.node = ReactDOM.findDOMNode(this);
  }

  componentDidUpdate(prevProps) {
    const { start, resources, showHours } = this.props;
    const { resizeElement } = this.state;

    if (
      prevProps.resources !== resources ||
      prevProps.showHours !== showHours
    ) {
      const nextElements = Reservations.getElements(
        resources,
        moment(start),
        showHours,
        resizeElement
      );

      this.setState({
        elements: nextElements,
        sx: -1,
        y: -1
        // selection: null
      });
    }
  }

  static preventEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  static getElements(resources, startDate, showHours, resizeElement) {
    const elements = [];

    let last = null;
    resources.forEach(elem => {
      const resourceElements = [];
      const resourceAbsenceElements = [];
      if (elem.type === "RESOURCE") {
        const { reservations, humanResources } = elem;
        reservations.forEach(reservation => {
          let start = moment(reservation.from);
          let end = moment(reservation.to);
          if (reservation.order && reservation.order.resources) {
            const resource = reservation.order.resources.find(
              res => res.id === reservation.id
            );
            if (resource) {
              start = moment(resource.from);
              end = moment(resource.to);
            }
          }

          const element = {
            model: {
              resource: elem,
              ...reservation,
              start,
              end,
              isSet: true
            },
            view: { className: "", status: "", info: [], offset: 0, width: 5 }
          };

          Reservations.calculateViewProperties(element, startDate, showHours);

          if (resizeElement && resizeElement.model.id === element.model.id) {
            resourceElements.push(resizeElement);
          } else {
            resourceElements.push(element);
          }
        });

        humanResources.forEach(hr => {
          const thisAbsenceElements = [];

          hr.absences.forEach(absence => {
            const hrName = `${hr.firstname} ${hr.lastname}`;

            const element = {
              model: {
                ...absence,
                start: moment(absence.from).hour(0),
                end: moment(absence.to).hour(0),
                hrName
              },
              view: { offset: 0, width: 5 }
            };

            Reservations.calculateAbsenceViewProperties(
              element,
              startDate,
              showHours
            );
            thisAbsenceElements.push(element);
          });

          if (thisAbsenceElements.length > 0)
            resourceAbsenceElements.push(thisAbsenceElements);
        });
      } else {
        const { reservations } = elem;
        if (reservations) {
          reservations.forEach(reservation => {
            if (reservation.type) {
              const start = moment(reservation.from);
              const end = moment(reservation.to);

              const element = {
                model: {
                  resource: elem,
                  ...reservation,
                  start,
                  end,
                  isSet: true
                },
                view: {
                  className: "",
                  status: "",
                  info: [],
                  offset: 0,
                  width: 5
                }
              };

              Reservations.calculateViewProperties(
                element,
                startDate,
                showHours
              );

              if (
                resizeElement &&
                resizeElement.model.id === element.model.id
              ) {
                resourceElements.push(resizeElement);
              } else {
                resourceElements.push(element);
              }
            } else {
              const start = moment(reservation.sourceDate);
              const end = moment(reservation.destinationDate);

              const element = {
                model: {
                  resource: reservation,
                  status: "transfer",
                  start,
                  end,
                  isSet: true
                },
                view: {
                  className: "",
                  status: "",
                  info: [],
                  offset: 0,
                  width: 5
                }
              };

              Reservations.calculateViewProperties(
                element,
                startDate,
                showHours
              );
              resourceElements.push(element);
            }
          });
        }
      }

      if (last && last.color !== elem.color) {
        elements[elements.length - 1].groupEnd = true;
      }

      elements.push({
        elements: resourceElements,
        absenceElements: resourceAbsenceElements,
        conflictElements: [],
        color: elem.color && elem.color !== "none" ? `${elem.color}11` : "none",
        groupEnd: false
      });

      last = elem;
    });

    let humans = [];
    resources.forEach((resource, index) => {
      const { humanResources } = resource;

      if (humanResources) {
        humans = humans.concat(
          humanResources.map(hr => ({
            id: hr.id,
            // resourceId: resource.id,
            name: `${hr.firstname} ${hr.lastname}`,
            index,
            resource,
            conflicts: []
          }))
        );
      }
    });

    elements.forEach((elem, index) => {
      const { elements: reservations } = elem;
      reservations.forEach(reservation => {
        const { model, view } = reservation;
        const { order } = model;
        let correctReservation = null;
        if (order) {
          const { humanResources, resources } = order;
          if (humanResources || resources) {
            const resource = resources.find(
              res => res.id === model.id
            );
            if (resource) {
              correctReservation = resource;
            } else {
              humanResources.forEach(({ humanResource }) => {
                const human = humans.find(h => h.id === humanResource.id);
                if (human && human.index !== index) {
                  const matchingReservations = elements[human.index].elements;
                  const matchingOrder = matchingReservations.find(
                    r => r.model.order && r.model.order.id === order.id
                  );
                  correctReservation = order.humanResources.find(
                    res => res.humanResource.id === human.id
                  );
                  model.start = moment(correctReservation.from);
                  model.end = moment(correctReservation.to);
                  this.calculateViewProperties(
                    { model, view },
                    startDate,
                    showHours
                  );

                  if (!matchingOrder) {
                    human.conflicts.push({
                      model: { id: human.id, name: human.name },
                      view,
                      order,
                      reservation: model
                    });
                  }
                }
              });
            }
          }
          if (order.actual_start_date_time) {
            view.hasActualStartTime = true;
          }
          if (order.actual_end_date_time) {
            view.hasActualEndTime = true;
          }
          if (order.actual_end_date_time && order.operational_period_finish) {
            const diff = moment(order.actual_end_date_time).diff(
              moment(model.start),
              "hours"
            );
            if (showHours) {
              view.actualWidth = 20 * diff;
            } else {
              view.actualWidth = 5 * diff;
            }
          }
        }
      });
    });

    humans.forEach(human => {
      if (human.conflicts.length) {
      }
      elements[human.index].conflictElements = human.conflicts;
    });

    return elements;
  }

  static calculateViewProperties(element, startDate, showHours) {
    const { model, view } = element;
    if (model.isSet) {
      const start = moment(model.start);
      const end = moment(model.end);

      const offsetHours = start.diff(startDate, "hours");
      const widthHours = Math.ceil(end.diff(start, "hours", true));

      let offset = 0;
      let width = 0;
      if (showHours) {
        // a hour is 20 pixels wide
        offset = 20 * offsetHours;
        width = 20 * widthHours;
      } else {
        // a hour is 5 pixels wide
        offset = 5 * offsetHours;
        width = 5 * widthHours;
      }

      const { order } = model;
      let { status } = model;
      let boxType = "RESERVATION_BOX";

      if (status.toString().startsWith("RES_")) {
        status = status.slice(4);
        if (model.scopeOfServices)
          view.info = model.scopeOfServices.map(sos =>
            Reservations.sosToString(sos)
          );
      } else if (status.toString().startsWith("WS_")) {
        const parts = status.split("_");
        status = parts.length > 2 ? parts[2] : "Werkstatt";
        boxType = "WORKSHOP_BOX";
      } else if (model.status === "transfer") {
        const { resource } = model;
        const { humanResource, vehicle, combination } = resource;

        view.info = [`${humanResource.lastname}, ${humanResource.firstname}`];

        const text = vehicle
          ? ` ${vehicle.type} ${vehicle.brand} (${vehicle.licensePlate} / ${vehicle.category})`
          : ` Gespann "${combination.description}" vom Standort ${combination.establishment}`;

        status = `Transfer mit ${text}`;
        boxType = "TRANSFER_BOX";
      } else if (order) {
        const costCenter = order.cost_center;
        if (costCenter) {
          let buildingProjectText = "kein Bauvorhaben definiert";
          if (costCenter.building_project && costCenter.building_project !== "")
            buildingProjectText = costCenter.building_project;

          status = `${buildingProjectText} (${
            costCenter.value ? order.cost_center.value : "k.A."
          })`;
        } else {
          status = order.description;
        }

        if (order.scopeOfServices) {
          view.info = order.scopeOfServices.map(sos =>
            Reservations.sosToString(sos)
          );
        }

        boxType = "ORDER_BOX";
      } else {
        status = "Abruf gelöscht";
        boxType = "DELETED_BOX";
      }

      view.type = boxType;
      view.status = status;
      view.offset = offset;
      view.width = width;
    }
  }

  static calculateAbsenceViewProperties(element, startDate, showHours) {
    const { model, view } = element;

    const start = moment(model.start);
    const end = moment(model.end);

    const offsetHours = start.diff(startDate, "hours");
    const widthHours = Math.ceil(end.diff(start, "hours", true)) + 24;

    let offset = 0;
    let width = 0;
    if (showHours) {
      // a hour is 20 pixels wide
      offset = 20 * offsetHours;
      width = 20 * widthHours;
    } else {
      // a hour is 5 pixels wide
      offset = 5 * offsetHours;
      width = 5 * widthHours;
    }

    view.offset = offset;
    view.width = width;
  }

  static sosToString(sos) {
    const sostValues = [
      ...new Set(
        sos.scopeOfServiceTyps.map(sost =>
          sost.value.startsWith("OTHER:") ? sost.value.substr(6) : sost.value
        )
      )
    ];

    let depthStr = sos.depth;
    if (sos.depthTo !== undefined && sos.depthTo !== null && sos.depthTo !== "")
      depthStr += ` - ${sos.depthTo}`;

    let str = sostValues.join(", ");
    str += `  (${sos.width} | ${depthStr} | ${sos.amount} ${sos.amountUnit})`;
    return str;
  }

  startSelectionDrag(e) {
    const { loading, write } = this.props;
    const { resizeElement } = this.state;

    this.closeContextMenu();

    if (resizeElement || !write || loading) return;

    const rect = this.node.getBoundingClientRect();
    const x = -(rect.x - e.clientX);
    const y = -(rect.y - e.clientY);

    this.setState({ sx: x, y: y - (y % 41) });
  }

  doSelectionDrag(e) {
    const { showHours } = this.props;
    const { elements, y } = this.state;
    let { sx } = this.state;

    const ix = sx;

    const step = showHours ? 20 : 120;

    if (sx < 0) return;

    const rect = this.node.getBoundingClientRect();
    let ex = -(rect.x - e.clientX);

    if (ex < sx) {
      const temp = sx;
      sx = ex;
      ex = temp;
    }

    if (ex - sx < 4 && !this.state.selection) {
      this.setState({ selection: null });
      return;
    }

    sx -= sx % step;
    ex = ex - (ex % step) + step;

    // Check if this selection intersects with an existing box and clamp the selection accordingly.
    let min = -1;
    let max = 9999999;

    let testElements = [];
    const index = y / 41;
    if (index >= 0 && elements.length > index)
      testElements = elements[index].elements;

    for (let i = 0; i < testElements.length; i += 1) {
      const { view } = testElements[i];
      const a = view.offset;
      const b = view.offset + view.width;
      if (ix >= b) if (b > min) min = b;
      if (ix <= a) if (a < max) max = a;
    }

    sx = sx < min ? min : sx;
    ex = ex > max ? max : ex;

    const selection = {
      model: {
        isSet: false
      },
      view: {
        offsetX: sx,
        offsetY: y,
        width: ex - sx
      }
    };

    this.setState({ selection });
  }

  endSelectionDrag(e) {
    const { resources, start, showHours } = this.props;
    const { selection, sx, y } = this.state;

    const rect = this.node.getBoundingClientRect();
    const x = -(rect.x - e.clientX);

    if (selection && x !== sx) {
      let selectionStart = null;
      let selectionEnd = null;
      const { view } = selection;
      if (showHours) {
        const hoursToStart = view.offsetX / 20;
        const hoursToEnd = (view.offsetX + view.width) / 20;

        selectionStart = moment(start).add(hoursToStart, "hours");
        selectionEnd = moment(start).add(hoursToEnd, "hours");
      } else {
        const daysToStart = view.offsetX / 120;
        const daysToEnd = (view.offsetX + view.width - 120) / 120;

        selectionStart = moment(start).add(daysToStart, "days");
        selectionEnd = moment(start).add(daysToEnd, "days");
        selectionStart.hours(5);
        selectionEnd.hours(20);
      }

      const index = y / 41;
      let resource = null;

      const extendedResources = resources.flatMap(r => {
        if (r.heightFactor) {
          const returnResources = [];
          for (let i = 0; i < r.heightFactor; i += 1) {
            returnResources.push(r);
          }

          return returnResources;
        }
        return [r];
      });

      if (index >= 0 && extendedResources.length >= index) {
        resource = extendedResources[index];
      }

      const finalizedSelection = {
        ...selection,
        model: {
          resource,
          isSet: true,
          start: selectionStart,
          end: selectionEnd
        }
      };

      this.setState({ selection: finalizedSelection });
    }

    this.setState({ sx: -1, y: -1 });
  }

  cancelSelection() {
    this.setState({ sx: -1, y: -1, selection: null });
  }

  createReservation() {
    const { dispatch, client } = this.props;
    const { selection, reservationData } = this.state;

    if (reservationData.id) {
      this.updateReservation();
    }

    if (selection && reservationData) {
      const { model } = selection;
      const { resource, start, end } = model;

      if (resource && start && end) {
        if (
          reservationData.selectedContact &&
          isNaN(parseInt(reservationData.selectedContact.value))
        ) {
          const nameArr = reservationData.selectedContact.value.split(" ");
          createContactAndReservation(
            dispatch,
            client,
            nameArr[0],
            nameArr.length > 1 ? nameArr[1] : "Unbekannt",
            resource,
            reservationData,
            start,
            end
          );
        } else {
          createReservation(
            dispatch,
            client,
            resource.id,
            null,
            resource.type,
            start,
            end,
            `RES_${reservationData.name}`,
            reservationData.scopeOfServices,
            null,
            reservationData.selectedContact
              ? reservationData.selectedContact.value
              : null
          );
        }
      }
      this.setState({ selection: null });
      this.closeReservationModal();
    }
  }

  updateReservation() {
    const { dispatch, client } = this.props;
    const { reservationData } = this.state;

    if (reservationData && reservationData.resource) {
      const { id, resource } = reservationData;
      if (
        reservationData.selectedContact &&
        isNaN(parseInt(reservationData.selectedContact.value))
      ) {
        const nameArr = reservationData.selectedContact.value.split(" ");
        createContactAndReservation(
          dispatch,
          client,
          nameArr[0],
          nameArr.length > 1 ? nameArr[1] : "Unbekannt",
          resource,
          reservationData,
          reservationData.from,
          reservationData.to
        );
      } else {
        createReservation(
          dispatch,
          client,
          resource.id,
          null,
          resource.type,
          reservationData.from,
          reservationData.to,
          `RES_${reservationData.name}`,
          reservationData.scopeOfServices,
          null,
          reservationData.selectedContact
            ? reservationData.selectedContact.value
            : null
        );
      }

      setTimeout(
        () =>
          dispatch(deleteReservation(client, id, resource.id, resource.type)),
        500
      );

      this.closeReservationModal();
    }
  }

  deleteReservation(id, resourceId, type) {
    if (id) {
      const { dispatch, client } = this.props;
      dispatch(deleteReservation(client, id, resourceId, type));
    }
  }

  createOrder(model, orderData) {
    const { subcontractors } = this.props;
    const subcontractor = model.resource.name;

    let saveSubcontractor = null;
    if (subcontractors.indexOf(subcontractor) !== -1) {
      saveSubcontractor = subcontractor;
    }

    const { client, dispatch } = this.props;
    const { selectedElements } = this.state;

    if (selectedElements.length > 0) {
      if (!model && !orderData) {
        const { order } = selectedElements[0];
        const models = selectedElements.slice(1);
        if (models.length > 0) {
          dispatch(extendOrderFromMachineDispo(client, order, models));
        }
      } else {
        const {
          scopeOfServices,
          costCenter,
          noCostCenter,
          establishment
        } = orderData;

        let operationalPeriodStart = moment.utc("3000-01-01");
        let operationalPeriodFinish = moment.utc("2000-01-01");

        selectedElements.forEach(elem => {
          if (moment.utc(elem.start).isBefore(operationalPeriodStart))
            operationalPeriodStart = moment.utc(elem.start);
          if (moment.utc(elem.end).isAfter(operationalPeriodFinish))
            operationalPeriodFinish = moment.utc(elem.end);
        });

        dispatch(
          createOrderFromMachineDispo(
            client,
            noCostCenter ? null : costCenter,
            establishment ? establishment.value : null,
            operationalPeriodStart,
            operationalPeriodFinish,
            scopeOfServices,
            selectedElements,
            saveSubcontractor
          )
        );
      }
      this.setSelectMode(false);
    } else {
      const {
        scopeOfServices,
        costCenter,
        noCostCenter,
        establishment
      } = orderData;
      const { resource, start, end } = model;
      const { id, heightFactor } = resource;

      if ((id && id !== "" && orderData) || heightFactor) {
        const operationalPeriodStart = moment
          .utc(start)
          .format("YYYY-MM-DD HH:mm");
        const operationalPeriodFinish = moment
          .utc(end)
          .format("YYYY-MM-DD HH:mm");
        dispatch(
          createOrderFromMachineDispo(
            client,
            noCostCenter ? null : costCenter,
            establishment ? establishment.value : null,
            operationalPeriodStart,
            operationalPeriodFinish,
            scopeOfServices,
            [model],
            saveSubcontractor
          )
        );
        this.setState({ selection: null });
      }
    }
  }

  createNote(model, noteData) {
    const { client, dispatch } = this.props;

    const { resource, start, end } = model;
    const { id, type } = resource;

    if (id && id !== "" && noteData) {
      const operationalPeriodStart = moment
        .utc(start)
        .format("YYYY-MM-DD HH:mm:ss Z");
      const operationalPeriodFinish = moment
        .utc(end)
        .format("YYYY-MM-DD HH:mm:ss Z");
      createReservation(
        dispatch,
        client,
        id,
        null,
        type,
        operationalPeriodStart,
        operationalPeriodFinish,
        `RES_NOTE`,
        [],
        noteData
      );
      this.setState({ selection: null });
    }
  }

  updateNote() {
    const { client, dispatch } = this.props;
    const { reservationData: model } = this.state;

    const { resource, status, start, end, note } = model;
    const { id } = resource;

    if (id && id !== "" && note) {
      const operationalPeriodStart = moment
        .utc(start)
        .format("YYYY-MM-DD HH:mm:ss Z");
      const operationalPeriodFinish = moment
        .utc(end)
        .format("YYYY-MM-DD HH:mm:ss Z");

      dispatch(
        updateReservation(
          client,
          model.id,
          id,
          resource.type,
          operationalPeriodStart,
          operationalPeriodFinish,
          status,
          id,
          note
        )
      );
      this.closeReservationModal();
    }
  }

  setResizeElement(model, view, index) {
    this.setState({
      sx: -1,
      y: -1,
      selection: null,
      resizeElement: {
        model,
        view,
        index,
        tempView: JSON.parse(JSON.stringify(view)),
        oldView: JSON.parse(JSON.stringify(view)),
        oldResourceId: model.resource.id,
        valid: true
      }
    });
  }

  startResize(e, side) {
    const { resizeElement } = this.state;
    const rect = this.node.getBoundingClientRect();
    const x = -(rect.x - e.clientX);

    this.setState({
      rsx: x,
      resizeElement: {
        ...resizeElement,
        side
      }
    });
  }

  doResize(e) {
    const { showHours, resources } = this.props;
    const { rsx, resizeElement, elements } = this.state;

    if (rsx < 0 || !resizeElement) return;

    const rect = this.node.getBoundingClientRect();
    const x = -(rect.x - e.clientX);
    let y = -(rect.y - e.clientY);

    const { side, model, view, index, tempView } = resizeElement;

    const nextView = { ...view };

    let dx = 0;
    let { offset } = tempView;
    let { width } = tempView;

    // If the y value is different, place the element into the appropriate reservations slot.
    let nextIndex = index;
    y = Math.max(0, y - 15);
    const iy = (y - (y % 41)) / 41;

    if (index !== iy) {
      if (iy >= 0 && iy < elements.length) {
        nextIndex = iy;
      }
    }

    // Check if the resized box intersects with an existing box and clamp the box accordingly.
    // const testElements = elements[nextIndex].filter(
    //   elem => elem.model.id !== model.id
    // );

    const step = showHours ? 20 : 120;
    const halfStep = step / 2;

    if (side === "left") {
      dx = x - tempView.offset;
      dx = dx < 0 ? dx - halfStep : dx;
      dx -= dx % step;

      if (dx !== 0) {
        offset += dx;
        width -= dx;

        if (width <= 0) {
          offset = tempView.offset + tempView.width - 20;
          width = 20;
        }
      }
    } else if (side === "right") {
      dx = x - (tempView.offset + tempView.width);
      dx = dx >= 0 ? dx + halfStep : dx;
      dx -= dx % step;

      if (dx !== 0) {
        width += dx;

        if (width <= 0) {
          width = 20;
        }
      }
    } else {
      dx = x - rsx;
      dx = dx < 0 ? dx - halfStep : dx;
      dx -= dx % step;

      if (dx !== 0) {
        offset += dx;
      }
    }

    const ex = offset + width;

    nextView.offset = offset;
    nextView.width = side === "middle" ? width : ex - offset;

    if (
      view.offset === nextView.offset &&
      view.width === nextView.width &&
      index === nextIndex
    )
      return;

    resizeElement.view = nextView;

    if (side === "middle" && index !== nextIndex) {
      const nextResource = resources[nextIndex];

      if (nextResource.type === model.resource.type) {
        resizeElement.index = nextIndex;
        resizeElement.model.resource = resources[nextIndex];
        const elementIndex = elements[index].elements.findIndex(
          elem => elem.model.id === model.id
        );

        if (elementIndex >= 0) elements[index].elements.splice(elementIndex, 1);
        elements[nextIndex].elements.push(resizeElement);
      } else {
        nextIndex = index;
      }
    } else {
      elements[nextIndex].elements = elements[nextIndex].elements.map(elem => {
        if (elem.model.id === model.id) {
          return resizeElement;
        }
        return elem;
      });
    }

    const testIndex = side === "middle" ? nextIndex : index;

    const testElements = elements[testIndex].elements;
    if (testElements) {
      resizeElement.valid = true;
      const as = nextView.offset;
      const ae = nextView.offset + nextView.width;

      for (let i = 0; i < testElements.length; i += 1) {
        const testElement = testElements[i];

        const { model: testModel, view: testView } = testElement;

        if (testModel.id !== model.id && testView.type !== "WORKSHOP_BOX") {
          const bs = testView.offset;
          const be = testView.offset + testView.width;

          if (!(bs >= ae || as >= be)) {
            resizeElement.valid = false;
            break;
          }
        }
      }
    } else {
      resizeElement.valid = true;
    }

    this.setState(prevState => ({ ...prevState }));
  }

  endResize() {
    const { rsx, resizeElement } = this.state;

    if (rsx < 0 || !resizeElement) return;

    resizeElement.tempView = { ...resizeElement.view };

    this.setState({ rsx: -1 });
  }

  unsetResizeElement() {
    const { dispatch, client, showHours, subcontractors } = this.props;
    const { resizeElement } = this.state;

    if (resizeElement) {
      const { model, view, oldView, oldResourceId } = resizeElement;
      const startDelta = view.offset - oldView.offset;
      const endDelta =
        view.offset + view.width - (oldView.offset + oldView.width);

      const step = showHours ? 20 : 5;
      const type = "hours";

      const { start, end } = model;

      if (startDelta !== 0) {
        const number = startDelta / step;
        start.add(number, type);
      }

      if (endDelta !== 0) {
        const number = endDelta / step;
        end.add(number, type);
      }

      if (!model.order || (model.order && !model.order.subcontractor)) {
        const { id, status, resource } = model;
        dispatch(
          updateReservation(
            client,
            id,
            resource.id,
            resource.type,
            start,
            end,
            status,
            oldResourceId
          )
        );
      } else {
        const column = this.props.resources[resizeElement.index];
        const {
          id,
          cost_center,
          establishment,
          project_number,
          subcontractor,
          building_address,
          lat,
          lng,
          directions,
          construction_manager_name,
          construction_manager_phone,
          certified_foreman_name,
          certified_foreman_phone,
          kutterContact,
          start_date_time,
          start_meeting_time,
          description,
          notes
        } = model.order;

        let saveSubcontractor = null;
        if (
          column &&
          column.name &&
          subcontractors.indexOf(column.name) !== -1
        ) {
          saveSubcontractor = column.name;
        } else if (subcontractors.indexOf(subcontractor) !== -1) {
          saveSubcontractor = subcontractor;
        }

        dispatch(
          updateOrder(
            client,
            id,
            cost_center ? cost_center.id : null,
            establishment,
            project_number,
            saveSubcontractor,
            building_address,
            lat,
            lng,
            directions,
            construction_manager_name,
            construction_manager_phone,
            certified_foreman_name,
            certified_foreman_phone,
            kutterContact ? kutterContact.id : null,
            start_date_time,
            start_meeting_time,
            start,
            end,
            description,
            {
              create: [],
              deleteType: [],
              createType: [],
              delete: [],
              update: []
            },
            notes
          )
        );
      }
      if (model.order) {
        const {
          id,
          start_date_time,
          operational_period_start,
          operational_period_finish,
          resources,
          vehicles,
          humanResources
        } = model.order;
        const startOrder = moment(
          operational_period_start ? operational_period_start : start_date_time
        );
        const endOrder = moment(operational_period_finish);
        this.setState({
          showConfirmationModal: true,
          vehicles,
          resources,
          humanResources,
          opStartDate: start,
          opFinishDate: end,
          orderStartDate: startOrder,
          orderFinishDate: endOrder,
          order: id,
          orderCheck: false,
          selectedResourceId: model.id
        });
      }
    }

    this.setState({ rsx: -1, resizeElement: null });
  }

  contextMenuActionHandler(func, args) {
    if (args) {
      return () => {
        this.closeContextMenu();
        func(...args);
      };
    }
    return () => {
      this.closeContextMenu();
      func();
    };
  }

  openContextMenu(e, obj, type, checkMultiple = true) {
    const { write, onSelectOrder } = this.props;
    const {
      elements,
      selectMode,
      selectedElements,
      resizeElement
    } = this.state;

    if (resizeElement || !write) return;

    const rect = this.node.getBoundingClientRect();
    const x = -(rect.x - e.clientX);
    const y = -(rect.y - e.clientY);

    const multipleElements = [];

    if (checkMultiple) {
      const iy = (y - (y % 41)) / 41;

      if (iy >= 0 && iy < elements.length) {
        const testElements = elements[iy].elements;
        const testConflictElements = elements[iy].conflictElements.map(elem => {
          const { order, model } = elem;
          const resources = [...order.resources, ...order.vehicles].map(
            r => r.resource
          );
          const elementsToShow = ConflictBox.getElementsToShow(resources);
          elem.view.status = `${model.name} ${elementsToShow} ${order.cost_center.building_project} (${order.cost_center.value})`;
          return elem;
        });
        const testAbsenceElements = elements[iy].absenceElements
          .flat()
          .map(elem => {
            elem.view.type = "ABSENCE_BOX";
            elem.view.status = `${elem.model.hrName} (${elem.model.type})`;
            return elem;
          });

        [
          ...testElements,
          ...testAbsenceElements,
          ...testConflictElements
        ].forEach(elem => {
          const {
            view: { offset, width }
          } = elem;
          const start = offset;
          const end = offset + width;

          if (x >= start && x <= end) {
            multipleElements.push({ ...elem, index: iy });
          }
        });
      }
    }

    const content = [];

    if (multipleElements.length < 2) {
      if (type === "WORKSHOP") return;

      const { model, view, index } = obj.props;

      let extend = false;
      if (selectedElements.length > 0) {
        if (selectedElements[0].order) extend = true;
      }

      if (type === "RESERVATION" && !extend) {
        const isNote = !!model.note;

        if (!isNote) {
          content.push({
            label: "Abruf erstellen",
            icon: faClipboardList,
            disabled: !obj.openModal,
            action: this.contextMenuActionHandler(obj.openModal)
          });
        }
        if (selectMode) {
          content.push({
            label: "Auswahl abbrechen",
            icon: faTimes,
            action: this.contextMenuActionHandler(this.setSelectMode, [false])
          });
        } else {
          if (!isNote) {
            content.push({
              label: "Maschinen auswählen",
              icon: faPlusCircle,
              action: this.contextMenuActionHandler(this.setSelectMode, [
                true,
                model
              ])
            });
          }
          content.push({
            label: "Bearbeiten",
            icon: faPen,
            action: this.contextMenuActionHandler(this.openReservationModal, [
              model
            ])
          });
          content.push({
            label: "Verschieben",
            icon: faArrowsAlt,
            disabled: Boolean(model.groupId),
            action: this.contextMenuActionHandler(this.setResizeElement, [
              model,
              view,
              index
            ])
          });
          content.push({
            label: "Löschen",
            icon: faTrashAlt,
            action: this.contextMenuActionHandler(this.deleteReservation, [
              model.id,
              model.resource.id,
              model.resource.type
            ])
          });
        }
        if (isNote) {
          content.push({
            label: "Abwesenheit erstellen",
            icon: faClipboardList,
            disabled: !obj.openModal,
            action: () => {
              window.location = `/absences?${queryString.stringify(
                {
                  new: true,
                  from: model.from,
                  to: model.to,
                  humanResources: model.resource.humanResources.map(hr =>
                    parseInt(hr.id, 10)
                  )
                },
                { arrayFormat: "index" }
              )}`;
            }
          });
        }
      } else if (type === "AT_ORDER" || extend) {
        if (selectMode) {
          content.push({
            label: "Abruf erweitern",
            icon: faCheck,
            action: this.contextMenuActionHandler(this.createOrder)
          });
          content.push({
            label: "Auswahl abbrechen",
            icon: faTimes,
            action: this.contextMenuActionHandler(this.setSelectMode, [false])
          });
        } else {
          content.push({
            label: "Abruf öffnen",
            icon: faClipboardList,
            action: inNewTab => {
              this.contextMenuActionHandler(this.openOrder, [
                model,
                inNewTab
              ])();
            }
          });
          content.push({
            label: "Maschinen anzeigen",
            icon: faEye,
            action: this.contextMenuActionHandler(onSelectOrder, [model.order])
          });
          content.push({
            label: "Maschinen hinzufügen",
            icon: faPlusCircle,
            action: this.contextMenuActionHandler(this.setSelectMode, [
              true,
              model
            ])
          });
          content.push({
            label: "Verschieben",
            icon: faArrowsAlt,
            disabled: Boolean(model.groupId),
            action: this.contextMenuActionHandler(this.setResizeElement, [
              model,
              view,
              index
            ])
          });
        }
      } else if (type === "ABSENCE_BOX") {
        content.push({
          label: obj.props.data.view.status,
          icon: faAngleRight,
          action: this.contextMenuActionHandler(this.openAbsence, [
            obj.props.data.model
          ])
        });
      } else if (type === "CONFLICT_BOX") {
        content.push({
          label: obj.props.data.view.status,
          icon: faAngleRight,
          action: this.contextMenuActionHandler(this.openOrder, [
            obj.props.data
          ])
        });
      }
    } else {
      multipleElements.forEach(elem => {
        const { model, view, index } = elem;

        switch (view.type) {
          case "RESERVATION_BOX": {
            content.push({
              label: view.status,
              icon: faAngleRight,
              action: this.contextMenuActionHandler(this.openContextMenu, [
                e,
                { ...obj, props: { model, view, index } },
                "RESERVATION",
                false
              ])
            });
            break;
          }
          case "ORDER_BOX": {
            content.push({
              label: view.status,
              icon: faAngleRight,
              action: this.contextMenuActionHandler(this.openContextMenu, [
                e,
                {
                  props: {
                    model: { ...model, order: elem.order || model.order },
                    view,
                    index
                  }
                },
                "AT_ORDER",
                false
              ])
            });
            break;
          }
          case "WORKSHOP_BOX": {
            content.push({
              label: view.status,
              icon: faAngleRight,
              action: this.contextMenuActionHandler(this.openResource)
            });
            break;
          }
          case "ABSENCE_BOX": {
            content.push({
              label: view.status,
              icon: faAngleRight,
              action: this.contextMenuActionHandler(this.openAbsence, [model])
            });
            break;
          }
          case "CONFLICT_BOX": {
            content.push({
              label: view.status,
              icon: faAngleRight,
              action: this.contextMenuActionHandler(this.openOrder, [
                elem.order
              ])
            });
            break;
          }
          default: {
            console.warn(elem);
            break;
          }
        }
      });
    }

    this.setState({
      contextMenu: {
        show: true,
        x,
        y,
        bounds: [rect.width, rect.height],
        context: { obj, type },
        content
      }
    });
  }

  closeContextMenu() {
    const { contextMenu } = this.state;

    this.setState({
      contextMenu: { ...contextMenu, show: false, context: null }
    });
  }

  openOrder({ order }, inNewTab) {
    if (order) {
      if (inNewTab) {
        window.open(`/order/${order.id}`, "_blank");
      } else {
        window.location = `/order/${order.id}`;
      }
    }
  }

  openAbsence(model) {
    window.open(`/absences/${model.id}`, "_blank");
  }

  openResource() {
    window.open("/workshop", "_blank");
  }

  setSelectMode(value, model) {
    if (value)
      if (model) this.setState({ selectMode: true, selectedElements: [model] });
      else this.setState({ selectMode: true });
    else this.setState({ selectMode: false, selectedElements: [] });
  }

  handleSelect(model, select) {
    const { selectedElements } = this.state;

    if (select)
      this.setState({ selectedElements: selectedElements.concat([model]) });
    else
      this.setState({
        selectedElements: selectedElements.filter(elem => elem.id !== model.id)
      });
  }

  handleReservationDataChange(data) {
    this.setState({ reservationData: data });
  }

  handleNoteDataChange(e) {
    const note = e.target.value;
    this.setState(state => ({
      reservationData: { ...state.reservationData, note }
    }));
  }

  openReservationModal(data = null) {
    if (data && data.note) {
      this.setState({
        reservationData: data
          ? { ...data, name: data.status.substring(4) }
          : null,
        noteData: data.note,
        showNoteModal: true
      });
    } else {
      this.setState({
        reservationData: data
          ? { ...data, name: data.status.substring(4) }
          : null,
        showReservationModal: true
      });
    }
  }

  closeReservationModal() {
    this.setState({
      reservationData: null,
      showReservationModal: false,
      showNoteModal: false
    });
  }

  handleModalOrderChange(order, orderCheck) {
    this.setState({ order, orderCheck });
  }

  handleModalChange(
    machinesToAdjust,
    vehiclesToAdjust,
    humanResourcesToAdjust,
    save
  ) {
    if (this.state.selectedResourceId) {
      this.setState({
        machinesToAdjust: machinesToAdjust.concat(this.state.selectedResourceId)
      });
    }
    this.setState(
      {
        showConfirmationModal: false,
        machinesToAdjust,
        vehiclesToAdjust,
        humanResourcesToAdjust
      },
      () => {
        if (save) {
          this.saveResourceChanges();
        }
      }
    );
  }

  saveResourceChanges() {
    const {
      machinesToAdjust,
      vehiclesToAdjust,
      humanResourcesToAdjust,
      opStartDate,
      opFinishDate,
      order,
      orderCheck
    } = this.state;
    const { client, dispatch } = this.props;

    if (machinesToAdjust.length > 0) {
      machinesToAdjust.forEach(machine => {
        if (machine) {
          dispatch(
            updateDispoMachine(
              client,
              machine,
              order,
              opStartDate,
              opFinishDate
            )
          );
        }
      });
    }

    if (vehiclesToAdjust.length > 0) {
      vehiclesToAdjust.forEach(vehicle => {
        if (vehicle) {
          dispatch(
            updateDispoVehicle(
              client,
              vehicle,
              order,
              opStartDate,
              opFinishDate
            )
          );
        }
      });
    }

    if (humanResourcesToAdjust.length > 0) {
      humanResourcesToAdjust.forEach(humanResource => {
        if (humanResource) {
          dispatch(
            updateDispoHumanResourceTime(
              client,
              humanResource,
              opStartDate,
              opFinishDate,
              "HUMAN_RESOURCE",
              order
            )
          );
        }
      });
    }

    if (orderCheck) {
      dispatch(updateOrderPeriod(client, order, opStartDate, opFinishDate));
    }
    this.setState({
      showConfirmationModal: false,
      resources: [],
      vehicles: [],
      humanResources: [],
      machinesToAdjust: [],
      vehiclesToAdjust: [],
      humanResourcesToAdjust: [],
      opStartDate: null,
      opFinishDate: null,
      order: null,
      orderCheck: false,
      selectedResourceId: null
    });
  }

  render() {
    const {
      width,
      showHours,
      backgroundPosition,
      highlight,
      costCenters,
      combinationsLength,
      showAbsences,
      showGroups
    } = this.props;
    const {
      elements,
      selection,
      resizeElement,
      contextMenu,
      selectMode,
      selectedElements,
      showReservationModal,
      showNoteModal,
      reservationData,
      showConfirmationModal,
      resources,
      vehicles,
      humanResources,
      machinesToAdjust,
      vehiclesToAdjust,
      humanResourcesToAdjust,
      opStartDate,
      opFinishDate,
      orderStartDate,
      orderFinishDate,
      order,
      orderCheck,
      selectedResourceId
    } = this.state;

    const combinationsBeginIndex = elements.length - combinationsLength;

    const highlightLeft = 120 * highlight[0] - 1;
    const highlightWidth = 120 * (highlight[1] - highlight[0] + 1) + 1;

    const reservationModalSubmitEnabled = reservationData
      ? reservationData.name !== ""
      : false;

    const extendedResources = this.props.resources.flatMap(r => {
      if (r.heightFactor) {
        const returnResources = [];
        for (let i = 0; i < r.heightFactor; i += 1) {
          returnResources.push(r);
        }

        return returnResources;
      }
      return [r];
    });

    return (
      <Fragment>
        <Modal
          show={showReservationModal}
          titleText="Maschine reservieren"
          closeBtnText="abbrechen"
          submitBtnText="speichern"
          onClose={this.closeReservationModal}
          onSubmit={this.createReservation}
          submitEnabled={reservationModalSubmitEnabled}
        >
          <ReservationData
            value={reservationData}
            onChange={this.handleReservationDataChange}
            onConfirm={this.createReservation}
            contacts={this.props.contacts}
          />
        </Modal>

        <Modal
          show={showNoteModal}
          titleText="Notiz bearbeiten"
          closeBtnText="abbrechen"
          submitBtnText="speichern"
          onClose={this.closeReservationModal}
          onSubmit={this.updateNote}
        >
          <textarea
            className="form-control"
            value={reservationData ? reservationData.note : ""}
            onChange={this.handleNoteDataChange}
          />
        </Modal>

        {showConfirmationModal && (
          <TimeModal
            resources={resources}
            vehicles={vehicles}
            humanResources={humanResources}
            machinesToAdjust={machinesToAdjust}
            vehiclesToAdjust={vehiclesToAdjust}
            humanResourcesToAdjust={humanResourcesToAdjust}
            handleModalChange={this.handleModalChange}
            handleModalOrderChange={this.handleModalOrderChange}
            opStartDate={opStartDate}
            opFinishDate={opFinishDate}
            width={"100%"}
            orderStartDate={orderStartDate}
            orderEndDate={orderFinishDate}
            order={order}
            orderCheck={orderCheck}
            selectedResourceId={selectedResourceId}
          />
        )}

        <div
          className={`gantt-content-container ${
            showHours ? "gantt-bg-hours" : "gantt-bg-days"
          }`}
          style={{ width, backgroundPosition }}
          onMouseDown={this.startSelectionDrag}
          onMouseMove={this.doSelectionDrag}
          onMouseUp={this.endSelectionDrag}
          onContextMenu={Reservations.preventEvent}
        >
          {!showHours && (
            <div
              className="gantt-content-highlight"
              style={{
                left: highlightLeft,
                width: highlightWidth
              }}
            />
          )}

          {elements.map((elem, i) => {
            const heightFactor = Math.max(
              ...elem.elements
                .map(e => e.model.resource.heightFactor || 1)
                .concat([1])
            );
            const height = heightFactor * 41;

            return (
              <div
                key={`root_${i}`}
                className={`gantt-reservation-line${
                  i >= combinationsBeginIndex
                    ? " gantt-reservation-line-dark"
                    : ""
                }${elem.groupEnd ? " gantt-reservation-line-dark-border" : ""}`}
                style={Object.assign(
                  { height },
                  showGroups ? { background: elem.color } : {}
                )}
                onMouseMove={this.doResize}
                onMouseUp={this.endResize}
              >
                {showAbsences &&
                  elem.absenceElements.map((array, j) => (
                    <Fragment key={`absence_${j}`}>
                      {array.map(obj => (
                        <AbsenceBox
                          key={obj.model.id}
                          model={obj.model}
                          view={obj.view}
                          line={i}
                          index={j}
                        />
                      ))}
                    </Fragment>
                  ))}

                {extendedResources[i] &&
                extendedResources[i].status === "Inaktiv" ? (
                  <InactiveBox
                    key={`inactive_${extendedResources[i].id}`}
                    data={extendedResources[i]}
                    start={this.props.start}
                    showHours={this.props.showHours}
                    width={width}
                  />
                ) : null}

                {elem.conflictElements.map((obj, j) => (
                  <ConflictBox
                    key={`conflict_${j}`}
                    data={obj}
                    line={i}
                    index={0}
                    onContextMenu={this.openContextMenu}
                    onClick={data =>
                      this.props.dispatch({
                        type: "SET_CONFLICTING_RESOURCES",
                        data
                      })
                    }
                  />
                ))}

                {elem.elements.map(obj => {
                  const { model, view } = obj;

                  let isValid = true;
                  if (resizeElement && resizeElement.model.id === model.id) {
                    isValid = resizeElement.valid;
                  }

                  let contactPerson = null;
                  let contactPersonCompany = null;
                  if (model.contactPersonId) {
                    contactPerson = this.props.contacts.find(
                      contact => model.contactPersonId === contact.id
                    );
                    if (contactPerson) {
                      contactPersonCompany = this.props.companies.find(
                        company => company.id === contactPerson.company_id
                      );
                    }
                  }

                  const editMode =
                    resizeElement && resizeElement.model.id === model.id;

                  const isSelected = Boolean(
                    selectedElements.find(
                      selectedElem => selectedElem.id === model.id
                    )
                  );

                  if (view.type === "ORDER_BOX") {
                    let crossOrders = [];
                    if (model.order && model.order.subcontractor) {
                      crossOrders = elem.elements.filter(subElem => {
                        if (
                          !subElem.model.order ||
                          !subElem.model.order.subcontractor
                        ) {
                          return false;
                        }

                        if (model.order) {
                          const orderFinish = new Date(
                            model.order.operational_period_finish
                          );
                          const subOrderStart = new Date(
                            subElem.model.order.operational_period_start
                          );
                          return orderFinish > subOrderStart;
                        }
                        return false;
                      });
                    }

                    let hf = model.order
                      ? crossOrders
                          .map(co => co.model.order.id)
                          .indexOf(model.order.id)
                      : -1;

                    if (hf === -1) {
                      hf = 0;
                    }

                    return (
                      <OrderBox
                        key={`order_${model.id || model.order.id}`}
                        marginTop={hf * 41}
                        model={model}
                        view={view}
                        containerWidth={width}
                        index={i}
                        editMode={editMode}
                        selectMode={selectMode}
                        isResizable={Boolean(resizeElement)}
                        isSelected={isSelected}
                        isValid={isValid}
                        onResizeUnlock={this.setResizeElement}
                        onResizeLock={this.unsetResizeElement}
                        onResizeStart={this.startResize}
                        onResizeEnd={this.endResize}
                        onContextMenu={this.openContextMenu}
                        hasEb={model.order.ebNumber !== ""}
                        hasIntern={model.order.intern}
                        hasProjectNumber={model.order.project_number !== ""}
                      />
                    );
                  }

                  return (
                    <ReservationBox
                      key={`reservation_${model.id ||
                        (model.order && model.order.id) ||
                        (model.resource && model.resource.id)}`}
                      model={model}
                      view={view}
                      containerWidth={width}
                      index={i}
                      editMode={editMode}
                      selectMode={selectMode}
                      costCenters={costCenters}
                      selectedElements={selectedElements}
                      isResizable={Boolean(resizeElement)}
                      isSelected={isSelected}
                      isValid={isValid}
                      onResizeUnlock={this.setResizeElement}
                      onResizeLock={this.unsetResizeElement}
                      onCreateOrder={this.createOrder}
                      onResizeStart={this.startResize}
                      onResizeEnd={this.endResize}
                      onDelete={this.deleteReservation}
                      onContextMenu={this.openContextMenu}
                      onSelect={this.handleSelect}
                      establishment={
                        this.props.establishment
                          ? this.props.establishment
                          : null
                      }
                      contactPerson={contactPerson}
                      contactPersonCompany={contactPersonCompany}
                    />
                  );
                })}
              </div>
            );
          })}

          {selection && (
            <SelectionBox
              resources={extendedResources}
              model={selection.model}
              view={selection.view}
              costCenters={costCenters}
              onCancel={this.cancelSelection}
              createReservation={this.openReservationModal}
              createOrder={this.createOrder}
              createNote={this.createNote}
            />
          )}

          <ContextMenu
            show={contextMenu.show}
            x={contextMenu.x}
            y={contextMenu.y}
            bounds={contextMenu.bounds}
          >
            <div className="list-group" style={{ display: "block" }}>
              {contextMenu.content.map((elem, index) => {
                if (elem.label === "Abruf öffnen") {
                  return (
                    <Fragment key={`ctx_${index}`}>
                      <button
                        type="button"
                        className="list-group-item list-group-item-action"
                        style={{ width: "75%", display: "inline-block" }}
                        onClick={() => elem.action(false)}
                        disabled={elem.disabled}
                      >
                        <FontAwesomeIcon
                          className="fa-icon-16 mr-3"
                          icon={elem.icon}
                        />
                        {elem.label}
                      </button>
                      <button
                        type="button"
                        className="list-group-item list-group-item-action"
                        style={{ width: "25%", display: "inline-block" }}
                        onClick={() => elem.action(true)}
                        disabled={elem.disabled}
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </button>
                    </Fragment>
                  );
                }
                return (
                  <button
                    key={`ctx_${index}`}
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={elem.action}
                    disabled={elem.disabled}
                  >
                    <FontAwesomeIcon
                      className="fa-icon-16 mr-3"
                      icon={elem.icon}
                    />
                    {elem.label}
                  </button>
                );
              })}
            </div>
          </ContextMenu>
        </div>
      </Fragment>
    );
  }
}

Reservations.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  history: PropTypes.object,
  width: PropTypes.number,
  resources: PropTypes.array,
  subcontractors: PropTypes.array,
  combinationsLength: PropTypes.number,
  start: PropTypes.object,
  end: PropTypes.object,
  showHours: PropTypes.bool,
  showAbsences: PropTypes.bool,
  showGroups: PropTypes.bool,
  backgroundPosition: PropTypes.string,
  highlight: PropTypes.array,
  establishment: PropTypes.object,
  costCenters: PropTypes.array,
  loading: PropTypes.bool,
  write: PropTypes.bool,
  onSelectOrder: PropTypes.func,
  contacts: PropTypes.array,
  companies: PropTypes.array
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment"),
  subcontractors: state.orders.get("subcontractors"),
  costCenters: state.costCenters.get("costCenters"),
  contacts: state.contacts.get("contacts"),
  companies: state.contacts.get("companies")
}))(Reservations);
