import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import moment from "moment";
import { debounce } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle
} from "@fortawesome/pro-solid-svg-icons";
import { faHistory } from "@fortawesome/pro-light-svg-icons";

import Select, { components } from "react-select";
import CreatableSelect from "react-select/lib/Creatable";

import { connect } from "react-redux";
import CostCenter from "../CostCenter/data";
import Map from "../CostCenter/map";
import ScopeOfService from "../ScopeOfService";
import Resources from "./resources";
import Disposal from "./disposal";
import Titlebar from "../TitleBar";
import Button from "../UnlockButton";
import createOrder from "../../actions/createOrder";
import updateOrder from "../../actions/updateOrder";
import deleteOrder from "../../actions/deleteOrder";
import createContactWithCompanyName from "../../actions/createContactWithCompanyName";

import prepareContact from "../../util/prepareContact";
import updateDispoMachine from "../../actions/updateDispoMachine";
import updateDispoVehicle from "../../actions/updateDispoVehicle";
import updateDispoHumanResourceTime from "../../actions/updateDispoHumanResourceTime";
import TimeModal from "../../util/timeModal";
import createContactWithoutHistoryPush from "../../actions/createContactWithoutHistoryPush";

const ebOptions = [
  {
    label: "EB",
    value: "EB"
  }
];
for (let i = 1; i <= 80; i += 1) {
  ebOptions.push({
    label: `EB-${i.toString()}`,
    value: `EB-${i.toString()}`
  });
}

const CustomOptionComponent = props => (
  <components.Option {...props}>
    <div className="row no-gutters">
      <div className="col-6">
        <span
          className="small"
          title={props.data.label}
          style={{
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "95%",
            whiteSpace: "nowrap"
          }}
        >
          {props.data.label}
        </span>
      </div>
      <div className="col-6 small">
        {props.data.company !== "" && (
          <span
            className="small badge badge-secondary float-right"
            title={props.data.company}
            style={{
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "95%",
              whiteSpace: "nowrap"
            }}
          >
            {props.data.company}
          </span>
        )}
      </div>
    </div>
  </components.Option>
);

const colourStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? "#e9ecef" : "white"
  }),
  option: (styles, { data }) => {
    const color = data.highlight ? "#d6d6d6" : "white";
    return {
      ...styles,
      backgroundColor: color
    };
  }
};

class Data extends Component {
  constructor(props) {
    super(props);

    // do not hide exact times in first load when establishment is not leipzig
    // WARNING: establishment CAN BE NULL if it is set to ALLE
    const { establishment } = this.props;
    let showExactTimes = true;
    if (establishment) {
      showExactTimes = establishment.value !== "Leipzig";
    }
    this.initState = {
      id: "",
      costCenter: null,
      description: "",
      projectNumber: "",
      subcontractor: null,
      scopeOfServices: [
        {
          id: `_0`,
          width: "0",
          depth: "0",
          depthTo: null,
          amount: "0",
          amountUnit: "qm",
          scopeOfServiceTyps: []
        }
      ],

      buildingAddress: "",
      lat: "",
      lng: "",
      directions: "",

      constructionManagerName: "",
      constructionManagerPhone: "",
      certifiedForemanName: "",
      certifiedForemanPhone: "",
      constructionManagerContact: null,
      certifiedForemanContact: null,
      kutterContact: null,
      kutterBooker: null,

      startDateTime: "",
      startMeetingTime: "06:30",
      operationalPeriodStart: "",
      operationalPeriodFinish: "",
      startDate: "",
      startTime: "07:00",
      opStartTime: "",
      opStartDate: "",
      opFinishTime: "",
      opFinishDate: "",
      status: 0,
      showSearchCostCenter: false,
      showSearchCostCenterInput: false,
      parentProjectFound: false,
      contacts: [],
      notes: "",
      type: 0,
      transporters: [],
      dropzones: [],
      locked: true,
      ebNumber: "",
      errors: {},
      resources: [],
      humanResources: [],
      vehicles: [],
      noMatchingCostCenter: false,
      establishment: "",
      showConfirmationModal: false,
      timePeriodChange: false,
      machinesToAdjust: [],
      vehiclesToAdjust: [],
      humanResourcesToAdjust: [],
      machinesTimesChanged: [],
      vehiclesTimesChanged: [],
      humanResourcesTimesChanged: [],
      showExactTimes: showExactTimes
    };
    this.changes = false;
    this.state = this.initState;

    this.setOrder = this.setOrder.bind(this);

    this.handleScopeOfServiceChange = this.handleScopeOfServiceChange.bind(
      this
    );

    this.handleCostCenterChange = this.handleCostCenterChange.bind(this);
    this.print = this.print.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleProjectNumberChange = this.handleProjectNumberChange.bind(this);
    this.handleSubcontractorChange = this.handleSubcontractorChange.bind(this);
    this.handleConstructionManagerContactChange = this.handleConstructionManagerContactChange.bind(
      this
    );
    this.handleCertifiedForemanContactChange = this.handleCertifiedForemanContactChange.bind(
      this
    );
    this.handleKutterContactChange = this.handleKutterContactChange.bind(this);
    this.handleKutterBookerChange = this.handleKutterBookerChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getContacts = this.getContacts.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.resetAddress = this.resetAddress.bind(this);
    this.submitRequest = debounce(this.submitRequest, 750);
    this.handleResourceChangeNoOrder = this.handleResourceChangeNoOrder.bind(
      this
    );
    this.handleEbNumberChange = this.handleEbNumberChange.bind(this);
    this.handleResourceNoteChange = this.handleResourceNoteChange.bind(this);
    this.handleResourceHumanResourceVehicleDateChange = this.handleResourceHumanResourceVehicleDateChange.bind(
      this
    );
    this.handleHumanNoteChange = this.handleHumanNoteChange.bind(this);
    this.handleVehicleNoteChange = this.handleVehicleNoteChange.bind(this);

    this.expand = this.expand.bind(this);
    this.handleModalChange = this.handleModalChange.bind(this);
    this.searchAddress = this.searchAddress.bind(this);
  }

  componentDidMount() {
    const { order } = this.props;
    document.addEventListener("keydown", this.handleKeyDown);

    if (order) {
      this.setOrder(order);
    } else {
      this.getContacts(null);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { order } = this.props;
    if (prevProps.order !== order) {
      if (order) {
        if(this.changes) {
          this.setOrderOnlyResources(order);
        } else {
          this.setOrder(order);
        }
        if (
          prevProps.order &&
          prevProps.order.id &&
          order.id &&
          prevProps.order.id !== order.id
        ) {
          this.setState({
            timePeriodChange: false,
            machinesToAdjust: [],
            vehiclesToAdjust: [],
            humanResourcesToAdjust: [],
            machinesTimesChanged: [],
            vehiclesTimesChanged: [],
            humanResourcesTimesChanged: []
          });
        }
      } else {
        this.setState(this.initState);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  setOrder(order) {
    let startDate = "";
    let startTime = "";
    let opStartDate = "";
    let opStartTime = "";
    let opFinishDate = "";
    let opFinishTime = "";
    let startMeetingTimeTime = "";
    if (order.start_date_time !== "") {
      const startDateTime = moment.utc(order.start_date_time);
      startDate = startDateTime.format("YYYY-MM-DD");
      startTime = startDateTime.format("HH:mm");
    }
    if (order.operational_period_start !== "") {
      const operationalPeriodStart = moment.utc(order.operational_period_start);
      opStartDate = operationalPeriodStart.format("YYYY-MM-DD");
      opStartTime =
        this.state.opStartTime || operationalPeriodStart.format("HH:mm");
    }
    if (order.operational_period_finish !== "") {
      const operationalPeriodFinish = moment.utc(
        order.operational_period_finish
      );
      opFinishDate = operationalPeriodFinish.format("YYYY-MM-DD");
      opFinishTime =
        this.state.opFinishTime || operationalPeriodFinish.format("HH:mm");
    }
    if (order.start_meeting_time !== "") {
      const startMeetingTime = moment.utc(order.start_meeting_time);
      startMeetingTimeTime = startMeetingTime.format("HH:mm");
    }

    if (order.cost_center && order.cost_center.customer) {
      this.getContacts(order.cost_center.customer);
    } else {
      this.getContacts(null);
    }

    this.setState({
      id: order.id,
      establishment: order.establishment,
      costCenter: order.cost_center,
      noMatchingCostCenter: !order.cost_center,
      projectNumber: order.project_number || "",
      subcontractor: order.subcontractor || null,
      description: order.description,
      scopeOfServices: order.scopeOfServices,
      buildingAddress: order.building_address,
      lat: order.lat,
      lng: order.lng,
      directions: order.directions,
      constructionManagerName: order.construction_manager_name || "",
      constructionManagerPhone: order.construction_manager_phone || "",
      certifiedForemanName: order.certified_foreman_name || "",
      certifiedForemanPhone: order.certified_foreman_phone || "",
      constructionManagerContact: order.construction_manager_name
        ? {
            value: order.construction_manager_name,
            label: order.construction_manager_name
          }
        : null,
      certifiedForemanContact: order.certified_foreman_name
        ? {
            value: order.certified_foreman_name,
            label: order.certified_foreman_name
          }
        : null,
      kutterContact: order.kutterContact,
      kutterBooker: order.kutterBooker,

      startDateTime: order.start_date_time,
      startMeetingTime:
        startMeetingTimeTime !== ""
          ? startMeetingTimeTime
          : order.start_meeting_time,
      operationalPeriodStart: order.operational_period_start,
      operationalPeriodFinish: order.operational_period_finish,
      startDate,
      startTime,
      opStartDate,
      opStartTime,
      opFinishDate,
      opFinishTime,
      status: order.status,
      notes: order.notes,
      type: order.type,
      transporters: order.transporters,
      dropzones: order.dropzones,
      ebNumber: order.ebNumber,
      errors: {},
      humanResources: order.humanResources,
      resources: order.resources,
      vehicles: order.vehicles
    });
  }

  setOrderOnlyResources(order) {
    this.setState({
      humanResources: order.humanResources,
      resources: order.resources,
      vehicles: order.vehicles
    });
  }

  handleInputChange(e) {
    this.changes = true;
    const { lat } = this.state;
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({ [name]: value });
    if (name === "buildingAddress" && lat === "") this.submitRequest(value);
  }

  searchAddress() {
    this.setState({ lat: "", lng: "" });
  }

  submitRequest(address) {
    this.setState({ buildingAddress: address });
  }

  handleAddressChange(lat, lng) {
    this.changes = true;
    this.setState({ lat: lat.toString(), lng: lng.toString() });
  }

  resetAddress() {
    this.setState({ buildingAddress: "", lat: "", lng: "" });
  }

  getContacts(customer) {
    this.props.client
      .query({
        query: gql`
          query getContacts($id: ID) {
            getContacts(id: $id) {
              id
              lastname
              firstname
              position
              mobile
              company {
                id
                name
              }
            }
          }
        `,
        variables: {
          id: ""
        }
      })
      .then(response => {
        if (response.data.getContacts) {
          const contactOptions = response.data.getContacts.map(contact => {
            return {
              value: contact.id,
              label: `${contact.lastname} ${contact.firstname}`,
              mobile: contact.mobile,
              company: contact.company ? contact.company.name : "",
              highlight:
                contact.company &&
                customer &&
                contact.company.name &&
                customer.includes(contact.company.name)
            };
          });

          if (customer) {
            contactOptions.sort((a, b) => {
              if (
                customer.includes(a.company) &&
                a.company !== "" &&
                customer.includes(b.company) &&
                b.company !== ""
              ) {
                return 0;
              }
              if (customer.includes(a.company) && a.company !== "") {
                return -1;
              }
              if (customer.includes(b.company) && b.company !== "") {
                return 1;
              }
              return 0;
            });
          }

          this.setState({ contacts: contactOptions });
        }
      });
  }

  handleCostCenterChange(type, value) {
    this.changes = true;
    if (type === "costCenter") {
      if (value && value.customer) {
        this.getContacts(value.customer);
      } else {
        this.getContacts(null);
      }

      this.setState({
        costCenter: value
      });
    } else if (type === "noMatchingCostCenter") {
      this.setState({
        noMatchingCostCenter: value
      });
    } else if (type === "establishment") {
      this.setState({
        establishment: value.value
      });
      if (value.value === "Leipzig" && this.state.type === 3)
        this.setState({ type: 0 });
      if (value.value === "Duben" && this.state.type === 0)
        this.setState({ type: 3 });
    }
  }

  changeStartDate(e) {
    this.setState({ startDate: e.target.value });
  }

  changeStartTime(e) {
    this.setState({ startTime: e.target.value });
  }

  changeMeetingTime(e) {
    this.setState({ startMeetingTime: e.target.value });
  }

  changeOPStartDate(e) {
    this.setState({
      opStartDate: e.target.value,
      timePeriodChange:
        this.state.vehicles.length ||
        this.state.resources.length ||
        this.state.humanResources.length
    });
  }

  changeOPFinishDate(e) {
    this.setState({
      opFinishDate: e.target.value,
      timePeriodChange:
        this.state.vehicles.length ||
        this.state.resources.length ||
        this.state.humanResources.length
    });
  }

  modifyTimes(time, isBeginTime) {
    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];
    const resources = this.state.resources;
    const humanResources = this.state.humanResources;
    const vehicles = this.state.vehicles;

    resources.forEach(r => {
      const dateUTC = new Date(r.from);
      dateUTC.setHours(parseInt(hours));
      dateUTC.setMinutes(parseInt(minutes));
      if (isBeginTime) {
        r.from = dateUTC;
      } else {
        r.to = dateUTC;
      }
    });

    humanResources.forEach(hr => {
      const dateUTC = new Date(hr.from);
      dateUTC.setHours(parseInt(hours));
      dateUTC.setMinutes(parseInt(minutes));
      if (isBeginTime) {
        hr.from = dateUTC;
      } else {
        hr.to = dateUTC;
      }
    });

    vehicles.forEach(v => {
      const dateUTC = new Date(v.from);
      dateUTC.setHours(parseInt(hours));
      dateUTC.setMinutes(parseInt(minutes));
      if (isBeginTime) {
        v.from = dateUTC;
      } else {
        v.to = dateUTC;
      }
    });

    this.setState({ resources: Array.from(resources) });
    this.setState({ humanResources: Array.from(humanResources) });
    this.setState({ vehicles: Array.from(vehicles) });
  }

  changeOpStartTime(e) {
    const newOpStartTime = e.target.value;
    this.setState({
      opStartTime: newOpStartTime
    });
    // this.modifyTimes(newOpStartTime, true);
  }

  changeOpFinishTime(e) {
    const newOpFinishTime = e.target.value;
    this.setState({
      opFinishTime: newOpFinishTime
    });
    // this.modifyTimes(newOpFinishTime, false);
  }

  handleScopeOfServiceChange(scopeOfServices) {
    this.changes = true;
    this.setState({ scopeOfServices });
  }

  unlock() {
    const { order, mp } = this.props;
    this.setState({
      locked: false
    });
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Edit",
        ...(order && { "Object Id": order.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
  }

  lock(preventReset) {
    const { order } = this.props;
    if (order && !preventReset) {
      this.setOrder(order);
    }
    this.setState({
      locked: !preventReset,
      timePeriodChange: false,
      machinesToAdjust: [],
      vehiclesToAdjust: [],
      humanResourcesToAdjust: [],
      machinesTimesChanged: [],
      vehiclesTimesChanged: [],
      humanResourcesTimesChanged: []
    });
  }

  handleHumanNoteChange(e, reservationId, humanResourceId) {
    if (e.target.value !== null) {
      const humanResourceUpdate = this.state.humanResources.map(hr => {
        if (
          hr.id === reservationId ||
          hr.humanResource.id === humanResourceId
        ) {
          hr.note = e.target.value;
          return hr;
        }
        return hr;
      });
      const { humanResourcesTimesChanged } = this.state;
      this.setState({
        humanResources: humanResourceUpdate,
        humanResourcesTimesChanged: humanResourcesTimesChanged.concat(
          reservationId
        )
      });
    }
  }

  handleResourceNoteChange(e, reservationId, resourceId) {
    if (e.target.value !== null) {
      const resourceUpdate = this.state.resources.map(r => {
        if (r.id === reservationId || r.resource.id === resourceId) {
          r.note = e.target.value;
          return r;
        }
        return r;
      });
      const { machinesTimesChanged } = this.state;
      this.setState({
        resources: resourceUpdate,
        machinesTimesChanged: machinesTimesChanged.concat(reservationId)
      });
    }
  }

  handleVehicleNoteChange(e, reservationId, vehicleId) {
    if (e.target.value !== null) {
      const vehiclesUpdate = this.state.vehicles.map(v => {
        if (v.id === reservationId || vehicleId === v.resource.id) {
          v.note = e.target.value;
          return v;
        }
        return v;
      });
      const { vehiclesTimesChanged } = this.state;
      this.setState({
        vehicles: vehiclesUpdate,
        vehiclesTimesChanged: vehiclesTimesChanged.concat(reservationId)
      });
    }
  }

  handleResourceHumanResourceVehicleDateChange(date, instance, type) {
    if (date) {
      this.changes = true;
      const start = moment(date[0]);
      const end = moment(date[1]);
      switch (type) {
        case "machine":
          const newArrM = this.state.machinesTimesChanged;
          const MachineUpdate = this.state.resources.map(r => {
            if (
              (r.id === instance.id && r.id && instance.id) ||
              r.resource.id === instance.resource.id
            ) {
              r.from = start;
              r.to = end;
              if (!newArrM.includes(instance.id)) {
                newArrM.push(instance.id);
              }
              return r;
            }
            return r;
          });
          this.setState({
            resources: MachineUpdate,
            machinesTimesChanged: newArrM
          });
          break;
        case "vehicle":
          const newArrV = this.state.vehiclesTimesChanged;
          const vehicleUpdate = this.state.vehicles.map(v => {
            if (
              (v.id === instance.id && v.id && instance.id) ||
              v.resource.id === instance.resource.id
            ) {
              v.from = start;
              v.to = end;
              if (!newArrV.includes(instance.id)) {
                newArrV.push(instance.id);
              }
              return v;
            }
            return v;
          });
          this.setState({
            vehicles: vehicleUpdate,
            vehiclesTimesChanged: newArrV
          });
          break;
        case "staff":
          const newArrH = this.state.humanResourcesTimesChanged;
          const staffUpdate = this.state.humanResources.map(hr => {
            if (hr.id === instance.resourceId && hr.id && instance.resourceId) {
              hr.from = start;
              hr.to = end;
              if (!newArrH.includes(instance.resourceId)) {
                newArrH.push(instance.resourceId);
              }
              return hr;
            }
            return hr;
          });
          this.setState({
            humanResources: staffUpdate,
            humanResourcesTimesChanged: newArrH
          });
          break;
        default:
          break;
      }
    }
  }

  handleModalChange(
    machinesToAdjust,
    vehiclesToAdjust,
    humanResourcesToAdjust,
    save
  ) {
    this.changes = true;
    this.setState(
      {
        showConfirmationModal: false,
        machinesToAdjust,
        vehiclesToAdjust,
        humanResourcesToAdjust
      },
      () => {
        if (save) {
          this.onSave();
        }
      }
    );
  }

  checkOnSave() {
    if (this.state.timePeriodChange) {
      this.setState({ showConfirmationModal: true });
    } else {
      this.onSave();
    }
  }

  onSave() {
    if (!this.validateForm()) {
      return;
    }

    const { client, dispatch, order, mp } = this.props;
    const { scopeOfServices, description, establishment } = this.state;
    const { costCenter } = this.state;
    const costCenterId = costCenter ? costCenter.id : null;

    let {
      startMeetingTime,
      startTime,
      startDate,
      opStartTime,
      opStartDate,
      opFinishTime,
      opFinishDate
    } = this.state;
    const {
      projectNumber,
      subcontractor,
      buildingAddress,
      lat,
      lng,
      directions,
      constructionManagerPhone,
      certifiedForemanPhone,
      constructionManagerContact,
      certifiedForemanContact,
      kutterContact,
      kutterBooker,
      notes,
      ebNumber,
      type,
      resources,
      humanResources,
      vehicles,
      machinesToAdjust,
      vehiclesToAdjust,
      humanResourcesToAdjust,
      machinesTimesChanged,
      vehiclesTimesChanged,
      humanResourcesTimesChanged
    } = this.state;

    const constructionManagerName = constructionManagerContact
      ? constructionManagerContact.label
      : "";
    const certifiedForemanName = certifiedForemanContact
      ? certifiedForemanContact.label
      : "";

    const kutterContactId = kutterContact ? kutterContact.id : null;
    const kutterBookerId = kutterBooker ? kutterBooker.id : null;

    startMeetingTime = startMeetingTime || "00:00";
    startTime = startTime || "00:00";
    opStartTime = opStartTime || "04:00";
    opFinishTime = opFinishTime || "18:00";
    startDate =
      startDate ||
      moment
        .utc()
        .utcOffset("+0100")
        .format("YYYY-MM-DD HH:mm:ss");
    opStartDate =
      opStartDate ||
      moment
        .utc()
        .utcOffset("+0100")
        .format("YYYY-MM-DD");
    opFinishDate =
      opFinishDate ||
      moment
        .utc()
        .utcOffset("+0100")
        .format("YYYY-MM-DD");

    let startDateTime =
      startDate.length > 10 ? startDate : `${startDate} ${startTime}`;

    if (
      startTime === "Invalid date" ||
      startTime === "" ||
      startTime === null
    ) {
      startDateTime = `${startDate} 12:00`;
    }
    if (startDate === "Invalid date") {
      startDateTime = startTime;
    }

    let operationalPeriodStart =
      opStartDate.length > 10 ? opStartDate : `${opStartDate} ${opStartTime}`;
    if (
      opStartTime === "Invalid date" ||
      opStartTime === "" ||
      opStartTime === null
    ) {
      operationalPeriodStart = `${opStartDate} 12:00`;
    }

    let operationalPeriodFinish = `${opFinishDate.slice(
      0,
      10
    )} ${opFinishTime}`;

    if (
      opFinishTime === "Invalid date" ||
      opFinishTime === "" ||
      opFinishTime === null
    ) {
      operationalPeriodFinish = `${opFinishDate} 12:00`;
    }

    if (opFinishDate === "Invalid date") {
      operationalPeriodFinish = opFinishTime;
    }

    if (startMeetingTime !== "") {
      startMeetingTime = `${moment
        .utc()
        .format("YYYY-MM-DD")} ${startMeetingTime}`;
    }

    if (order) {
      // Diff the old and new scope of services.
      const oldSOS = order.scopeOfServices;
      const sosChanges = {
        create: [],
        update: [],
        delete: [],
        createType: [],
        deleteType: []
      };
      scopeOfServices.forEach(sos => {
        const osos = oldSOS.find(elem => elem.id === sos.id);
        if (osos) {
          // Found a match.
          if (sos !== osos) sosChanges.update.push(sos);
          // Compare types.
          const types = sos.scopeOfServiceTyps;
          const oldTypes = osos.scopeOfServiceTyps;

          types.forEach(t => {
            if (t.id === "new") {
              sosChanges.createType.push([sos.id, t]);
            }
          });

          oldTypes.forEach(t => {
            if (!types.find(elem => elem.id === t.id)) {
              sosChanges.deleteType.push(t);
            }
          });
        } else {
          // This is a new scope of service.
          sosChanges.create.push(sos);
        }
      });

      oldSOS.forEach(sos => {
        if (!scopeOfServices.find(elem => elem.id === sos.id))
          sosChanges.delete.push(sos);
      });

      if (machinesToAdjust.length > 0) {
        machinesToAdjust.forEach(machine => {
          if (machine) {
            dispatch(
              updateDispoMachine(
                client,
                machine,
                order.id,
                operationalPeriodStart,
                operationalPeriodFinish
              )
            );
          }
        });
      }

      if (vehiclesToAdjust.length > 0) {
        vehiclesToAdjust.forEach(vehicle => {
          if (vehicle) {
            dispatch(
              updateDispoVehicle(
                client,
                vehicle,
                order.id,
                operationalPeriodStart,
                operationalPeriodFinish
              )
            );
          }
        });
      }

      if (humanResourcesToAdjust.length > 0) {
        humanResourcesToAdjust.forEach(humanResource => {
          if (humanResource) {
            dispatch(
              updateDispoHumanResourceTime(
                client,
                humanResource,
                operationalPeriodStart,
                operationalPeriodFinish,
                "HUMAN_RESOURCE",
                order.id
              )
            );
          }
        });
      }

      if (machinesTimesChanged.length > 0) {
        machinesTimesChanged.forEach(machine => {
          const machineInstance = resources.find(r => r.id === machine);
          if (machine && machineInstance) {
            dispatch(
              updateDispoMachine(
                client,
                machine,
                order.id,
                machineInstance.from,
                machineInstance.to,
                machineInstance.note
              )
            );
          }
        });
      }

      if (vehiclesTimesChanged.length > 0) {
        vehiclesTimesChanged.forEach(vehicle => {
          const vehicleInstance = vehicles.find(v => v.id === vehicle);
          if (vehicle && vehicleInstance) {
            dispatch(
              updateDispoVehicle(
                client,
                vehicle,
                order.id,
                vehicleInstance.from,
                vehicleInstance.to,
                vehicleInstance.note
              )
            );
          }
        });
      }

      if (humanResourcesTimesChanged.length > 0) {
        humanResourcesTimesChanged.forEach(humanResource => {
          const humanResourceInstance = humanResources.find(
            hr => hr.id === humanResource
          );
          if (humanResource && humanResourceInstance) {
            dispatch(
              updateDispoHumanResourceTime(
                client,
                humanResource,
                humanResourceInstance.from,
                humanResourceInstance.to,
                "HUMAN_RESOURCE",
                order.id,
                humanResourceInstance.note
              )
            );
          }
        });
      }

      dispatch(
        updateOrder(
          client,
          order.id,
          costCenterId,
          establishment,
          projectNumber,
          subcontractor,
          buildingAddress,
          lat,
          lng,
          directions,
          constructionManagerName,
          constructionManagerPhone,
          certifiedForemanName,
          certifiedForemanPhone,
          kutterContactId,
          kutterBookerId,
          startDateTime,
          startMeetingTime,
          operationalPeriodStart,
          operationalPeriodFinish,
          description,
          sosChanges,
          notes,
          ebNumber,
          type,
          machinesToAdjust,
          vehiclesToAdjust,
          humanResourcesToAdjust,
          resources,
          humanResources,
          vehicles
        )
      );
    } else {
      dispatch(
        createOrder(
          client,
          costCenterId,
          establishment,
          projectNumber,
          subcontractor,
          scopeOfServices,
          buildingAddress,
          lat,
          lng,
          directions,
          constructionManagerName,
          constructionManagerPhone,
          certifiedForemanName,
          certifiedForemanPhone,
          kutterContactId,
          kutterBookerId,
          startDateTime,
          startMeetingTime,
          operationalPeriodStart,
          operationalPeriodFinish,
          description,
          notes,
          resources,
          humanResources,
          vehicles
        )
      );
      if (mp && mp.isInit && mp._mp) {
        const track_obj_param = {
          Screen: document.title,
          type: "Save",
          ...(order && { "Object Id": order.id }),
          "Tracking Time": new Date().toISOString()
        };
        mp._mp.track("Action", track_obj_param, () =>
          console.log("tracked", track_obj_param)
        );
      }
    }

    const contacts = [];

    // eslint-disable-next-line
    if (constructionManagerContact && constructionManagerContact.__isNew__) {
      contacts.push(
        prepareContact({
          name: constructionManagerContact.label,
          position: "Bauleitung",
          phone: constructionManagerPhone
        })
      );
    }

    // eslint-disable-next-line
    if (certifiedForemanContact && certifiedForemanContact.__isNew__) {
      contacts.push(
        prepareContact({
          name: certifiedForemanContact.label,
          position: "Polier",
          phone: certifiedForemanPhone
        })
      );
    }

    if (costCenter) {
      contacts.forEach(contact =>
        dispatch(
          createContactWithCompanyName(
            client,
            contact.salutation,
            contact.lastname,
            contact.firstname,
            contact.phone,
            contact.position,
            contact.mobile,
            contact.email,
            contact.fax,
            costCenter.customer
          )
        )
      );
    }

    this.lock(false);
    this.changes = false;
  }

  onDelete() {
    const { client, order, mp } = this.props;
    this.props.dispatch(deleteOrder(client, order.id));
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "delete",
        ...(order && { "Object Id": order.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
    this.lock(true);
  }

  print() {
    const { order, mp } = this.props;
    if (order) {
      window.location = `/order/${order.id}/print`;
    }
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Print",
        ...(order && { "Object Id": order.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
  }

  handleKeyDown(e) {
    if (e.ctrlKey && e.code === "KeyP") {
      e.preventDefault();
      this.print();
    }
  }

  handleTypeChange(value, actionMeta) {
    switch (actionMeta.action) {
      case "select-option": {
        this.changes = true;
        this.setState({ type: value.value });
        break;
      }
      default:
        console.warn(value, actionMeta);
        break;
    }
  }

  handleProjectNumberChange(value, actionMeta) {
    switch (actionMeta.action) {
      case "select-option": {
        this.changes = true;
        this.setState({ projectNumber: value.value });
        break;
      }
      case "create-option": {
        this.changes = true;
        this.setState({ projectNumber: value.value });
        break;
      }
      case "clear": {
        this.changes = true;
        this.setState({ projectNumber: "", ebNumber: "" });
        break;
      }
      default:
        console.warn(value, actionMeta);
        break;
    }
  }

  handleSubcontractorChange(value, actionMeta) {
    switch (actionMeta.action) {
      case "select-option": {
        this.changes = true;
        this.setState({ subcontractor: value.value });
        break;
      }
      case "create-option": {
        this.changes = true;
        this.setState({ subcontractor: value.value });
        break;
      }
      case "clear": {
        this.changes = true;
        this.setState({ subcontractor: null });
        break;
      }
      default:
        console.warn(value, actionMeta);
        break;
    }
  }

  handleConstructionManagerContactChange(value, actionMeta) {
    this.changes = true;
    const { contacts } = this.state;
    const { client, dispatch } = this.props;
    if (actionMeta.action === "create-option") {
      value.phone = "";
      this.setState({
        contacts: [...contacts, value],
        constructionManagerName: value.value,
        constructionManagerPhone: "",
        constructionManagerContact: value
      });
      // save contact
      const nameArr = value.value.slice(" ");
      dispatch(
        createContactWithoutHistoryPush(
          client,
          "",
          nameArr[0],
          nameArr.length > 1 ? nameArr[1] : "",
          "",
          "",
          "",
          "",
          "",
          ""
        )
      );
    } else if (value) {
      this.setState({
        constructionManagerPhone: value.mobile,
        constructionManagerName: value.label,
        constructionManagerContact: value
      });
    }
  }

  handleCertifiedForemanContactChange(value, actionMeta) {
    this.changes = true;
    const { contacts } = this.state;

    if (actionMeta.action === "create-option")
      this.setState({ contacts: [...contacts, value] });
    else if (value && value.mobile) {
      this.setState({ certifiedForemanPhone: value.mobile });
    }

    this.setState({ certifiedForemanContact: value });
  }

  handleEbNumberChange(e) {
    this.changes = true;
    this.setState({ ebNumber: e.value });
  }

  handleKutterContactChange(value) {
    this.changes = true;
    this.setState({ kutterContact: value });
  }

  handleKutterBookerChange(value) {
    this.changes = true;
    this.setState({ kutterBooker: value });
  }

  handleResourceChangeNoOrder(type, resource, humanResources) {
    switch (type) {
      case "select": {
        this.changes = true;
        const additionalSelectedResources = [];
        const additionalSelectedVehicles = [];
        if (resource) {
          resource.forEach(r => {
            const { id } = r;
            const isResource = id.charAt(0) === "R";
            // eslint-disable-next-line no-param-reassign
            r.id = id.substring(1);
            if (isResource) {
              additionalSelectedResources.push({ resource: r });
            } else {
              const numberSplit = r.number.split("/");
              additionalSelectedVehicles.push({
                resource: {
                  ...r,
                  brand: r.name,
                  type: numberSplit[0] ? numberSplit[0].trim() : "",
                  licensePlate: numberSplit[1] ? numberSplit[1].trim() : ""
                }
              });
            }
          });
        }

        const additionalSelectedHumanResources = humanResources.map(hr => ({
          humanResource: hr,
          name: hr.label
        }));

        this.setState(state => ({
          resources: [...state.resources, ...additionalSelectedResources],
          humanResources: [
            ...state.humanResources,
            ...additionalSelectedHumanResources
          ],
          vehicles: [...state.vehicles, ...additionalSelectedVehicles]
        }));
        break;
      }
      case "list": {
        this.changes = true;
        const id = resource;
        let {
          selectedResources,
          selectedHumanResources,
          selectedVehicles
        } = this.state;

        switch (humanResources) {
          case "RESOURCE": {
            selectedResources = selectedResources.filter(
              r => r.resource.id !== id
            );
            break;
          }
          case "VEHICLE": {
            selectedVehicles = selectedVehicles.filter(
              v => v.resource.id !== id
            );
            break;
          }
          case "HUMAN_RESOURCE": {
            selectedHumanResources = selectedHumanResources.filter(
              hr => hr.humanResource.id !== id
            );
            break;
          }
          default: {
            break;
          }
        }
        this.setState({
          selectedResources,
          selectedHumanResources,
          selectedVehicles
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  expand() {
    const { expanded, onExpand } = this.props;
    onExpand(expanded ? "LIST_DETAIL" : "DETAIL_DISPO");
  }

  validateForm() {
    const phoneRegex = /^\d+$/i;
    const {
      errors,
      constructionManagerContact,
      certifiedForemanContact,
      constructionManagerPhone,
      certifiedForemanPhone,
      noMatchingCostCenter,
      establishment,
      costCenter
    } = this.state;

    if (constructionManagerContact || certifiedForemanContact) {
      errors.phone = !(
        constructionManagerPhone.match(phoneRegex) ||
        certifiedForemanPhone.match(phoneRegex)
      );
    }

    if (noMatchingCostCenter) {
      errors.establishment = !establishment;
      errors.costCenter = undefined;
    } else {
      errors.costCenter = !costCenter;
    }

    let valid = true;
    Object.keys(errors).forEach(field => {
      valid = valid && !errors[field];
    });

    this.setState({ errors });
    return valid;
  }

  render() {
    const {
      footerClassName,
      expanded,
      order,
      costCenters,
      users,
      write,
      view,
      subcontractors
    } = this.props;
    const {
      establishment,
      costCenter,
      projectNumber,
      subcontractor,
      scopeOfServices,
      buildingAddress,
      lat,
      lng,
      directions,
      constructionManagerContact,
      constructionManagerPhone,
      certifiedForemanContact,
      certifiedForemanPhone,
      kutterContact,
      kutterBooker,
      startMeetingTime,
      startDate,
      startTime,
      opStartDate,
      opFinishDate,
      status,
      contacts,
      notes,
      transporters,
      dropzones,
      noMatchingCostCenter,
      description,
      resources,
      vehicles,
      humanResources,
      selectedResources,
      selectedHumanResources,
      selectedVehicles,
      locked,
      errors,
      showConfirmationModal,
      machinesToAdjust,
      vehiclesToAdjust,
      humanResourcesToAdjust,
      opStartTime,
      opFinishTime,
      showExactTimes,
    } = this.state;

    let showUnlockButtons = this.props.write;
    if (view === "disposal") {
      showUnlockButtons = this.props.disposalWrite;
    }

    const typeOptions = [];

    if (this.state.establishment !== "Leipzig")
      typeOptions.push({ label: "Kutter Duben", value: 3 });

    if (this.state.establishment !== "Duben")
      typeOptions.push({ label: "Kutter LE", value: 0 });

    typeOptions.push({ label: "von", value: 1 }, { label: "an", value: 2 });

    return (
      <Fragment>
        {showConfirmationModal && (
          <TimeModal
            resources={resources}
            vehicles={vehicles}
            humanResources={humanResources}
            machinesToAdjust={machinesToAdjust}
            vehiclesToAdjust={vehiclesToAdjust}
            humanResourcesToAdjust={humanResourcesToAdjust}
            opStartDate={opStartDate}
            opFinishDate={opFinishDate}
            handleModalChange={this.handleModalChange}
            width={"50%"}
          />
        )}
        {view === "data" ? (
          <div style={{ padding: "0 20px", position: "relative" }}>
            <div className="row">
              {errors.costCenter !== undefined ? (
                <div className="col-12">
                  <div className="alert alert-warning">
                    <FontAwesomeIcon
                      className="mr-3"
                      icon={faExclamationTriangle}
                    />
                    Bitte wählen Sie eine Kostenstelle aus oder wählen Sie
                    "keine passende Kostenstelle" aus.
                  </div>
                </div>
              ) : null}
              {errors.establishment !== undefined ? (
                <div className="col-12">
                  <div className="alert alert-warning">
                    <FontAwesomeIcon
                      className="mr-3"
                      icon={faExclamationTriangle}
                    />
                    Bitte wählen Sie eine Niederlassung aus.
                  </div>
                </div>
              ) : null}
            </div>

            {noMatchingCostCenter ? (
              <div className="row">
                <div className="col-12 form-group">
                  <label className="form-label small">Beschreibung</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    value={description}
                    onChange={this.handleInputChange}
                    disabled={locked}
                  />
                </div>
              </div>
            ) : null}
            <CostCenter
              establishment={establishment}
              costCenter={costCenter}
              noMatchingCostCenter={noMatchingCostCenter}
              costCenters={costCenters}
              users={users}
              write={write}
              createMode={false}
              disabled={locked}
              showMapOverlay={false}
              onChange={this.handleCostCenterChange}
              showButton={false}
              hideSpacer={true}
            />

            <div className="row">
              <div className="col-2 form-group">
                <label className="form-check-label small">&nbsp;</label>
                <br />
                <Select
                  value={typeOptions.find(o => o.value === this.state.type)}
                  options={typeOptions}
                  isDisabled={locked}
                  onChange={this.handleTypeChange}
                />
              </div>
              <div className="form-group col-3">
                <label className="form-check-label small">Projektnummer</label>
                <CreatableSelect
                  isClearable
                  isDisabled={locked}
                  placeholder=""
                  noOptionsMessage={() => "Projektnummer Eingeben"}
                  noResultsMessage={() => "Keine Übereinstimmung"}
                  value={{ label: projectNumber, value: projectNumber }}
                  options={
                    costCenter && costCenter.projectNumbers
                      ? costCenter.projectNumbers.map(number => ({
                          label: number,
                          value: number
                        }))
                      : []
                  }
                  formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
                  onChange={this.handleProjectNumberChange}
                />
              </div>
              <div className="col-2 form-group">
                <label className="form-check-label small">EB</label>
                <Select
                  isDisabled={locked || this.state.projectNumber === ""}
                  placeholder=""
                  value={{
                    label: this.state.ebNumber,
                    value: this.state.ebNumber
                  }}
                  options={ebOptions}
                  onChange={this.handleEbNumberChange}
                />
              </div>
              {this.state.type > 0 && this.state.type < 3 ? (
                <div className="col-5 form-group">
                  <label className="form-check-label small">
                    {this.state.type === 1 ? "Partner" : "Sub"}unternehmen
                  </label>
                  <CreatableSelect
                    isClearable
                    isDisabled={locked}
                    placeholder=""
                    noResultsMessage={() => "Keine Übereinstimmung"}
                    value={{ label: subcontractor, value: subcontractor }}
                    options={subcontractors.map(value => ({
                      label: value,
                      value
                    }))}
                    formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
                    onChange={this.handleSubcontractorChange}
                  />
                </div>
              ) : null}
              <div className="col-sm-6">
                <h4>Bauleitung des AG</h4>
                <div className="row">
                  <div className="form-group col-md-7">
                    <label
                      className="form-check-label small"
                      htmlFor="constructionManagerName"
                    >
                      Bauleiter
                    </label>
                    <CreatableSelect
                      isClearable
                      isDisabled={locked}
                      placeholder=""
                      noOptionsMessage={() => "Name Eingeben"}
                      noResultsMessage={() => "Keine Übereinstimmung"}
                      value={constructionManagerContact}
                      options={contacts}
                      formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
                      onChange={this.handleConstructionManagerContactChange}
                      components={{ Option: CustomOptionComponent }}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="form-group col-sm-5">
                    <label
                      className="form-check-label small"
                      htmlFor="constructionManagerPhone"
                    >
                      Tel.
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.constructionManagerPhone ? "is-invalid" : ""
                      }`}
                      id="constructionManagerPhone"
                      name="constructionManagerPhone"
                      value={constructionManagerPhone}
                      disabled={locked}
                      onChange={this.handleInputChange}
                    />
                    {errors.constructionManagerPhone ? (
                      <small className="form-text text-danger">
                        Darf nur Zahlen enthalten.
                      </small>
                    ) : null}
                  </div>

                  <div className="form-group col-sm-7">
                    <label
                      className="form-check-label small"
                      htmlFor="certifiedForemanName"
                    >
                      Polier
                    </label>
                    <CreatableSelect
                      isClearable
                      isDisabled={locked}
                      placeholder=""
                      noOptionsMessage={() => "Name Eingeben"}
                      noResultsMessage={() => "Keine Übereinstimmung"}
                      value={certifiedForemanContact}
                      options={contacts}
                      formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
                      onChange={this.handleCertifiedForemanContactChange}
                    />
                  </div>
                  <div className="form-group col-sm-5">
                    <label
                      className="form-check-label small"
                      htmlFor="certifiedForemanPhone"
                    >
                      Tel.
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.certifiedForemanPhone ? "is-invalid" : ""
                      }`}
                      id="certifiedForemanPhone"
                      name="certifiedForemanPhone"
                      value={certifiedForemanPhone}
                      disabled={locked}
                      onChange={this.handleInputChange}
                    />
                    {errors.certifiedForemanPhone ? (
                      <small className="form-text text-danger">
                        Darf nur Zahlen enthalten.
                      </small>
                    ) : null}
                  </div>
                  {errors.phone ? (
                    <small className="form-text text-danger pl-3">
                      Bitte mindestens eine Telefonnummer angeben.
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <h4>Ansprechpartner Kutter</h4>
                <div className="row">
                  <div className="form-group col-md-7">
                    <label
                      className="form-check-label small"
                      htmlFor="constructionManagerName"
                    >
                      Bauleiter
                    </label>
                    <Select
                      isClearable
                      isDisabled={locked}
                      placeholder=""
                      noOptionsMessage={() => "Name Eingeben"}
                      noResultsMessage={() => "Keine Übereinstimmung"}
                      getOptionValue={opt => opt.id}
                      getOptionLabel={opt => `${opt.firstname} ${opt.lastname}`}
                      value={kutterContact}
                      options={users.filter(
                        user => user.position === "Bauleiter"
                      )}
                      onChange={this.handleKutterContactChange}
                    />
                  </div>
                  <div className="form-group col-sm-5">
                    <label
                      className="form-check-label small"
                      htmlFor="contactPhone"
                    >
                      Tel.
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="contactPhone"
                      name="contactPhone"
                      value={kutterContact ? kutterContact.phone : ""}
                      readOnly
                      disabled={locked}
                    />
                  </div>
                  <div className="form-group col-md-7">
                    <label
                      className="form-check-label small"
                      htmlFor="constructionManagerName"
                    >
                      Disponent
                    </label>
                    <Select
                      isClearable
                      isDisabled={locked}
                      placeholder=""
                      noOptionsMessage={() => "Name Eingeben"}
                      noResultsMessage={() => "Keine Übereinstimmung"}
                      getOptionValue={opt => opt.id}
                      getOptionLabel={opt => `${opt.firstname} ${opt.lastname}`}
                      value={kutterBooker}
                      options={users.filter(
                        user =>
                          user.position === "Disposition" ||
                          user.position === "Bauleiter"
                      )}
                      onChange={this.handleKutterBookerChange}
                    />
                  </div>
                  <div className="form-group col-sm-5">
                    <label
                      className="form-check-label small"
                      htmlFor="contactPhone"
                    >
                      Tel.
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="contactPhoneBooker"
                      name="contactPhone"
                      value={kutterBooker ? kutterBooker.phone : ""}
                      readOnly
                      disabled={locked}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <h4>
                  Notizen{" "}
                  <p
                    className="small m-0"
                    style={{
                      display: "inline",
                      fontSize: "60%",
                      fontWeight: "400"
                    }}
                  >
                    z.B. Infos zur Baustelle
                  </p>
                </h4>
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="input-group mb-3">
                      <textarea
                        className="form-control"
                        rows="3"
                        name="notes"
                        value={notes}
                        onChange={this.handleInputChange}
                        disabled={locked}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <h4>Adresse (Anfahrtsbeschreibung)</h4>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label
                      className="form-check-label small w-100"
                      htmlFor="buildingAddress"
                    >
                      Adresse (wenn vorhanden)
                      <span className="float-right">
                        ({lat}, {lng})
                      </span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="buildingAddress"
                        name="buildingAddress"
                        value={buildingAddress}
                        disabled={locked}
                        onChange={this.handleInputChange}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          disabled={locked}
                          onClick={this.searchAddress}
                        >
                          suchen
                        </button>
                      </div>
                      <div className="input-group-append">
                        <button
                          className="btn btn-secondary"
                          disabled={locked}
                          onClick={this.resetAddress}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label
                      className="form-check-label small"
                      htmlFor="directions"
                    >
                      genaue Anfahrtbeschreibung
                    </label>
                    <textarea
                      className="form-control"
                      id="directions"
                      name="directions"
                      disabled={locked}
                      onChange={this.handleInputChange}
                      value={directions}
                      rows="3"
                    />
                  </div>
                  <div
                    className="mx-3 mb-3 hidden-print"
                    style={{ width: "100%", height: "500px" }}
                  >
                    <Map
                      address={buildingAddress}
                      coordinates={lat !== "" ? { lat, lng } : null}
                      locked={locked}
                      isOverlayVisible={false}
                      onAddressChange={this.handleAddressChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <ScopeOfService
              scopeOfServices={scopeOfServices}
              locked={locked}
              onChange={this.handleScopeOfServiceChange}
            />

            <div className="row print-avoid-page-break-inside">
              <div className="col-md-12">
                <h4>Arbeitsbeginn</h4>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label className="form-check-label small">Datum</label>
                    <input
                      type="date"
                      value={startDate}
                      disabled={locked}
                      onChange={this.changeStartDate.bind(this)}
                      className="form-control"
                      id="bauvorhaben"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="form-check-label small">Beginn</label>
                    <input
                      type="time"
                      value={startTime}
                      disabled={locked}
                      onChange={this.changeStartTime.bind(this)}
                      className="form-control"
                      id="bauvorhaben"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="form-check-label small">Treff</label>
                    <input
                      type="time"
                      value={startMeetingTime}
                      disabled={locked}
                      onChange={this.changeMeetingTime.bind(this)}
                      className="form-control"
                      id="bauvorhaben"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row print-avoid-page-break-inside">
              <div className="col-md-12" style={{ marginBottom: 20 }}>
                <h4>geplanter Einsatzzeitraum</h4>
                <div className="row">
                  <div className="col-12">
                    <FontAwesomeIcon
                      icon={faHistory}
                      style={showExactTimes ? { color: "#007bff" } : {}}
                      onClick={() => {
                        this.setState({ showExactTimes: !showExactTimes });
                      }}
                    />
                  </div>
                  <div
                    className={`form-group${
                      showExactTimes ? " col-md-3" : " col-md-6"
                    }`}
                  >
                    <label className="form-check-label small">Start</label>
                    <input
                      type="date"
                      disabled={locked}
                      onChange={this.changeOPStartDate.bind(this)}
                      value={opStartDate}
                      className="form-control"
                      id="opStartDate"
                    />
                  </div>
                  {showExactTimes && (
                    <div className="form-group col-3">
                      <label className="form-check-label small" />
                      <input
                        type="time"
                        value={opStartTime}
                        disabled={locked}
                        onChange={this.changeOpStartTime.bind(this)}
                        className="form-control"
                        id="opStartTime"
                      />
                    </div>
                  )}
                  <div
                    className={`form-group${
                      showExactTimes ? " col-md-3" : " col-md-6"
                    }`}
                  >
                    <label className="form-check-label small">Ende</label>
                    <input
                      type="date"
                      className="form-control"
                      id="opFinishDate"
                      disabled={locked}
                      value={opFinishDate}
                      onChange={this.changeOPFinishDate.bind(this)}
                    />
                  </div>
                  {showExactTimes && (
                    <div className="form-group col-3">
                      <label className="form-check-label small" />
                      <input
                        type="time"
                        value={opFinishTime}
                        disabled={locked}
                        onChange={this.changeOpFinishTime.bind(this)}
                        className="form-control"
                        id="opFinishTime"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <hr />

            <Titlebar title="Maschinen und Mitarbeiter">
              <div className="col">
                <button
                  type="button"
                  onClick={this.expand}
                  className="btn btn-outline-primary btn-sm px-3 ml-3 float-right"
                >
                  <FontAwesomeIcon
                    icon={expanded ? faChevronLeft : faChevronRight}
                  />
                </button>
              </div>
            </Titlebar>
            <Resources
              order={
                this.state || {
                  humanResources: selectedHumanResources,
                  resources: selectedResources,
                  vehicles: selectedVehicles
                }
              }
              locked={locked || this.state.id === ""}
              onActionNoOrder={this.handleResourceChangeNoOrder}
              handleResourceNoteChange={this.handleResourceNoteChange}
              handleResourceHumanResourceVehicleDateChange={
                this.handleResourceHumanResourceVehicleDateChange
              }
              handleHumanNoteChange={this.handleHumanNoteChange}
              handleVehicleNoteChange={this.handleVehicleNoteChange}
              opStartDate={opStartDate}
              opStartTime={opStartTime}
              opFinishDate={opFinishDate}
              opFinishTime={opFinishTime}
            />
          </div>
        ) : (
          <Fragment>
            <hr />
            <Disposal
              order={order}
              transporters={transporters}
              dropzones={dropzones}
              disabled={false}
            />
          </Fragment>
        )}
        <div style={{ height: 85 }} />
        {showUnlockButtons ? (
          <div className={`${footerClassName} border-top`}>
            {order && this.props.write && locked && view === "data" ? (
              <button
                type="button"
                className="btn btn-outline-secondary ml-3 mt-3 float-left hidden-print"
                onClick={this.print}
              >
                drucken
              </button>
            ) : null}
            {status === 0 && view === "data" ? (
              <Button
                className="row pt-3 pl-3 hidden-print mr-2"
                onSave={this.checkOnSave.bind(this)}
                onDelete={this.onDelete.bind(this)}
                unlock={this.unlock.bind(this)}
                lock={this.lock.bind(this)}
                chosen={!!this.props.order}
                locked={locked}
              />
            ) : null}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

Data.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  footerClassName: PropTypes.string,
  expanded: PropTypes.bool,
  view: PropTypes.string,
  resource: PropTypes.object,
  order: PropTypes.object,
  orders: PropTypes.array,
  costCenters: PropTypes.array,
  write: PropTypes.bool,
  disposalWrite: PropTypes.bool,
  users: PropTypes.array,
  subcontractors: PropTypes.array,
  onExpand: PropTypes.func,
  newOrder: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment"),
  users: state.humanResources.get("users"),
  subcontractors: state.orders.get("subcontractors"),
  newOrder: state.orders.get("newOrder")
}))(Data);
