import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import List from "./list";
import User from "./user";

import getUsers from "../../actions/getUsers";
import getSalt from "../../actions/getSalt";
import { dateToMonthRange } from "../../util/dateHelper";
import getAbsencesInRange from "../../actions/getAbsencesInRange";

class App extends Component {
  constructor(props) {
    super(props);

    document.title = "Mitarbeiter";

    this.state = {
      size: "col-md-6"
    };
    this.setSize = this.setSize.bind(this);

    const id = props.location.pathname.split("/")[2];
    props.dispatch(getUsers(props.client, id));
    props.dispatch(getSalt(props.backend));
    const dateRange = dateToMonthRange(new Date());
    props.dispatch(getAbsencesInRange(props.client, "absences", dateRange));
  }

  setSize(size) {
    this.setState({ size });
  }

  componentDidMount() {
    const { mp } = this.props;
    if (mp && mp.isInit && mp._mp) {
      const tracking_obj_param = {
        Screen: document.title,
        "Tracking Time": new Date().toDateString()
      };
      mp._mp.track("Screen View", tracking_obj_param, () =>
        console.log("tracked ", tracking_obj_param)
      );
    }
  }

  render() {
    const {
      permissions,
      loginName,
      establishment,
      users,
      chosenUser,
      client,
      mp
    } = this.props;
    const { size } = this.state;

    const write =
      Boolean(permissions.find(p => p.name === "human_resources" && p.write)) ||
      loginName === "klout";

    const isEqualSize = size === "col-md-6";
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <List
              className={`${isEqualSize ? "col-md-6" : "col-md-3"} padding-20`}
              establishment={establishment}
              users={users}
              chosenUser={chosenUser}
              write={write}
              client={client}
              isEqualSize={isEqualSize}
            />
            <User
              className={`${
                isEqualSize ? "col-md-6" : "col-md-9"
              } data-panel padding-20 border-left`}
              establishment={establishment}
              user={chosenUser}
              write={write}
              size={size}
              setSize={this.setSize}
              mp={mp}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  users: PropTypes.array,
  chosenUser: PropTypes.object,
  client: PropTypes.object,
  location: PropTypes.object,
  permissions: PropTypes.array,
  loginName: PropTypes.string,
  establishment: PropTypes.object,
  backend: PropTypes.string,
  absences: PropTypes.array
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  users: state.humanResources.get("users"),
  absences: state.absences.get("absences"),
  chosenUser: state.humanResources.get("chosenUser"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName"),
  establishment: state.main.get("establishment"),
  backend: state.main.get("backend")
}))(App);
