import React, { Component } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import CreatableSelect from "react-select/lib/Creatable";

import TitleBar from "../TitleBar";
import Companies from "./companies";
import LoadingIndicator from "../LoadingIndicator";
import Button from "../UnlockButton";

import createContact from "../../actions/createContact";
import updateContact from "../../actions/updateContact";
import deleteContact from "../../actions/deleteContact";

import createCompany from "../../actions/createCompany";

class Data extends Component {
  constructor(props) {
    super();
    this.initState = {
      salutation: "Herr",
      firstname: "",
      lastname: "",
      phone: "",
      mobile: "",
      email: "",
      fax: "",
      position: null,
      locked: !props.write,
      company: null,
      companies: props.companies,
      validationErrors: {
        firstname: false,
        lastname: false,
        phone: false,
        mobile: false,
        email: false,
        fax: false
      }
    };
    this.state = this.initState;

    this.positionOptions = [
      { label: "keine Angabe", value: "keine Angabe" },
      { label: "Sekretär/-in", value: "Sekretär/-in" },
      { label: "Bauleitung", value: "Bauleitung" },
      { label: "Polier", value: "Polier" },
      { label: "Einkauf", value: "Einkauf" },
      { label: "Abteilungsleitung", value: "Abteilungsleitung" },
      { label: "Geschäftsführung", value: "Geschäftsführung" },
      { label: "sonstiges", value: "sonstiges" }
    ];

    this.state.position = this.positionOptions[0];

    this.setContact = this.setContact.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { contact, company, companies } = this.props;
    if (prevProps.contact !== contact) {
      if (contact) {
        this.setContact(contact);
      } else {
        this.setState(this.initState);
        if (companies) {
          this.setState({ companies });
        }
      }
    }

    if (prevProps.company !== company) {
      this.setState({ company });
    }

    if (prevProps.companies !== companies) {
      this.setState({ companies });
    }
  }

  setContact(contact) {
    this.setState({
      salutation: contact.salutation || "-",
      firstname: contact.firstname,
      lastname: contact.lastname,
      phone: contact.phone,
      mobile: contact.mobile,
      email: contact.email,
      fax: contact.fax,
      position: {
        label: contact.position,
        value: contact.position
      },
      locked: true,
      validationErrors: {
        firstname: false,
        lastname: false,
        phone: false,
        mobile: false,
        email: false,
        fax: false
      }
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handlePositionChange(value) {
    if (!value || value.length === 0) {
      this.setState({ position: this.positionOptions[0] });
    } else if (value.value !== "" && value.value.trim() !== "") {
      this.setState({
        position: { label: value.label.trim(), value: value.value.trim() }
      });
    }
  }

  lock(reset = true) {
    const { contact } = this.props;
    if (reset && contact) {
      this.setContact(contact);
    }
    this.setState({
      locked: true
    });
  }

  unlock() {
    const { contact, mp } = this.props;
    this.setState({
      locked: false
    });
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Edit",
        ...(contact && { "Object Id": contact.id }),
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
  }

  validateForm() {
    const { validationErrors } = this.state;

    validationErrors.lastname = this.state.lastname === "";
    // validationErrors.firstname = this.state.firstname === '';
    // validationErrors.phone = !this.state.phone.match(/^\d+$/i);
    // validationErrors.mobile = !this.state.mobile.match(/^\d+$/i);

    if (this.state.fax === "") {
      validationErrors.fax = false;
    } else {
      validationErrors.fax = !this.state.fax.match(/^\d+$/i);
    }

    if (this.state.email === "") {
      validationErrors.email = false;
    } else {
      validationErrors.email = !this.state.email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
    }

    let valid = true;
    Object.keys(validationErrors).forEach(field => {
      valid = valid && !validationErrors[field];
    });

    const companiesFormValid = this.companiesForm.validateForm(true);
    valid = valid && companiesFormValid;

    this.setState({ validationErrors });
    return valid;
  }

  onSave() {
    let { salutation } = this.state;
    const {
      lastname,
      firstname,
      position,
      phone,
      mobile,
      email,
      fax,
      notice
    } = this.state;

    const isHumanContact = salutation !== "Abteilung/Ort";

    if (!this.validateForm()) return;

    const { client, contact, mp } = this.props;
    const { company } = this.props;
    const newCompany = this.companiesForm.getValues();

    salutation = salutation === "-" ? null : salutation;

    if (!company && newCompany.name) {
      if (contact) {
        this.props.dispatch(
          createCompany(
            client,
            newCompany.name,
            newCompany.adress,
            newCompany.zipcode,
            newCompany.city,
            newCompany.phone,
            newCompany.fax,
            newCompany.email,
            false,
            contact
          )
        );
      } else {
        const newContact = {
          salutation,
          lastname,
          firstname,
          position: isHumanContact ? position.value : "",
          phone,
          mobile,
          email,
          fax
        };

        this.props.dispatch(
          createCompany(
            client,
            newCompany.name,
            newCompany.adress,
            newCompany.zipcode,
            newCompany.city,
            newCompany.phone,
            newCompany.fax,
            newCompany.email,
            true,
            newContact
          )
        );
      }
    } else if (contact) {
      this.props.dispatch(
        updateContact(
          client,
          contact.id,
          salutation,
          lastname,
          firstname,
          phone,
          isHumanContact ? position.value : "",
          mobile,
          email,
          fax,
          notice,
          company.id
        )
      );
    } else {
      this.props.dispatch(
        createContact(
          client,
          salutation,
          lastname,
          firstname,
          phone,
          isHumanContact ? position.value : "",
          mobile,
          email,
          fax,
          company.id
        )
      );
    }

    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Save",
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
    this.lock(false);
  }

  onDelete() {
    const { client, contact, mp } = this.props;
    this.props.dispatch(deleteContact(client, contact.id));
    if (mp && mp.isInit && mp._mp) {
      const track_obj_param = {
        Screen: document.title,
        type: "Delete",
        "Object Id": contact.id,
        "Tracking Time": new Date().toISOString()
      };
      mp._mp.track("Action", track_obj_param, () =>
        console.log("tracked", track_obj_param)
      );
    }
    this.lock(false);
  }

  render() {
    const { className, write } = this.props;
    const {
      salutation,
      lastname,
      firstname,
      phone,
      mobile,
      email,
      fax,
      position,
      locked,
      company,
      companies,
      validationErrors: errors
    } = this.state;

    const loadingCompanies = !(companies && companies.length > 0);

    const isHumanContact = salutation !== "Abteilung/Ort";

    return (
      <div className={className}>
        <TitleBar title="Stammdaten" />

        <div className="row">
          <div className="form-group col-sm-3">
            <label className="form-check-label small">Anrede</label>
            <select
              className="form-control"
              name="salutation"
              value={salutation}
              onChange={this.handleInputChange}
              disabled={locked}
            >
              <option value="-">-</option>
              <option value="Herr">Herr</option>
              <option value="Frau">Frau</option>
              <option value="Abteilung/Ort">Abteilung/Ort</option>
            </select>
          </div>
          {isHumanContact ? (
            <div className="form-group col-sm-3">
              <label className="form-check-label small">Vorname</label>
              <input
                type="text"
                className={`form-control ${
                  errors.firstname ? "is-invalid" : ""
                }`}
                value={firstname}
                disabled={locked}
                name="firstname"
                onChange={this.handleInputChange}
              />
            </div>
          ) : null}
          <div
            className={`form-group ${isHumanContact ? "col-sm-3" : "col-sm-9"}`}
          >
            <label className="form-check-label small">Name*</label>
            <input
              type="text"
              className={`form-control ${errors.lastname ? "is-invalid" : ""}`}
              value={lastname}
              disabled={locked}
              name="lastname"
              onChange={this.handleInputChange}
            />
          </div>
          {isHumanContact ? (
            <div className="form-group col-sm-3">
              <label className="form-check-label small">Position</label>
              <CreatableSelect
                placeholder={""}
                noOptionsMessage={() => ""}
                formatCreateLabel={inputValue => inputValue}
                value={position}
                options={this.positionOptions}
                onChange={this.handlePositionChange}
                isDisabled={locked}
              />
            </div>
          ) : null}
          <div className="form-group col-sm-3">
            <label className="form-check-label small">Telefonnummer</label>
            <input
              type="tel"
              className={`form-control ${errors.phone ? "is-invalid" : ""}`}
              value={phone}
              disabled={locked}
              name="phone"
              onChange={this.handleInputChange}
            />
            {errors.phone ? (
              <small className="form-text text-danger">
                Darf nur Zahlen enthalten.
              </small>
            ) : null}
          </div>
          {isHumanContact ? (
            <div className="form-group col-sm-3">
              <label className="form-check-label small">
                Telefonnummer (Mobil)
              </label>
              <input
                type="text"
                className={`form-control ${errors.mobile ? "is-invalid" : ""}`}
                value={mobile}
                disabled={locked}
                name="mobile"
                onChange={this.handleInputChange}
              />
              {errors.mobile ? (
                <small className="form-text text-danger">
                  Darf nur Zahlen enthalten.
                </small>
              ) : null}
            </div>
          ) : null}
          <div className="form-group col-sm-3">
            <label className="form-check-label small">E-Mail</label>
            {locked && email !== "" ? (
              <a
                href={`mailto:${email}`}
                className="btn btn-outline-primary w-100 text-left"
              >
                {email}
              </a>
            ) : (
              <input
                type="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                value={email}
                disabled={locked}
                name="email"
                onChange={this.handleInputChange}
              />
            )}
            {errors.email ? (
              <small className="form-text text-danger">
                Keine korrekte E-Mail.
              </small>
            ) : null}
          </div>
          <div className="form-group col-sm-3">
            <label className="form-check-label small">Fax</label>
            <input
              type="text"
              className={`form-control ${errors.fax ? "is-invalid" : ""}`}
              value={fax}
              disabled={locked}
              name="fax"
              onChange={this.handleInputChange}
            />
            {errors.fax ? (
              <small className="form-text text-danger">
                Darf nur Zahlen enthalten.
              </small>
            ) : null}
          </div>
        </div>

        <hr />

        <LoadingIndicator
          className="mb-3"
          show={loadingCompanies}
          loadingMessage="Lade Firmen..."
        />

        {!loadingCompanies ? (
          <Companies
            onRef={ref => (this.companiesForm = ref)}
            companies={companies}
            company={company}
            locked={locked}
          />
        ) : null}

        {write ? (
          <div className="order-fixed-bottom border-top">
            <Button
              className="row pt-3 pl-3 hidden-print mr-2"
              onSave={this.onSave.bind(this)}
              onDelete={this.onDelete.bind(this)}
              unlock={this.unlock.bind(this)}
              lock={this.lock.bind(this)}
              chosen={Boolean(this.props.contact)}
              locked={locked || loadingCompanies}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Data.propTypes = {
  className: Proptypes.string,
  dispatch: Proptypes.func,
  contact: Proptypes.object,
  companies: Proptypes.array,
  company: Proptypes.object,
  client: Proptypes.object,
  write: Proptypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  salt: state.main.get("salt")
}))(Data);
