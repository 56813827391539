import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Data from "./data";
import Absences from "./Absences";
import UsernamePassword from "./usernamePassword";
import EmployeeHours from "./EmployeeHours";
import TitleBar from "../TitleBar";

export default class User extends Component {
  render() {
    const {
      className,
      establishment,
      user,
      write,
      size,
      setSize,
      mp
    } = this.props;

    const isEqualSize = size === "col-md-6";
    return (
      <div className={className}>
        <TitleBar
          titleComponent={
            <Fragment>
              <span
                className={`${
                  isEqualSize ? "text-black" : "text-black-50"
                } h4 mr-3 cursor-pointer`}
                onClick={() => setSize("col-md-6")}
              >
                Stammdaten
              </span>
              <span
                className={`${
                  isEqualSize ? "text-black-50" : "text-black"
                } h4 cursor-pointer`}
                onClick={() => setSize("col-md-3")}
              >
                Stundenzettel
              </span>
            </Fragment>
          }
        />
        {isEqualSize ? (
          <Fragment>
            <Data
              establishment={establishment}
              user={user}
              write={write}
              mp={mp}
            />
            {user ? (
              <Fragment>
                <UsernamePassword user={user} write={write} />
                <Absences
                  absences={user.absences}
                  user={user}
                  userId={parseInt(user.id, 10)}
                  write={write}
                />
              </Fragment>
            ) : null}
          </Fragment>
        ) : (
          <EmployeeHours
            key="employeeHours"
            user={user}
            write={write}
            absences={user ? user.absences : []}
          />
        )}
      </div>
    );
  }
}

User.propTypes = {
  className: PropTypes.string,
  establishment: PropTypes.object,
  user: PropTypes.object,
  write: PropTypes.bool
};
